import { Link } from 'react-router-dom';
import URLS from 'URLS';
import { useAuth, useIsSchoolAuth } from 'hooks/Auth';
import { useUserState } from 'hooks/User';
import { useTranslation } from 'react-i18next';

// Material UI Components
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Hidden, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

// Assets/Icons
import LOGO from 'assets/icons/logo_stroke.svg';
import ListAltIcon from '@material-ui/icons/ListAlt';
import InfoIcon from '@material-ui/icons/Info';
import SellIcon from '@material-ui/icons/ShoppingCart';
import PlayNextIcon from '@material-ui/icons/QueuePlayNext';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ResourcesIcon from '@material-ui/icons/LibraryBooks';
import EventIcon from '@material-ui/icons/Event';

// Project components
import Apps from 'components/navigation/Apps';
import Paper from 'components/layout/Paper';
import theme from 'theme';
import SideBar from './SideBar';

const useStyles = makeStyles((theme) => ({
  nav: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 0,
    padding: theme.spacing(1, 0),
    margin: 'auto',
    width: '100%',
    marginBottom: 0,
    position: 'absolute',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    [theme.breakpoints.up('lg')]: {
      position: 'fixed',
      zIndex: 2,
    },
    backgroundColor: '#141414',
    overflow: 'visible',
  },
  navContent: {
    margin: 'auto',
    padding: theme.spacing(0, 1),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  logoWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 15,
    },
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
  avatar: {
    width: 45,
    height: 45,
    fontSize: 18,
    fontWeight: 'bold',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  userInfo: {
    right: '5%',
    position: 'absolute',
  },
  menu: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  menuText: {
    textTransform: 'none',
    color: 'white',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    cursor: 'pointer',
    '&:hover': {
      color: 'rgba(82, 153, 211)',
    },
    [theme.breakpoints.between('lg', 1400)]: {
      fontSize: '0.9em',
    },
  },
  menuButton: {
    [theme.breakpoints.between('lg', 1400)]: {
      padding: '6px 0px',
    },
  },
}));

type URIbuttonProps = {
  data: {
    link: string;
    text: string;
    external?: boolean;
  };
};

export const URIbutton = ({ data }: URIbuttonProps) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.menuButton}
      color='secondary'
      component={data.external ? Button : Link}
      onClick={() => {
        if (data.external) {
          window.open(data.link);
        } else {
          data.link === window.location.pathname ? window.location.reload() : undefined;
        }
      }}
      to={data.external ? undefined : data.link}>
      <Typography variant='h3'>
        <div className={classes.menuText}>{data.text}</div>
      </Typography>
    </Button>
  );
};
type PersonIconProps = {
  name: string;
  img?: string;
};

const PersonIcon = ({ name, img }: PersonIconProps) => {
  const classes = useStyles();
  return (
    <Button component={Link} onClick={URLS.ACCOUNT === window.location.pathname ? () => window.location.reload() : undefined} to={URLS.ACCOUNT}>
      <div className={classes.profileContainer}>
        <Avatar className={classes.avatar} src={img}>
          {name.substring(0, 1)}
        </Avatar>
      </div>
    </Button>
  );
};

const NavBar = () => {
  const { t } = useTranslation(['common']);
  const classes = useStyles();
  const [auth, isLoading] = useAuth();
  const [_, __, schoolAuthDoc] = useIsSchoolAuth();
  const isSchoolStudent = schoolAuthDoc !== null ? schoolAuthDoc?.data().role === 'student' : false;
  const user = useUserState();

  const pages = [
    {
      link: URLS.ABOUT,
      text: t('LANDING.TEMPLATE.ABOUT'),
      icon: <InfoIcon />,
    },
    {
      link: URLS.PRICING,
      text: t('LANDING.TEMPLATE.PRICING'),
      icon: <LocalOfferIcon />,
    },
    {
      link: URLS.REFERENCES,
      text: t('LANDING.TEMPLATE.REFERENCES'),
      icon: <ListAltIcon />,
    },
    ...(isSchoolStudent !== true
      ? [
          {
            link: URLS.SHOP,
            text: t('LANDING.TEMPLATE.SHOP'),
            external: true,
            icon: <SellIcon />,
          },
        ]
      : []),

    {
      link: URLS.HUB,
      text: t('LANDING.TEMPLATE.HUB'),
      external: false,
      icon: <PlayNextIcon />,
    },
    {
      link: URLS.RESOURCES,
      text: t('LANDING.TEMPLATE.RESOURCES'),
      external: false,
      icon: <ResourcesIcon />,
    },
    {
      link: URLS.EVENTS,
      text: t('LANDING.TEMPLATE.ACTIVITIES'),
      external: false,
      icon: <EventIcon />,
    },
    // {
    //   link: URLS.RESOURCES,
    //   text: t('LANDING.TEMPLATE.SUPPORT'),
    //   external: false,
    //   icon: <ContactSupportIcon />,
    // },
  ];

  return (
    <>
      <Paper className={classes.nav} outlined>
        <div className={classes.navContent}>
          <Hidden xlUp>
            <SideBar pages={pages} />
          </Hidden>
          <Button className={classes.logoWrapper} component={Link} to={URLS.LANDING}>
            <img alt='EC-Play logo' height='40em' src={LOGO} width='auto' />
          </Button>

          <Hidden lgDown>
            <div className={classes.menu}>
              {pages.map((page, i) => (
                <URIbutton data={page} key={i} />
              ))}
            </div>
          </Hidden>
          <div className={classes.userInfo}>
            <Apps light={true} />
            {!isLoading &&
              (auth && user ? (
                <PersonIcon img={user.data()?.picture} name={user.data()?.name || ''} />
              ) : (
                <Button component={Link} to={URLS.SIGN_IN} variant='contained'>
                  {t('LANDING.TEMPLATE.SIGNIN')}
                </Button>
              ))}
          </div>
        </div>
      </Paper>
      <div style={{ paddingTop: theme.spacing(8) }}></div>
    </>
  );
};

export default NavBar;
