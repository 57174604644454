// Material ui
import { Button, Checkbox, Fab, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import FormatListIcon from '@material-ui/icons/FormatListBulleted';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '250px',
    position: 'relative',
    cursor: 'pointer',
  },
  sideBox: {
    display: 'flex',
    position: 'fixed',
    [theme.breakpoints.up(1700)]: {
      right: theme.spacing(2),
      top: '35%',
    },
  },
  registeredCheckbox: {
    fill: '#14ad14',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: '1.5rem',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    flexShrink: 0,
    userSelect: 'none',
  },
  text: {
    wordBreak: 'break-word',
  },
  nextText: {
    wordBreak: 'break-word',
    fontWeight: 'bold',
  },
  pastText: {
    color: 'green',
    wordBreak: 'break-word',
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
  },
  pointer: {
    cursor: 'pointer',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
    [theme.breakpoints.up(1000)]: {
      bottom: theme.spacing(2),
    },
    zIndex: 1,
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
  dialog: {},
  deactivate: {
    color: 'red',
    display: 'flex',
    paddingTop: theme.spacing(1),
    justifyContent: 'center',
  },
  checkbox: {
    marginLeft: theme.spacing(1),
  },
  nextStep: {
    boxShadow: '0px 2px 8px 0px rgba(81, 203, 238, 1)',
  },
  padding: {
    padding: theme.spacing(1),
  },
  stepText: {
    display: 'flex',
    flexDirection: 'row',
  },
  numberText: {
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(0.5),
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
}));

type OnboardingProps = {
  title: string;
  steps: Array<OnboardingStep>;
  asDialog: boolean;
  setAsDialog: (asDialog: boolean) => void;
  stopOnboarding: () => void;
  setNavigateNow: (navigateNow: boolean) => void;
  setUrl: (url: string) => void;
};

type OnboardingStep = {
  text: string;
  checked: boolean;
  redirect?: string;
  clickRedirect?: string;
  autoRedirect?: boolean;
};

const Onboarding = ({ steps, title, asDialog, setAsDialog, stopOnboarding, setNavigateNow, setUrl }: OnboardingProps) => {
  const classes = useStyles();
  const { t } = useTranslation('events');
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down(1700));
  const done = steps.every((step) => step.checked);

  return (
    <>
      {!asDialog && (
        <div className={classes.sideBox}>
          {small ? (
            <>
              <Fab className={classes.fab} color='secondary' onClick={() => setAsDialog(true)} variant='extended'>
                <FormatListIcon className={classes.fabIcon} />
                {t('ONBOARDING.HELP')}
              </Fab>
            </>
          ) : (
            <Paper className={classes.paper} elevation={15} onClick={() => setAsDialog(true)}>
              <div className={classes.padding}>
                <OnboardingBody disabled={true} setNavigateNow={setNavigateNow} setUrl={setUrl} steps={steps} title={title} />
              </div>
            </Paper>
          )}
        </div>
      )}
      <MaterialDialog
        aria-labelledby='form-dialog-title'
        className={classes.dialog}
        fullWidth
        maxWidth={'sm'}
        onClose={() => (done ? stopOnboarding() : setAsDialog(false))}
        open={Boolean(asDialog)}>
        <div className={classes.padding}>
          <OnboardingBody disabled={false} setNavigateNow={setNavigateNow} setUrl={setUrl} steps={steps} title={title} />
        </div>

        {done ? (
          <Typography className={classes.title} variant='h5'>
            {t('ONBOARDING.DONE')}
          </Typography>
        ) : (
          <div className={classes.deactivate}>
            <Button color='inherit' onClick={() => stopOnboarding()} variant='outlined'>
              {t('ONBOARDING.NOHELP')}
            </Button>
          </div>
        )}

        <DialogActions>
          <div className={classes.pointer} onClick={() => (done ? stopOnboarding() : setAsDialog(false))}>
            <CloseIcon />
          </div>
        </DialogActions>
      </MaterialDialog>
    </>
  );
};

export default Onboarding;

type OnboardingBodyProps = {
  steps: Array<OnboardingStep>;
  title: string;
  setNavigateNow: (navigateNow: boolean) => void;
  setUrl: (url: string) => void;
  disabled?: boolean;
};

const OnboardingBody = ({ steps, title, setNavigateNow, setUrl, disabled = false }: OnboardingBodyProps) => {
  const classes = useStyles();

  const handleStepClick = (step: OnboardingStep) => {
    if (step.redirect && !disabled) {
      setUrl(step.redirect);
      setNavigateNow(true);

      if (step.clickRedirect) {
        setTimeout(() => {
          setUrl(step.clickRedirect || '');
          setNavigateNow(true);
        }, 300);
      }
    }
  };

  const RegisteredCheckboxIcon = () => {
    return (
      <svg className={classes.registeredCheckbox}>
        <path d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z' />
      </svg>
    );
  };

  const isNextStep = (step: OnboardingStep) => {
    return steps.find((step) => !step.checked) === step;
  };

  const isFutureStep = (step: OnboardingStep) => {
    return steps.findIndex((step) => !step.checked) < steps.findIndex((s) => s === step) && steps.some((step) => !step.checked);
  };

  const isPastStep = (step: OnboardingStep) => {
    return steps.findIndex((step) => !step.checked) > steps.findIndex((s) => s === step) || !steps.some((step) => !step.checked);
  };

  return (
    <>
      <Typography className={classes.title} variant='h3'>
        {title}
      </Typography>
      <List>
        {steps.map((step, index) => {
          return (
            <ListItem
              button
              className={isNextStep(step) ? classes.nextStep : ''}
              dense
              disabled={isFutureStep(step)}
              key={index}
              onClick={() => handleStepClick(step)}
              role={undefined}>
              <ListItemText classes={{ primary: isPastStep(step) ? classes.pastText : isNextStep(step) ? classes.nextText : classes.text }} secondary={false}>
                <div className={classes.stepText}>
                  <div className={classes.numberText}>{`${index + 1}.`}</div>
                  {`${step.text}`}
                </div>
              </ListItemText>
              <ListItemIcon>
                <Checkbox
                  className={classes.checkbox}
                  disableRipple
                  disabled={true}
                  edge='start'
                  icon={step.checked ? <RegisteredCheckboxIcon /> : undefined}
                  tabIndex={-1}
                />
              </ListItemIcon>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};
