import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import URLS from 'URLS';
import { useTranslation } from 'react-i18next';
import { useIsSchoolAuth } from 'hooks/Auth';
import { useAdmin } from 'apps/AdminPanel/hooks/useAdmin';

// Material UI Components
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// Assets/Icons
import LOGO from 'assets/icons/ECPlayLogo.svg';
import MenuIcon from '@material-ui/icons/MenuRounded';
import HomeIcon from '@material-ui/icons/HomeRounded';

const useStyles = makeStyles((theme) => ({
  drawer: {
    minWidth: 275,
    width: '70%',
    maxWidth: 400,
  },
  logo: {
    margin: theme.spacing(1, 2),
    width: 150,
    height: 'auto',
  },
  menuIcon: {
    fill: theme.palette.white?.main || '#fff',
  },
}));

type SideBarProps = {
  pages: Array<{
    link: string;
    text: string;
    external?: boolean;
    icon: ReactNode;
  }>;
};

const SideBar = ({ pages }: SideBarProps) => {
  const { t } = useTranslation(['common']);
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => setOpen((prev) => !prev);

  type ListItemProps = {
    icon: ReactNode;
    text: string;
    to: string;
    external?: boolean;
  };

  const ListItem = ({ to, icon, text, external }: ListItemProps) => (
    <MuiListItem
      button
      color='primary'
      onClick={() => {
        if (external) {
          window.open(to);
        } else {
          navigate(to);
        }
      }}
      selected={location.pathname === to}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </MuiListItem>
  );

  return (
    <>
      <IconButton aria-label='Toggle sidebar' onClick={toggleDrawer}>
        <MenuIcon className={classes.menuIcon} />
      </IconButton>
      <Drawer anchor='left' classes={{ paper: classes.drawer }} onClose={toggleDrawer} open={open}>
        <List>
          <img alt='EC-Play logo' className={classes.logo} height='40em' src={LOGO} width='auto' />
          <ListItem icon={<HomeIcon />} text={t('APPS.HOME')} to={URLS.LANDING} />
          {pages.map((page) => (
            <ListItem external={page.external} icon={page.icon} key={page.link} text={page.text} to={page.link} />
          ))}
        </List>
        <Divider />
      </Drawer>
    </>
  );
};

export default SideBar;
