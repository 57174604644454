import { useState, useEffect } from 'react';
import firebase, { COLLECTIONS, collection, db } from 'fb';
import { EventTimeParticipant, MembershipType } from 'apps/Events/types';
import { useUserWithLoading } from 'hooks/User';
import useEventAdmin from 'apps/Events/hooks/useEventAdmin';
import { User, Membership } from 'types';
import { useUsersUtils } from 'hooks/Users';

const useMembership = (eventId = '') => {
  const { user, isLoading: isUserLoading } = useUserWithLoading();
  const { getUserById } = useUsersUtils();
  const { event, eventTimes, isLoading: isEventLoading } = useEventAdmin(eventId);
  const [isLoading, setIsLoading] = useState(true);
  const [memberships, setMemberships] = useState<{ [key: string]: MembershipType }>({});
  const [membershipRefs, setMembershipRefs] = useState<{ [key: string]: firebase.firestore.DocumentSnapshot<Membership> }>({});
  const [isFamilyPlan, setIsFamilyPlan] = useState(false);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);

  useEffect(() => {
    if (!isEventLoading && !isUserLoading) {
      fetch();
    }
  }, [isEventLoading, isUserLoading, user]);

  const refetch = () => fetch();

  const isFamilyPlanChild = (child: string) => {
    if (user && membershipRefs[user.id]?.id && membershipRefs[child]?.id) {
      return child !== user.id && membershipRefs[user.id]!.id === membershipRefs[child]!.id;
    } else {
      return false;
    }
  };

  const fetch = async () => {
    setIsLoading(true);
    setMemberships({});

    const users: firebase.firestore.DocumentSnapshot<User>[] = [];
    if (user) {
      users.push(user);
    }

    if (user?.data()?.children) {
      await Promise.all(
        user!.data()!.children!.map(async (childId) => {
          const child = await getUserById(childId, true);
          return users.push(child);
        }),
      );
    }

    if (event) {
      // fetch everyone from event
      await Promise.all(
        eventTimes.map(async (time) => {
          const participants = await collection<EventTimeParticipant>(`${time.ref.path}/${COLLECTIONS.EVENTS_EVENT_TIME_PARTICIPANTS}`).get();

          const eventUsers: firebase.firestore.DocumentSnapshot<User>[] = [];
          await Promise.all(
            participants.docs.map(async (part) => {
              const user = await part.data().user.get();
              return eventUsers.push(user);
            }),
          );
          return eventUsers.forEach((u) => users.push(u));
        }),
      );
    }

    await Promise.all(
      [...new Set(users)].map(async (user) => {
        if (!user!.data()!.events?.membership?.membershipType || user!.data()!.events?.membership?.membershipType === 'unset') {
          const membershipsCopy = memberships;
          membershipsCopy[user!.id] = 'unset';
          setMemberships(membershipsCopy);
        } else {
          const membershipsCopy = memberships;
          membershipsCopy[user!.id] = user!.data()!.events!.membership!.membershipType as MembershipType;
          setMemberships(membershipsCopy);
        }
        // Store membershipRefs
        if (user!.data()!.events?.membership?.currentMembershipRef) {
          const ref = await user!.data()!.events!.membership!.currentMembershipRef!.get();
          const refsCopy = membershipRefs;

          refsCopy[user!.id] = ref as firebase.firestore.DocumentSnapshot<Membership>;
          setMembershipRefs(refsCopy);
        }
      }),
    );

    setIsFamilyPlan(Boolean(user?.data()!.events?.membership?.family));
    setHasPaymentMethod(user?.data()!.events?.membership?.hasPaymentMethod || false);

    setIsLoading(false);
  };

  return {
    isLoading,
    memberships,
    membershipRefs,
    refetch,
    isFamilyPlan,
    isFamilyPlanChild,
    hasPaymentMethod,
  };
};

export default useMembership;
