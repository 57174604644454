import { useState, useEffect, ReactNode, createContext, useContext } from 'react';
import firebase, { auth, COLLECTIONS, db } from 'fb';
import schools from '../assets/files/schools';
import { SchoolMember } from '../types';
import { useUserState } from './User';

const useAuth = () => {
  const [state, setState] = useState<firebase.User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setState(user?.emailVerified || (user && user.providerData.length === 0) ? user : null);
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  return [state, isLoading] as const;
};

const SchoolUserContext = createContext<{
  isSchoolUser: boolean | null;
  isLoading: boolean;
  schoolUserDoc: firebase.firestore.QueryDocumentSnapshot<SchoolMember> | null;
  hasSchoolAccess: boolean;
  schoolClass: string | null;
}>({
  isSchoolUser: null,
  isLoading: true,
  schoolUserDoc: null,
  hasSchoolAccess: false,
  schoolClass: null,
});

// TODO REWORK
const SchoolUserProvider = ({ children }: { children: ReactNode }) => {
  const [auth, authIsLoading] = useAuth();
  const user = useUserState();
  const [isSchoolUser, setIsSchoolUser] = useState<boolean | null>(null);
  const [schoolUserDoc, setSchoolUserDoc] = useState<firebase.firestore.QueryDocumentSnapshot<SchoolMember> | null>(null);
  const [schoolClass, setSchoolClass] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasSchoolAccess, setHasSchoolAccess] = useState<boolean>(false);

  useEffect(() => {
    if (!authIsLoading && auth) {
      db.collectionGroup(COLLECTIONS.SCHOOL_MEMBERS)
        .where('uid', '==', auth.uid)
        .get()
        .then((docsSnapshot) => {
          if (docsSnapshot.size > 0) {
            setIsSchoolUser(true);
            const tempSchoolUserDoc = docsSnapshot.docs[0];
            if (tempSchoolUserDoc) {
              setSchoolUserDoc(tempSchoolUserDoc as firebase.firestore.QueryDocumentSnapshot<SchoolMember>);
              setSchoolClass(tempSchoolUserDoc.ref.parent.parent!.id);
            }
          } else {
            setIsSchoolUser(false);
          }
        });
    }
  }, [auth, authIsLoading]);

  useEffect(() => {
    setIsLoading(isSchoolUser === null);
    if (user) {
      setHasSchoolAccess(Boolean(isSchoolUser || user.data()?.subscription?.schoolBypass));
    }
  }, [isSchoolUser, user]);

  return (
    <SchoolUserContext.Provider
      value={{ isSchoolUser: isSchoolUser, isLoading: isLoading, schoolUserDoc: schoolUserDoc, schoolClass: schoolClass, hasSchoolAccess: hasSchoolAccess }}>
      {children}
    </SchoolUserContext.Provider>
  );
};

const useIsSchoolAuth = (): [boolean | null, boolean, firebase.firestore.QueryDocumentSnapshot<SchoolMember> | null, string | null, boolean] => {
  const context = useContext(SchoolUserContext);
  return [context.isSchoolUser, context.isLoading, context.schoolUserDoc, context.schoolClass, context.hasSchoolAccess];
};

export { useAuth, SchoolUserProvider, useIsSchoolAuth };
