import { createMuiTheme } from '@material-ui/core/styles';
import { PaletteColor, PaletteColorOptions } from '@material-ui/core/styles/createPalette';
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    black: PaletteColor;
    logo: PaletteColor;
    white?: PaletteColor;
  }

  interface PaletteOptions {
    black: PaletteColorOptions;
    logo: PaletteColorOptions;
    white?: PaletteColorOptions;
  }
}
export default createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 600,
      lg: 900,
      xl: 1200,
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", sans-serif',
    h1: {
      color: '#222222',
      textAlign: 'left',
      fontWeight: 700,
      fontSize: '3rem',
      fontFamily: '"Oswald", sans-serif',
      marginTop: 'auto',
      marginBottom: 'auto',
      whiteSpace: 'normal',
      paddingBottom: 8,
      '@media only screen and (max-width: 900px)': {
        fontSize: '2rem',
      },
    },
    h2: {
      color: '#111111',
      textAlign: 'left',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginTop: 16,
      marginBottom: 8,
    },
    h3: {
      color: '#111111',
      textAlign: 'left',
      fontSize: '1.1rem',
    },
    h4: {
      color: '#476282',
      textAlign: 'left',
      marginBottom: 8,
      fontSize: '0.9rem',
    },
  },
  palette: {
    black: {
      main: 'black',
      light: 'black',
    },
    white: {
      main: 'white',
      contrastText: 'black',
    },
    primary: {
      main: '#5b7ee0',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#1F0322',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#B71C1C',
      contrastText: '#ffffff',
    },
    type: 'light',
    text: {
      primary: '#111111',
    },
    background: {
      default: '#f6f6f6',
      paper: '#fafafa',
    },
    logo: {
      main: 'default',
      light: 'none',
    },
  },
});

const pickerTheme = createMuiTheme({
  palette: {
    black: {
      main: 'black',
      light: 'white',
    },
    primary: {
      main: '#F76C6C',
      contrastText: '#FFFFFF',
    },
    logo: {
      main: 'default',
      light: 'none',
    },
  },
});

export { pickerTheme };
