import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '..';

// Material UI Components
import { makeStyles, Tab, Tabs } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
}));

export type TabsMenuProps = {
  items: Array<Page>;
  selected: string;
  setSelected: (selected: string) => void;
  hidden?: boolean;
};

const TabsMenu = ({ items, selected, setSelected, hidden = false }: TabsMenuProps) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [renderIndex, setRenderIndex] = useState(0);
  const { t } = useTranslation('common');

  useEffect(() => {
    setSelectedIndex(items.findIndex((item) => item.name === selected));
  }, [selected]);

  const handleClicked = (index: number) => {
    setSelectedIndex(index);
    setSelected(items[index].displayName);
    window.sessionStorage.setItem('landingPage', items[index].name);
  };

  return (
    <>
      {!hidden && (
        <>
          <div className={classes.container}>
            <Tabs indicatorColor='primary' onChange={(e, val) => handleClicked(val)} value={selectedIndex} variant='scrollable'>
              {items.map((item, i) => {
                return <Tab key={i} label={t(item.displayName)} />;
              })}
            </Tabs>
          </div>
        </>
      )}
    </>
  );
};

export default TabsMenu;
