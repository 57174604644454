import { useCallback } from 'react';
import firebase, { collection, COLLECTIONS, REQUEST_TYPES } from 'fb';
import { addWeeks } from 'date-fns';
import {
  User,
  Organization,
  OrganizationMemberType,
  Request,
  EventRequest,
  ParentChildRequest,
  LocationConnectedHostRequest,
  EventToTeacherRequest,
  TeacherToEventRequest,
  EventToLocationRequest,
  LocationToEventRequest,
  OrganizationMemberRequest,
} from 'types';
import { Location, Event, EventTime } from 'apps/Events/types';

const getExpiryDate = (weeks = 1) => firebase.firestore.Timestamp.fromDate(addWeeks(new Date(), weeks));

export const useRequest = () => {
  /**
   * Create a parent-child request
   * @param childRef Firestore reference of the child
   * @param parentRef Firestore reference of the parent which should receive the request
   * @param message Optional message to reciever
   */
  const createParentChildRequest = useCallback(
    async (childRef: firebase.firestore.DocumentReference<User>, parentRef: firebase.firestore.DocumentReference<User>, message = '') => {
      return collection<ParentChildRequest>(COLLECTIONS.REQUESTS).add({
        type: REQUEST_TYPES.PARENT_CHILD,
        child: childRef,
        sender: childRef,
        receiver: parentRef,
        expiry: getExpiryDate(),
        message: message,
      });
    },
    [],
  );

  /**
   * Create a location-connected-teacher request
   * @param locationRef Firestore reference of the location
   * @param locationOwnerRef Firestore reference of the location-owner
   * @param hostRef Firestore reference of the teacher which should be connected to the location
   * @param message Optional message to reciever
   */
  const createLocationConnectedHostRequest = useCallback(
    async (
      locationRef: firebase.firestore.DocumentReference<Location>,
      locationOwnerRef: firebase.firestore.DocumentReference<User>,
      hostRef: firebase.firestore.DocumentReference<User>,
      message = '',
    ) => {
      return collection<LocationConnectedHostRequest>(COLLECTIONS.REQUESTS).add({
        type: REQUEST_TYPES.LOCATION_CONNECTED_HOST,
        location: locationRef,
        sender: locationOwnerRef,
        receiver: hostRef,
        expiry: getExpiryDate(),
        message: message,
      });
    },
    [],
  );

  /**
   * Create a user-connected-teacher request
   * @param userRef Firestore reference of the user that sends the request
   * @param teacherRef Firestore reference of the teacher which should be connected to the requesting user
   * @param message Optional message to reciever
   */
  const createUserConnectedTeacherRequest = useCallback(
    async (userRef: firebase.firestore.DocumentReference<User>, teacherRef: firebase.firestore.DocumentReference<User>, message = '') => {
      return collection<Request>(COLLECTIONS.REQUESTS).add({
        type: REQUEST_TYPES.USER_CONNECTED_TEACHER,
        sender: userRef,
        receiver: teacherRef,
        expiry: getExpiryDate(),
        message: message,
      });
    },
    [],
  );

  /**
   * Create a event-to-location request, where a event requests the locations to host the event
   * @param eventRef Firestore reference of the event
   * @param locationRef Firestore reference of the location
   * @param hostRef Firestore reference of the event host
   * @param locationOwnerRef Firestore reference of the location-owner
   * @param eventTimes Array of Firestore references to the event-times that the location is requested to host
   * @param suggestedPrice Suggested price per hour
   * @param message Optional message to reciever
   */
  const createEventToLocationRequest = useCallback(
    async (
      eventRef: firebase.firestore.DocumentReference<Event>,
      locationRef: firebase.firestore.DocumentReference<Location>,
      eventHostRef: firebase.firestore.DocumentReference<User>,
      locationOwnerRef: firebase.firestore.DocumentReference<User | Organization>,
      eventTimes: Array<firebase.firestore.DocumentReference<EventTime>>,
      suggestedPrice: number,
      message = '',
    ) => {
      return collection<EventToLocationRequest>(COLLECTIONS.REQUESTS).add({
        type: REQUEST_TYPES.EVENT_TO_LOCATION,
        sender: eventHostRef,
        receiver: locationOwnerRef,
        expiry: getExpiryDate(),
        message: message,
        event: eventRef,
        location: locationRef,
        eventTimes: eventTimes,
        price: suggestedPrice,
      });
    },
    [],
  );

  /**
   * Create a location-to-event request, where a location requests a event to host the event
   * @param eventRef Firestore reference of the event
   * @param locationRef Firestore reference of the location
   * @param hostRef Firestore reference of the event host
   * @param locationOwnerRef Firestore reference of the location-owner
   * @param eventTimes Array of Firestore references to the event-times that the location is requested to host
   * @param suggestedPrice Suggested price per hour
   * @param message Optional message to reciever
   */
  const createLocationToEventRequest = useCallback(
    async (
      eventRef: firebase.firestore.DocumentReference<Event>,
      locationRef: firebase.firestore.DocumentReference<Location>,
      eventHostRef: firebase.firestore.DocumentReference<User | Organization>,
      locationOwnerRef: firebase.firestore.DocumentReference<User>,
      eventTimes: Array<firebase.firestore.DocumentReference<EventTime>>,
      suggestedPrice: number,
      message = '',
    ) => {
      return collection<LocationToEventRequest>(COLLECTIONS.REQUESTS).add({
        type: REQUEST_TYPES.LOCATION_TO_EVENT,
        sender: locationOwnerRef,
        receiver: eventHostRef,
        expiry: getExpiryDate(),
        message: message,
        event: eventRef,
        location: locationRef,
        eventTimes: eventTimes,
        price: suggestedPrice,
      });
    },
    [],
  );

  /**
   * Create a event-to-location request, where a event requests the locations to host the event
   * @param eventRef Firestore reference of the event
   * @param teacherRef Firestore reference of the location
   * @param hostRef Firestore reference of the event host
   * @param eventTimes Array of Firestore references to the event-times that the location is requested to host
   * @param suggestedPrice Suggested price per hour
   * @param message Optional message to reciever
   */
  const createEventToTeacherRequest = useCallback(
    async (
      eventRef: firebase.firestore.DocumentReference<Event>,
      teacherRef: firebase.firestore.DocumentReference<User>,
      hostRef: firebase.firestore.DocumentReference<User>,
      eventTimes: Array<firebase.firestore.DocumentReference<EventTime>>,
      suggestedPrice: number,
      message = '',
    ) => {
      return collection<EventToTeacherRequest>(COLLECTIONS.REQUESTS).add({
        type: REQUEST_TYPES.EVENT_TO_TEACHER,
        sender: hostRef,
        receiver: teacherRef,
        expiry: getExpiryDate(),
        message: message,
        event: eventRef,
        eventTimes: eventTimes,
        price: suggestedPrice,
      });
    },
    [],
  );

  /**
   * Create a location-to-event request, where a location requests a event to host the event
   * @param eventRef Firestore reference of the event
   * @param teacherRef Firestore reference of the teacher
   * @param hostRef Firestore reference of the event host
   * @param eventTimes Array of Firestore references to the event-times that the location is requested to host
   * @param suggestedPrice Suggested price per hour
   * @param message Optional message to reciever
   * @param answerToRequest Firestore reference if the request is an answer to a former request
   */
  const createTeacherToEventRequest = useCallback(
    async (
      eventRef: firebase.firestore.DocumentReference<Event>,
      teacherRef: firebase.firestore.DocumentReference<User>,
      hostRef: firebase.firestore.DocumentReference<User | Organization>,
      eventTimes: Array<firebase.firestore.DocumentReference<EventTime>>,
      paymentTo: firebase.firestore.DocumentReference<User | Organization>,
      suggestedPrice: number,
      message = '',
      answerToRequest: firebase.firestore.DocumentReference<EventRequest> | null = null,
    ) => {
      return collection<TeacherToEventRequest>(COLLECTIONS.REQUESTS).add({
        type: REQUEST_TYPES.TEACHER_TO_EVENT,
        sender: teacherRef,
        receiver: hostRef,
        expiry: getExpiryDate(),
        message,
        event: eventRef,
        eventTimes,
        price: suggestedPrice,
        answerToRequest,
        paymentTo,
      });
    },
    [],
  );

  /**
   * Create a organization-member request, an invite for an user to join an organization
   * @param userRef Firestore reference of the sending user
   * @param receiverRef Firestore reference of the receiving user
   * @param organizationRef Firestore reference of the organization
   * @param memberType Type of membership; owner, admin or employee
   * @param message Optional message to reciever
   */
  const createOrganizationMemberRequest = useCallback(
    async (
      userRef: firebase.firestore.DocumentReference<User>,
      receiverRef: firebase.firestore.DocumentReference<User>,
      organizationRef: firebase.firestore.DocumentReference<Organization>,
      memberType: OrganizationMemberType,
      message = '',
    ) => {
      return collection<OrganizationMemberRequest>(COLLECTIONS.REQUESTS).add({
        type: REQUEST_TYPES.ORGANIZATION_MEMBER,
        sender: userRef,
        receiver: receiverRef,
        expiry: getExpiryDate(),
        message: message,
        organization: organizationRef,
        memberType: memberType,
      });
    },
    [],
  );

  return {
    createParentChildRequest,
    createLocationConnectedHostRequest,
    createUserConnectedTeacherRequest,
    createEventToLocationRequest,
    createLocationToEventRequest,
    createEventToTeacherRequest,
    createTeacherToEventRequest,
    createOrganizationMemberRequest,
  };
};

export default { useRequest };
