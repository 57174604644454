import { ReactNode, useEffect } from 'react';
import classnames from 'classnames';
import { useAuth } from 'hooks/Auth';
import { useTranslation } from 'react-i18next';

// Material UI Components
import { makeStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

// Project Components
import Footer from 'components/navigation/Footer';
import TopBar from './TopBar';
import SkipToContent from 'components/navigation/SkipToContent';

const useStyles = makeStyles<Theme, Pick<NavigationProps, 'paddingOnSmall'>>((theme) => ({
  main: {
    minHeight: '101vh',
  },
}));

export type NavigationProps = {
  children?: ReactNode;
  isLoading?: boolean;
  header?: string;
  noFooter?: boolean;
  paddingOnSmall?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
};

const Navigation = ({ children, isLoading, noFooter, paddingOnSmall, header }: NavigationProps) => {
  const classes = useStyles({ paddingOnSmall });
  const [auth, isAuthLoading] = useAuth();
  const { t } = useTranslation(['events']);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <SkipToContent />
      <TopBar />
      <main className={classes.main} id={'main'}>
        <>{isLoading ? <LinearProgress /> : <>{children}</>}</>
      </main>
      {!noFooter && !isLoading && <Footer />}
    </>
  );
};

export default Navigation;
