import { ReactNode, useContext, useEffect, useMemo, useReducer, useCallback, createContext } from 'react';
import firebase, { COLLECTIONS, collection } from 'fb';
import { useUserState } from 'hooks/User';
import { Notification } from 'types';

export type NotificationsContext = {
  notifications: Array<firebase.firestore.DocumentSnapshot<Notification>>;
  moreToLoad: boolean;
};

const NotificationsStateContext = createContext<NotificationsContext | undefined>(undefined);
const NotificationsDispatchContext = createContext<React.Dispatch<Action> | undefined>(undefined);

type Action =
  | {
      type: 'add';
      payload: Array<firebase.firestore.DocumentSnapshot<Notification>>;
    }
  | {
      type: 'update';
      payload: firebase.firestore.DocumentSnapshot<Notification>;
    }
  | {
      type: 'moreToLoad';
      payload: boolean;
    };

const notificationsReducer = (state: NotificationsContext, action: Action) => {
  const removeDuplicates = (array: Array<firebase.firestore.DocumentSnapshot<Notification>> = []) =>
    array.filter((item) => !state.notifications.find((doc) => doc.id === item.id));

  switch (action.type) {
    case 'add':
      return {
        ...state,
        notifications: [...state.notifications, ...removeDuplicates(action.payload)].sort(
          (a, b) => (b.data()?.time.toMillis() || 0) - (a.data()?.time.toMillis() || 0),
        ),
      };
    case 'update':
      return { ...state, notifications: state.notifications.map((notification) => (notification.id === action.payload.id ? action.payload : notification)) };
    case 'moreToLoad':
      return { ...state, moreToLoad: action.payload };
  }
};

export type NotificationsProviderProps = {
  children: ReactNode;
};

const AMOUNT_TO_LOAD = 8;
const dateOneWeekAgo = new Date();
dateOneWeekAgo.setDate(dateOneWeekAgo.getDate() - 7);

const NotificationsProvider = ({ children }: NotificationsProviderProps) => {
  const user = useUserState();
  const [state, dispatch] = useReducer(notificationsReducer, { notifications: [], moreToLoad: true });

  useEffect(() => {
    if (user) {
      const unsub = collection<Notification>(`${user.ref.path}/${COLLECTIONS.USER_NOTIFICATIONS}`)
        .orderBy('time', 'desc')
        .limit(1)
        .onSnapshot((snapshot) => dispatch({ type: 'add', payload: snapshot.docs }));
      let subscribed = true;
      collection<Notification>(`${user.ref.path}/${COLLECTIONS.USER_NOTIFICATIONS}`)
        .where('time', '>', dateOneWeekAgo)
        .orderBy('time', 'desc')
        .limit(AMOUNT_TO_LOAD)
        .get()
        .then((snapshot) => !subscribed || dispatch({ type: 'add', payload: snapshot.docs }));
      return () => {
        unsub();
        subscribed = false;
      };
    }
  }, [user]);

  return (
    <NotificationsStateContext.Provider value={state}>
      <NotificationsDispatchContext.Provider value={dispatch}>{children}</NotificationsDispatchContext.Provider>
    </NotificationsStateContext.Provider>
  );
};

const useNotificationsState = () => {
  const context = useContext(NotificationsStateContext);
  if (context === undefined) {
    throw new Error('useNotificationsState must be used within a NotificationsProvider');
  }
  return context;
};

const useNotificationsDispatch = () => {
  const context = useContext(NotificationsDispatchContext);
  if (context === undefined) {
    throw new Error('useNotificationsDispatch must be used within a NotificationsProvider');
  }
  return context;
};

const useNotifications = () => {
  const user = useUserState();
  const context = useNotificationsState();
  const dispatch = useNotificationsDispatch();

  /**
   * @return All loaded notifications
   */
  const notifications = useMemo(() => context.notifications, [context]);
  const userData = useMemo(() => user?.data(), [user]);
  /**
   * @return {Number} Count of unread notifications of those loaded
   */
  const unread = useMemo(() => notifications.filter((item) => !item.data()?.read).length, [notifications]);
  const newNotifications = useMemo(() => userData?.newNotifications, [userData]);

  /**
   * Loads more notifications from Firestore and adds them to the cache. The notifications can be retrieved through `notifications`
   */
  const loadMoreNotifications = useCallback(async () => {
    if (!user) {
      return;
    }
    let query = collection<Notification>(`${user.ref.path}/${COLLECTIONS.USER_NOTIFICATIONS}`).orderBy('time', 'desc').limit(AMOUNT_TO_LOAD);
    !context.notifications.length || (query = query.startAfter(context.notifications[context.notifications.length - 1]));
    query.get().then((snapshot) => {
      dispatch({ type: 'add', payload: snapshot.docs });
      if (snapshot.docs.length !== AMOUNT_TO_LOAD) {
        dispatch({ type: 'moreToLoad', payload: false });
      }
    });
  }, [user, context, dispatch]);

  /**
   * @param {number} id - Id of the notification to mark as read
   */
  const markAsRead = useCallback(
    async (id) => {
      if (!user) {
        return;
      }
      const doc = collection<Notification>(`${user.ref.path}/${COLLECTIONS.USER_NOTIFICATIONS}`).doc(id);
      await doc.update({ read: true });
      const newDoc = await doc.get();
      dispatch({ type: 'update', payload: newDoc });
    },
    [user, context, dispatch],
  );

  return { notifications, moreToLoad: context.moreToLoad, unread, loadMoreNotifications, markAsRead, newNotifications };
};

export { NotificationsProvider, useNotifications };
