// Material UI Components
import { Typography, makeStyles } from '@material-ui/core';

// Project imports
import { boldText } from 'utils';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  textField: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down('xl')]: {
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
    },
  },
  headerText: {
    wordWrap: 'break-word',
    margin: 0,
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('xl')]: {
      fontSize: '34px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
    },
  },
  text: {
    fontSize: '20px',
    whiteSpace: 'pre-line',
  },
  image: {
    maxWidth: '50%',
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      order: -1,
      maxWidth: '100%',
      marginBottom: theme.spacing(3),
    },
  },
}));

export type PosterProps = {
  header: string;
  text: string;
  image: string;
};

const Poster = ({ header, text, image }: PosterProps) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <div className={classes.textField}>
          <Typography className={classes.headerText} variant='h1'>
            {header}
          </Typography>
          <Typography className={classes.text}>{boldText(text)}</Typography>
        </div>
        <img className={classes.image} src={image} />
      </div>
    </div>
  );
};

export default Poster;
