import { useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { languages } from 'i18n';
import { functions } from 'fb';
import { useUserState } from 'hooks/User';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  button: {
    width: 'fit-content',
    minWidth: 150,
    margin: 'auto',
    marginTop: theme.spacing(1),
  },
  menuItem: {
    minWidth: 150,
  },
}));

const LangSelect = ({ className }: ButtonProps) => {
  const { t, i18n } = useTranslation(['common']);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const user = useUserState();

  const setLang = async (code: string) => {
    i18n.changeLanguage(code);
    setAnchorEl(null);

    if (user) {
      functions.httpsCallable('users-ChangeLanguage')({
        language: code,
      });
    }
    // Change html lng when language is changed
    document.documentElement.setAttribute('lang', code);
  };
  return (
    <>
      <Button
        aria-controls='language-menu'
        aria-haspopup='true'
        className={classnames(classes.button, className)}
        color='secondary'
        onClick={(e) => setAnchorEl(e.currentTarget)}
        variant='outlined'>
        {t('LANGUAGE')}
      </Button>
      <Menu anchorEl={anchorEl} id='language-menu' onClose={() => setAnchorEl(null)} open={Boolean(anchorEl)}>
        {languages.map((lang, i) => (
          <MenuItem className={classes.menuItem} key={i} onClick={() => setLang(lang.code)}>
            {lang.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LangSelect;
