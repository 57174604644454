import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import NotificationsIcon from '@material-ui/icons/NotificationsNoneRounded';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

import GroupIcon from '@material-ui/icons/Group';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';

import SchoolIcon from '@material-ui/icons/School';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocationOffIcon from '@material-ui/icons/LocationOff';

import MusicNoteIcon from '@material-ui/icons/MusicNote';
import MusicOffIcon from '@material-ui/icons/MusicOff';

import PaymentIcon from '@material-ui/icons/Payment';

const getNotificationIcon = (templateName: string | undefined) => {
  if (!templateName) {
    return <NotificationsIcon />;
  }

  switch (templateName.toString()) {
    case 'acceptedToTeachAtEventTime':
      return <CheckIcon />;

    case 'connectionRemoved':
    case 'declinedToTeachAtEventTime':
    case 'declinedRequest':
    case 'removedAsTeacherEventTimes':
      return <ClearIcon />;

    case 'chatFrom':
      return <ChatBubbleIcon />;

    case 'requestOnBehalfOfOrganization':
    case 'requestAcceptedToJoinOrganization':
      return <GroupAddIcon />;

    case 'requestDeclinedToJoinOrganization':
      return <PersonAddDisabledIcon />;

    case 'removedFromOrganization':
    case 'userLeftTheOrganization':
      return <RemoveCircleOutlineIcon />;

    case 'requestToTeachAtTheirEvent':
    case 'requestToTeachAtYourEvent':
    case 'requestToBecomeConnectedTeacher':
    case 'requestToBeTeacher':
      return <SchoolIcon />;

    case 'requestOnBehalfOfLocationToBeHost':
    case 'requestHostAtYourLocation':
    case 'requestHostLocationAtYourLocation':
    case 'acceptToBecomeHost':
      return <LocationOnIcon />;

    case 'locationRemovedFromEventTime':
    case 'locationRemoved':
    case 'hostLocationRemoved':
      return <LocationOffIcon />;

    case 'songApproval':
      return <MusicNoteIcon />;

    case 'suggestionAdded':
      return <MusicNoteIcon />;

    case 'songDenied':
      return <MusicOffIcon />;

    case 'paymentSucceeded':
    case 'paymentFailed':
      return <PaymentIcon />;

    case 'acceptedParent':
      return <GroupIcon />;
  }
  return <NotificationsIcon />;
};

export default getNotificationIcon;
