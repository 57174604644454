import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';

const CookieNotification = () => {
  const [open, setOpen] = useState<boolean>(true);
  const { t } = useTranslation(['common']);

  return (
    <CookieConsent buttonText={t('COOKIE.IUNDERSTAND')} debug={false} expires={365} location='bottom'>
      {t('COOKIE.CONCENTTEXT')}{' '}
      <a href={'/privacy'} rel='noreferrer' style={{ color: 'white', textDecoration: 'underline' }} target='_blank'>
        {t('COOKIE.HERE')}
      </a>
    </CookieConsent>
  );
};

export default CookieNotification;
