/* eslint-disable comma-spacing */
import firebase, { db, geoDb, geofirestore, storage, auth, functions, messaging } from './firebase';

enum COLLECTIONS {
  CHALLENGES = 'challenges',
  CHALLENGE_USERS = 'challenge_users',
  CHALLENGE_ACTIVITIES = 'activities',
  CHATS = 'chats',
  CHAT_MESSAGES = 'messages',
  EVENTS = 'events',
  EVENTS_EVENT_TIMES = 'event_times',
  EVENTS_EVENT_TIME_PARTICIPANTS = 'event_time_participants',
  GROUPS = 'groups',
  GROUP_USERS = 'group_users',
  INVITES = 'invites',
  LOCATIONS = 'locations',
  NOTIFICATIONS = 'notifications',
  ORGANIZATIONS = 'orgs',
  REQUESTS = 'requests',
  USERS = 'users',
  USER_NOTIFICATIONS = 'user_notifications',
  TEACHING_RESOURCES = 'teachingResources',
  RESOURCE_DIRECTORIES = 'resourceDirectories',
  FAVORITE_RESOURCES = 'favoriteResources',
  EVENT_INVOICES = 'eventInvoices',
  SCHOOLS = 'schools',
  SCHOOL_CLASSES = 'school_classes',
  SCHOOL_MEMBERS = 'school_members',
  SCHOOL_INTEREST = 'schoolInterest',
  MEMBERSHIP = 'membership',
  FAVORITES = 'favorites',
  SONGS = 'songs',
  SONGS_DELETED = 'songsDeleted',
  SONGS_TRASH = 'songsInThrash',
  SONGS_VALIDATION = 'songsToBeValidated',
  SONGS_VALIDATION_DRAFT = 'songsToBeValidatedDraft',
  SONGS_PRIVATE = 'songsPrivate',
  SONGS_HISTORY = 'songsHistory',
  PLAYLISTS = 'playlists',
  CHORD_VIDEOS = 'chordVideos',
  GUESTS = 'guests',
  SCHOOL_LICENSES = 'schoolLicenses',
  SUGGESTED_SONGS = 'suggestedSongs',
}

enum REQUEST_TYPES {
  EVENT_TO_LOCATION = 'event-to-location',
  EVENT_TO_TEACHER = 'event-to-teacher',
  LOCATION_TO_EVENT = 'location-to-event',
  LOCATION_CONNECTED_HOST = 'location-connected-host',
  PARENT_CHILD = 'parent-child',
  TEACHER_TO_EVENT = 'teacher-to-event',
  USER_CONNECTED_TEACHER = 'user-connected-teacher',
  ORGANIZATION_MEMBER = 'organization-member',
}

const converter = <T,>() => ({
  toFirestore: (data: Partial<T>) => data,
  fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) => snap.data() as T,
});
const doc = <T,>(docPath: string) => firebase.firestore().doc(docPath).withConverter(converter<T>());
const collection = <T,>(collectionPath: string) => firebase.firestore().collection(collectionPath).withConverter(converter<T>());
const collectionGroup = <T,>(collection: string) => firebase.firestore().collectionGroup(collection).withConverter(converter<T>());

export { db, geoDb, geofirestore, storage, auth, functions, messaging, COLLECTIONS, REQUEST_TYPES, doc, collection, collectionGroup };
export default firebase;
