import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  parentWrapper: {
    position: 'relative',
    width: '550px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    cursor: 'pointer',
  },
  phone: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  screen: {
    display: 'flex',
    borderRadius: '35px',
    border: '10px solid black',
    overflow: 'hidden',
    width: '100%',
    paddingBottom: '58%',
    position: 'relative',
  },
  smallRectangle: {
    borderRadius: '5px',
    width: '4.8%',
    height: '3%',
    backgroundColor: 'black',
  },
  middleRectangle: {
    borderRadius: '5px',
    width: '9.6%',
    height: '3%',
    backgroundColor: 'black',
  },
  bigRectangle: {
    borderRadius: '5px',
    width: '12.8%',
    height: '3%',
    backgroundColor: 'black',
  },
  volumeDown: {
    position: 'absolute',
    top: '-1.5%',
    right: '30%',
  },
  volumeUp: {
    position: 'absolute',
    top: '-1.5%',
    right: '20%',
  },
  mute: {
    position: 'absolute',
    top: '-1.5%',
    right: '12%',
  },
  power: {
    position: 'absolute',
    bottom: '-1%',
    right: '25%',
  },
}));

export type DemoProps = {
  content?: string;
  onClick?: () => void;
};

const Demo = ({ content, onClick }: DemoProps) => {
  const classes = useStyles();
  return (
    <div className={classes.parentWrapper}>
      <div className={classes.phone}>
        <div className={classnames(classes.volumeDown, classes.middleRectangle)} />

        <div className={classnames(classes.volumeUp, classes.middleRectangle)} />

        <div className={classnames(classes.mute, classes.smallRectangle)} />

        <div className={classnames(classes.power, classes.bigRectangle)} />

        <div className={classes.screen}>
          <div className={classes.container}>
            <img onClick={onClick} src={content} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demo;
