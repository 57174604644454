import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import firebase, { COLLECTIONS, functions } from 'fb';
import { useUserState } from 'hooks/User';
import { useRequest } from 'hooks/Request';
import { useSnackbar } from 'hooks/Snackbar';
import { useHasOrganizationAdminAccess } from 'hooks/Organization';
import { useEventById, useEventTimesByEventId } from 'apps/Events/hooks/useEvent';
import { User } from 'types';
import { Event, EventTime, Location } from 'apps/Events/types';
import { getEventHostType } from 'apps/Events/utils';

const useEventAdmin = (eventId: string) => {
  const { t } = useTranslation(['events']);
  const user = useUserState();
  const { showSnackbar } = useSnackbar();
  const { createEventToLocationRequest, createEventToTeacherRequest } = useRequest();
  const { data: event, refetch: refreshEvent, isLoading: isEventLoading } = useEventById(eventId, true);
  const { data: times, refetch: refreshEventTimes, isLoading: isEventTimesLoading } = useEventTimesByEventId(eventId, true);

  const isHostedByOrg = Boolean(event?.exists) && getEventHostType(event!) === COLLECTIONS.ORGANIZATIONS;
  const { hasAccess: isOrgAdmin, isLoading: isOrgAdminLoading } = useHasOrganizationAdminAccess(isHostedByOrg ? event!.data()!.host.id : '');
  const eventTimes = useMemo(() => times?.docs.sort((a, b) => a.data()!.timeStart.toMillis() - b.data()!.timeStart.toMillis()) || [], [times]);
  const isHost = useMemo(
    () => (isHostedByOrg ? isOrgAdmin : Boolean(event?.data()) && user?.id === event?.data()?.host.id),
    [event, user, isHostedByOrg, isOrgAdmin],
  );
  const isLocationOwner = useMemo(() => Boolean(times?.docs.some((eventTime) => eventTime.data().location?.owner?.id === user?.id)), [times, user]);
  const isTeacher = useMemo(
    () => Boolean(eventTimes.some((eventTime) => eventTime.data().teachers?.list?.some((teacherId) => teacherId === user?.id))),
    [eventTimes, user],
  );
  const isLoading = useMemo(
    () => isEventLoading || isEventTimesLoading || (isHostedByOrg && isOrgAdminLoading),
    [isEventLoading, isEventTimesLoading, isHostedByOrg, isOrgAdminLoading],
  );

  /**
   * Send requests for selected locations to their owners for event-times
   * @param {Array<firebase.firestore.DocumentSnapshot>} locations - Array of locations
   * @param {number} price - Suggested price per hour
   * @param {string} message - Optional message to receivers
   * @param {Array<firebase.firestore.DocumentReference>} timeRefs - Array of event-times
   */
  const onSelectedConnectedLocation = async (
    locations: Array<firebase.firestore.DocumentSnapshot<Location>>,
    price: number,
    message: string,
    timeRefs: Array<firebase.firestore.DocumentReference<EventTime>>,
  ) => {
    try {
      if (!Array.isArray(timeRefs) || !timeRefs.length) {
        throw new Error(t('common:COMMON.ERROR'));
      }
      await Promise.all(
        locations.map((location) =>
          createEventToLocationRequest(
            timeRefs[0]!.parent!.parent! as firebase.firestore.DocumentReference<Event>,
            location.ref,
            user!.ref,
            location.data()!.owner,
            timeRefs,
            price,
            message,
          ),
        ),
      );
      showSnackbar(t('EVENT.ADMIN.LOCATIONS.REQUESTSENT'), { severity: 'success' });
    } catch (e: unknown) {
      const error = e as Error;
      showSnackbar(error.message, { severity: 'error' });
    }
  };

  /**
   * Set a selected location owned by the user as location for event-times
   * @param {firebase.firestore.DocumentReference} location - The selected location
   * @param {Array<firebase.firestore.DocumentReference>} timeRefs - Array of event-times
   */
  const onSelectedOwnLocation = async (
    location: firebase.firestore.DocumentSnapshot<Location> | null,
    timeRefs: Array<firebase.firestore.DocumentReference<EventTime>>,
  ) => {
    try {
      await Promise.all(
        timeRefs.map((timeRef) =>
          timeRef.update({
            location: {
              address: location?.data()!.address || null,
              name: location?.data()!.name || null,
              owner: location?.data()!.owner || null,
              ref: location?.ref || null,
              price: 0,
            },
          }),
        ),
      );
      showSnackbar(t('EVENT.ADMIN.LOCATIONS.UPDATED'), { severity: 'success' });
      refreshEventTimes();
    } catch (e: unknown) {
      const error = e as Error;
      showSnackbar(error.message, { severity: 'error' });
    }
  };

  /**
   * Send requests for selected teachers for event-times
   * @param {Array<firebase.firestore.DocumentReference>} teachers - Array of teachers
   * @param {number} price - Suggested price per hour
   * @param {string} message - Optional message to receivers
   * @param {boolean} bypass - Whether to bypass request or not
   * @param {Array<firebase.firestore.DocumentReference>} timeRefs - Array of event-times
   */
  const onSelectedConnectedTeachers = async (
    teachers: Array<firebase.firestore.DocumentSnapshot<User>>,
    price: number,
    message: string,
    bypass = false,
    timeRefs: Array<firebase.firestore.DocumentReference<EventTime>>,
  ) => {
    try {
      if (!Array.isArray(timeRefs) || !timeRefs.length) {
        throw new Error(t('common:COMMON.ERROR'));
      }
      if (bypass) {
        await Promise.all(
          timeRefs.map((timeRef) => {
            teachers.map(async (teacher) => {
              timeRef.update({
                'teachers.list': firebase.firestore.FieldValue.arrayUnion(teacher!.id),
                [`teachers.info.${teacher!.id}`]: {
                  price: 0,
                  paymentTo: teacher!.ref,
                },
              });
              await functions.httpsCallable('eventNotifications-addedTeacherToTime')({
                userId: user!.id,
                eventId: event!.id,
                teacherId: teacher!.id,
              });
            });
          }),
        );
        showSnackbar(t('EVENT.ADMIN.TEACHERS.UPDATED'), { severity: 'success' });
      } else {
        await Promise.all(
          teachers.map((teacher) =>
            createEventToTeacherRequest(
              timeRefs[0]!.parent!.parent! as firebase.firestore.DocumentReference<Event>,
              teacher.ref,
              user!.ref,
              timeRefs,
              price,
              message,
            ),
          ),
        );
        showSnackbar(t('EVENT.ADMIN.TEACHERS.REQUESTSENT'), { severity: 'success' });
      }
    } catch (e: unknown) {
      const error = e as Error;
      showSnackbar(error.message, { severity: 'error' });
    }
    refreshEventTimes();
  };

  /**
   * Set the current user as teacher for event-times
   * @param {Array<firebase.firestore.DocumentReference>} timeRefs - Array of event-times
   */
  const onSelectedYourselfAsTeacher = async (timeRefs: Array<firebase.firestore.DocumentReference<EventTime>>) => {
    try {
      await Promise.all(
        timeRefs.map((timeRef) =>
          timeRef.update({
            'teachers.list': firebase.firestore.FieldValue.arrayUnion(user!.id),
            [`teachers.info.${user!.id}`]: {
              price: 0,
              paymentTo: user!.ref,
            },
          }),
        ),
      );
      showSnackbar(t('EVENT.ADMIN.TEACHERS.UPDATED'), { severity: 'success' });
      refreshEventTimes();
    } catch (e: unknown) {
      const error = e as Error;
      showSnackbar(error.message, { severity: 'error' });
    }
  };
  return {
    user,
    event,
    eventTimes,
    refreshEvent,
    refreshEventTimes,
    isLoading,
    isHost,
    isTeacher,
    isLocationOwner,
    onSelectedConnectedLocation,
    onSelectedConnectedTeachers,
    onSelectedOwnLocation,
    onSelectedYourselfAsTeacher,
  };
};

export default useEventAdmin;
