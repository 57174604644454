import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Project components
import AskForEmailPermissionPopup from 'components/miscellaneous/AskForEmailPermissionPopupProps';
import Template from './pages/Template';
import { pages } from './pages/Pages';
import Navigation from './components/navigation/Navigation';
import { useAuth } from 'hooks/Auth';
import URLS from 'URLS';

export type Quote = {
  text: string;
  name?: string;
  picture?: string;
};

export type Page = {
  displayName: string;
  name: string;
  videoUrl: string;
  salesPitchHeader: string;
  salesPitchText: string;
  salesPitchButton?: string;
  salesPitchButtonText?: string;
  salesPitchButtonAlwaysRedirect?: boolean;
  demoImage?: string;
  demoUrl?: string;
  quotes: Array<Quote>;
  posterHeader: string;
  posterText: string;
  posterImage: string;
};

const Landing = () => {
  const { t } = useTranslation(['common']);
  const [page, setPage] = useState<string>(window.sessionStorage.getItem('landingPage') || pages[0].name);
  const navigate = useNavigate();
  const [auth, isAuthLoading] = useAuth();

  useEffect(() => {
    if (window.history.state.idx === 0 && auth) {
      navigate(URLS.HUB);
    }
  }, [auth, isAuthLoading]);

  return (
    <>
      <AskForEmailPermissionPopup>
        <Navigation>
          <Template page={pages.find((p) => p.name === page) || pages[0]} pages={pages} setPage={setPage} />
        </Navigation>
      </AskForEmailPermissionPopup>
    </>
  );
};

export default Landing;
