import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Used in the language switch in footer
const languages = [
  { name: 'Norsk', code: 'nb-NO' },
  { name: 'English', code: 'en-US' },
];

const resources = {
  'nb-NO': {
    common: require('locales/no.json'),
    challenge: require('apps/Challenge/locales/no.json'),
    events: require('apps/Events/locales/no.json'),
    player: require('components/Player/locales/no.json'),
    adminPanel: require('apps/AdminPanel/locales/no.json'),
    academy: require('apps/Academy/locales/no.json'),
    composer: require('apps/Composer/locales/no.json'),
    resources: require('apps/Resources/locales/no.json'),
  },
  'en-US': {
    common: require('locales/en.json'),
    challenge: require('apps/Challenge/locales/en.json'),
    events: require('apps/Events/locales/en.json'),
    player: require('components/Player/locales/en.json'),
    adminPanel: require('apps/AdminPanel/locales/en.json'),
    academy: require('apps/Academy/locales/en.json'),
    composer: require('apps/Composer/locales/en.json'),
    resources: require('apps/Resources/locales/en.json'),
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'nb-NO',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
export { languages };
