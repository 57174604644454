import { ReactNode, useState, useEffect, useContext, createContext } from 'react';
import { useAuth } from 'hooks/Auth';
import { COLLECTIONS, collection } from 'fb';
import { User } from 'types';

const AdminContext = createContext<[{ player: boolean; events: boolean; license: boolean; resources: boolean } | null, boolean]>([null, true]);

type AdminProviderProps = {
  children: ReactNode;
};

const AdminProvider = ({ children }: AdminProviderProps) => {
  const [authed, authIsLoading] = useAuth();
  const [admin, setAdmin] = useState<{ player: boolean; events: boolean; license: boolean; resources: boolean } | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    if (authed && !authIsLoading) {
      const unsub = collection<User>(COLLECTIONS.USERS)
        .doc(authed.uid)
        .onSnapshot((user) => {
          if (user.exists) {
            const userData = user.data();
            const isPlayerAdmin = userData?.player?.admin;
            const isEventsAdmin = userData?.events?.admin;
            const isLicenseAdmin = userData?.licenseAdmin;
            const isResourcesAdmin = userData?.resources?.admin;
            setAdmin({
              player: Boolean(isPlayerAdmin),
              events: Boolean(isEventsAdmin),
              license: Boolean(isLicenseAdmin),
              resources: Boolean(isResourcesAdmin),
            });
            setIsLoading(false);
          }
        });
      return () => unsub();
    } else {
      setAdmin(null);
      setIsLoading(authIsLoading);
    }
  }, [authed, authIsLoading]);

  return <AdminContext.Provider value={[admin, isLoading]}>{children}</AdminContext.Provider>;
};

const useAdmin = () => {
  const context = useContext(AdminContext);
  if (context === undefined) {
    throw new Error('useAdmin must be used within a AdminProvider');
  }
  return context;
};

export { AdminProvider, useAdmin };
