import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import URLS from 'URLS';

const useStyles = makeStyles((theme) => ({
  floatingContainer: {
    zIndex: 3,
    position: 'fixed',
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(1),
    },
    [theme.breakpoints.only('md')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    [theme.breakpoints.only('lg')]: {
      bottom: theme.spacing(5),
      right: theme.spacing(2),
    },
    [theme.breakpoints.up('xl')]: {
      bottom: theme.spacing(5),
      right: theme.spacing(20),
    },
  },
}));

const GetStartedWithSchoolSubscriptionButton = ({ floating, className }: { floating?: boolean; className?: string }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const navigate = useNavigate();
  const text = t('LANDING.GETSTARTEDBUTTON');
  const onClick = () => {
    navigate(URLS.SCHOOLONBOARDING + 'start');
  };
  return (
    <>
      {floating ? (
        <div className={classes.floatingContainer}>
          <Fab className={className} color={className === undefined ? 'primary' : undefined} onClick={onClick} variant={'extended'}>
            {text}
          </Fab>
        </div>
      ) : (
        <Button className={className} color={className === undefined ? 'primary' : undefined} onClick={onClick} variant={'contained'}>
          {text}
        </Button>
      )}
    </>
  );
};

export default GetStartedWithSchoolSubscriptionButton;
