import { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Severity, SnackbarOptions } from 'hooks/Snackbar';

// Material UI Components
import { makeStyles } from '@material-ui/core/styles';
import MaterialSnackbar, { SnackbarProps as MuiSnackbarProps } from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import SuccessIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

const useStyles = makeStyles((theme) => ({
  snackbar: {
    [theme.breakpoints.down('sm')]: {
      bottom: 75,
      left: theme.spacing(1),
      right: theme.spacing(1),
      transform: 'none',
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    flexGrow: 1,
    backgroundColor: '#e6e6e6',
    [theme.breakpoints.up('md')]: {
      flexGrow: 'initial',
      minWidth: 344,
    },
  },
  title: {
    fontWeight: 'bold',
  },
  actionRoot: {
    padding: theme.spacing(1, 1, 1, 2),
  },
  icons: {
    marginLeft: 'auto !important',
  },
  expand: {
    padding: theme.spacing(1),
    transform: 'rotate(0deg)',
    transition: '0.3s',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  collapse: {
    padding: theme.spacing(2),
  },
  button: {
    textTransform: 'none',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  body: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  img: {
    maxHeight: 250,
    width: 'auto',
    objectFit: 'contain',
    marginTop: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  error: {
    color: '#611a15',
    background: '#fdecea',
    '& button': {
      color: '#611a15',
    },
  },
  warning: {
    color: '#663c00',
    background: '#fff4e5',
    '& button': {
      color: '#663c00',
    },
  },
  info: {
    color: '#0d3c61',
    background: '#e8f4fd',
    '& button': {
      color: '#0d3c61',
    },
  },
  success: {
    color: '#1e4620',
    background: '#edf7ed',
    '& button': {
      color: '#1e4620',
    },
  },
}));

export type SnackbarProps = MuiSnackbarProps & {
  title: string;
  options?: SnackbarOptions;
  onClose: () => void;
};

const Snackbar = ({ open, onClose, title, options = { severity: 'info' } }: SnackbarProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const closeSnack = () => {
    setExpanded(false);
    onClose();
  };
  const getSeverityIcon = (severity: Severity) => {
    switch (severity) {
      case 'error':
        return <ErrorIcon />;
      case 'warning':
        return <WarningIcon />;
      case 'info':
        return <InfoIcon />;
      case 'success':
        return <SuccessIcon />;
      default:
    }
  };

  return (
    <MaterialSnackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={options.length || 6000}
      className={classes.snackbar}
      onClose={(_, reason) => {
        if (!(options.closeOnClick === false && reason === 'clickaway')) {
          closeSnack();
        }
      }}
      open={open}>
      <Card className={classes.card}>
        <CardActions
          classes={{
            root: classnames(classes.actionRoot, options.severity && classes[options.severity]),
          }}>
          {options.severity && getSeverityIcon(options.severity)}
          <Typography className={classes.title} variant='subtitle2'>
            {title}
          </Typography>
          <div className={classes.icons}>
            {options.body && (
              <IconButton aria-label='Show more' className={classnames(classes.expand, expanded && classes.expandOpen)} onClick={() => setExpanded(!expanded)}>
                <ExpandMoreIcon />
              </IconButton>
            )}
            <IconButton className={classes.expand} onClick={closeSnack}>
              <CloseIcon />
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <Paper className={classes.collapse}>
            <div className={classes.flex}>
              <Typography className={classes.body}>{options.body}</Typography>
              {options.click_action && (
                <Button className={classes.button} component={Link} onClick={closeSnack} to={options.click_action}>
                  {t('COMMON.OPEN')}
                </Button>
              )}
            </div>
            {options.image && <img alt={title} className={classes.img} src={options.image} width='100%' />}
          </Paper>
        </Collapse>
      </Card>
    </MaterialSnackbar>
  );
};

export default Snackbar;
