import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import firebase, { COLLECTIONS, collection } from 'fb';
import { Event, EventTime, EventTimeParticipant, SuperEvent } from 'apps/Events/types';
import { connectAutoComplete } from 'react-instantsearch-dom';

export const EVENTS_QUERY_KEY = 'events';
export const EVENT_TIMES_QUERY_KEY = 'event_times';
export const SUPER_EVENT_FROM_EVENT_QUERY_KEY = 'superEventFromEvents';
export const SUPER_EVENT_FROM_EVENT_TIME_QUERY_KEY = 'superEventFromEventTimes';
export const SUPER_EVENT_FROM_PARTICIPANT_QUERY_KEY = 'superEventFromParticipants';
export const PARTICIPANT_FROM_EVENT_QUERY_KEY = 'participantsFromEvent';

export const useEventById = (id: string, doNotNotifyOnStale?: boolean) => {
  return useQuery([EVENTS_QUERY_KEY, id], () => collection<Event>(COLLECTIONS.EVENTS).doc(id).get(), {
    enabled: id !== '',
    notifyOnChangePropsExclusions: doNotNotifyOnStale ? ['isStale'] : [],
  });
};

export const useEventTimesByEventId = (id: string, doNotNotifyOnStale?: boolean) => {
  return useQuery([EVENT_TIMES_QUERY_KEY, id], () => collection<EventTime>(`${COLLECTIONS.EVENTS}/${id}/${COLLECTIONS.EVENTS_EVENT_TIMES}`).get(), {
    enabled: id !== '',
    notifyOnChangePropsExclusions: doNotNotifyOnStale ? ['isStale'] : [],
  });
};

export const useSuperEventsFromEvents = (events: firebase.firestore.Query<Event>, onlyFuture = false, ...keys: unknown[]) => {
  const { eventsToSuperEvents } = useEventUtils();
  return useQuery([SUPER_EVENT_FROM_EVENT_QUERY_KEY, ...keys], () => events.get().then((snapshots) => eventsToSuperEvents(snapshots.docs, onlyFuture)));
};

export const useSuperEventsFromEventTimes = (eventTimes: firebase.firestore.Query<EventTime>, ...keys: unknown[]) => {
  const { eventTimesToSuperEvents } = useEventUtils();
  return useQuery([SUPER_EVENT_FROM_EVENT_TIME_QUERY_KEY, ...keys], () => eventTimes.get().then((snapshots) => eventTimesToSuperEvents(snapshots.docs)));
};

export const useSuperEventsFromParticipants = (participants: firebase.firestore.Query<EventTimeParticipant>, onlyFuture = false, ...keys: unknown[]) => {
  const { eventParticipantsToSuperEvents } = useEventUtils();
  return useQuery([SUPER_EVENT_FROM_PARTICIPANT_QUERY_KEY, ...keys], () =>
    participants.get().then((snapshots) => {
      const onlyParticipatingEvents = snapshots.docs.filter((doc) => doc.data().paymentSucceeded === 'true');
      return eventParticipantsToSuperEvents(onlyParticipatingEvents, onlyFuture);
    }),
  );
};

export const useParticipantsByEvent = (eventId: string, userId: string) => {
  const queryClient = useQueryClient();
  return useQuery([PARTICIPANT_FROM_EVENT_QUERY_KEY, 'eventId', eventId, 'userId', userId], async () => {
    const eventTimes = await queryClient.fetchQuery([EVENT_TIMES_QUERY_KEY, eventId], () =>
      collection<EventTime>(`${COLLECTIONS.EVENTS}/${eventId}/${COLLECTIONS.EVENTS_EVENT_TIMES}`).get(),
    );
    const times: Array<firebase.firestore.DocumentSnapshot<EventTimeParticipant>> = [];
    await Promise.all(
      (eventTimes?.docs || []).map((eventTime) =>
        eventTime.ref
          .collection(COLLECTIONS.EVENTS_EVENT_TIME_PARTICIPANTS)
          .doc(userId)
          .get()
          .then((userEvent) => {
            if (userEvent.exists) {
              if (userEvent.data()!.paymentSucceeded !== 'false') {
                times.push(userEvent as firebase.firestore.DocumentSnapshot<EventTimeParticipant>);
              }
            }
          }),
      ),
    );
    return times;
  });
};

export const useMultipleParticipantsByEvent = (eventId: string, userIds: Array<string>) => {
  const queryClient = useQueryClient();
  return useQuery([PARTICIPANT_FROM_EVENT_QUERY_KEY, 'eventId', eventId, 'userIds', userIds], async () => {
    const times: Array<firebase.firestore.DocumentSnapshot<EventTimeParticipant>> = [];
    const queryResult = await queryClient
      .fetchQuery([EVENT_TIMES_QUERY_KEY, eventId], () => collection<EventTime>(`${COLLECTIONS.EVENTS}/${eventId}/${COLLECTIONS.EVENTS_EVENT_TIMES}`).get())
      .then(async (eventTime) => {
        return await Promise.all(
          userIds.map((userId) => {
            return eventTime.docs.map(async (doc) => {
              const userEvent = doc.ref.collection(COLLECTIONS.EVENTS_EVENT_TIME_PARTICIPANTS).doc(userId);
              return userEvent.get().then((event) => {
                if (event.exists) {
                  if (event.data()!.paymentSucceeded !== 'false') {
                    return event as firebase.firestore.DocumentSnapshot<EventTimeParticipant>;
                  }
                }
              });
            });
          }),
        );
      });
    for (let i = 0; i < queryResult.length; i++) {
      for (let j = 0; j < queryResult[i].length; j++) {
        const value = await queryResult[i][j];
        if (value) {
          times.push(value);
        }
      }
    }
    return times;
  });
};

export const useEventUtils = () => {
  const queryClient = useQueryClient();

  /**
   * Merge multiple event_times into events with their superevent, times and id
   * @param {Array<firebase.firestore.DocumentSnapshot<EventTime>>} eventTimes A event_times collection snapshot
   * @return {Array<SuperEvent>} A promise with an array of superevents
   */
  const eventTimesToSuperEvents = useCallback(
    async (eventTimes: Array<firebase.firestore.DocumentSnapshot<EventTime>>) => {
      const newEvents: Array<SuperEvent> = [];
      await Promise.all(
        eventTimes.map(async (doc) => {
          const superEventId = doc.ref.parent.parent?.id;
          if (!superEventId) {
            return;
          }
          const index = newEvents.findIndex((event) => event.id === superEventId);
          if (index < 0) {
            const superEvent = await queryClient.fetchQuery([EVENTS_QUERY_KEY, superEventId], () =>
              collection<Event>(COLLECTIONS.EVENTS).doc(superEventId).get(),
            );
            const newIndex = newEvents.findIndex((event) => event.id === superEventId);
            if (newIndex >= 0) {
              newEvents[newIndex] = { ...newEvents[newIndex], times: [...newEvents[newIndex].times, doc] };
            } else {
              newEvents.push({ id: superEventId, superEvent: superEvent, times: [doc] });
            }
          } else {
            newEvents[index] = { ...newEvents[index], times: [...newEvents[index].times, doc] };
          }
        }),
      );
      return newEvents.sort((a, b) => (a.times[0].data()?.timeStart.toMillis() || 0) - (b.times[0].data()?.timeStart.toMillis() || 0));
    },
    [queryClient],
  );

  /**
   * Create events with times from superevents
   * @param {Array<firebase.firestore.DocumentSnapshot<Event>>} events A events collection snapshot
   * @param {boolean} includePrevious Should event-times in the past also be retrieved
   * @return {Promise<Array<SuperEvent>>} A promise with an array of superevents
   */
  const eventsToSuperEvents = useCallback(
    async (events: Array<firebase.firestore.DocumentSnapshot<Event>>, includePrevious = false) => {
      const newEvents: Array<SuperEvent> = [];
      await Promise.all(
        events.map((doc) => {
          const query = includePrevious
            ? collection<EventTime>(`${COLLECTIONS.EVENTS}/${doc.id}/${COLLECTIONS.EVENTS_EVENT_TIMES}`)
            : collection<EventTime>(`${COLLECTIONS.EVENTS}/${doc.id}/${COLLECTIONS.EVENTS_EVENT_TIMES}`).where('timeStart', '>=', new Date());
          return queryClient
            .fetchQuery([EVENT_TIMES_QUERY_KEY, doc.id, { includePrevious }], () => query.get())
            .then((times) => {
              if (times.size) {
                newEvents.push({ id: doc.id, superEvent: doc, times: times.docs });
              }
            });
        }),
      );
      return newEvents.sort((a, b) => (a.times[0].data()?.timeStart.toMillis() || 0) - (b.times[0].data()?.timeStart.toMillis() || 0));
    },
    [queryClient],
  );

  /**
   * Create events with times from participant event times
   * @param {Array<firebase.firestore.DocumentSnapshot<EventTimeParticipant>>} participants A events collection snapshot
   * @return {Promise<Array<SuperEvent>>} A promise with an array of superevents
   */
  const eventParticipantsToSuperEvents = useCallback(
    async (participants: Array<firebase.firestore.DocumentSnapshot<EventTimeParticipant>>, onlyFuture = false) => {
      const eventTimes: Array<firebase.firestore.DocumentSnapshot<EventTime>> = [];
      await Promise.all(
        participants.map((doc) => {
          const eventTimeRef = doc.ref.parent.parent;
          if (!eventTimeRef) {
            return;
          }
          return eventTimeRef.get().then((time) => {
            if (onlyFuture && time.data()?.timeEnd.toDate() < new Date()) {
              return;
            }
            eventTimes.push(time as firebase.firestore.DocumentSnapshot<EventTime>);
          });
        }),
      );
      return eventTimesToSuperEvents(eventTimes);
    },
    [eventTimesToSuperEvents],
  );

  return { eventTimesToSuperEvents, eventsToSuperEvents, eventParticipantsToSuperEvents };
};
