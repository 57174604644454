import i18n from 'i18n';
import firebase, { COLLECTIONS } from 'fb';
import { getFormattedDate } from 'utils';
import { Location, Event, EventTime, EventHostType } from 'apps/Events/types';
import { useTranslation } from 'react-i18next';

export const weekdays = [
  i18n.t('events:EVENTS.CARD.MONDAY').substring(0, 3),
  i18n.t('events:EVENTS.CARD.TUESDAY').substring(0, 3),
  i18n.t('events:EVENTS.CARD.WEDNESDAY').substring(0, 3),
  i18n.t('events:EVENTS.CARD.THURSDAY').substring(0, 3),
  i18n.t('events:EVENTS.CARD.FRIDAY').substring(0, 3),
  i18n.t('events:EVENTS.CARD.SATURDAY').substring(0, 3),
  i18n.t('events:EVENTS.CARD.SUNDAY').substring(0, 3),
];

export const weekdaysLongPlural = [
  i18n.t('events:EVENTS.CARD.MONDAYS'),
  i18n.t('events:EVENTS.CARD.TUESDAYS'),
  i18n.t('events:EVENTS.CARD.WEDNESDAYS'),
  i18n.t('events:EVENTS.CARD.THURSDAYS'),
  i18n.t('events:EVENTS.CARD.FRIDAYS'),
  i18n.t('events:EVENTS.CARD.SATURDAYS'),
  i18n.t('events:EVENTS.CARD.SUNDAYS'),
];

/**
 * Get the amount of theachers divieded on wanted teachers at an event-time
 * @param {*} time - The event-time
 * @return {string} Ex.: "Teachers: 1/3"
 */
export const getEventTimeTeachers = (time: firebase.firestore.DocumentSnapshot<EventTime>) => {
  return `${i18n.t('events:EVENTS.CARD.TEACHERS')} ${
    time.data()!.teachers
      ? `${time.data()!.teachers.list?.length || '0'}/${time.data()!.teachers.wanted_amount || '-'} ${i18n.t('common:COMMON.PCS')}.`
      : i18n.t('events:EVENTS.CARD.NOTSET')
  }`;
};

/**
 * Get a formated string with the location of an event-time
 * @param {firebase.firestore.DocumentSnapshot} time - The event-time
 * @return {string} Ex.: "Location: Wall street" or "Location: not set"
 */
export const getEventTimeLocation = (time: firebase.firestore.DocumentSnapshot<EventTime>) => {
  return `${i18n.t('events:EVENTS.CARD.LOCATION')}${time.data()!.location?.name || i18n.t('events:EVENTS.CARD.NOTSET')}`;
};

/**
 * Get a formated string with the amount of registered participants to an event-time
 * @param {firebase.firestore.DocumentSnapshot} time - The event-time
 * @return {string} Ex.: "Registered: 5/20"
 */
export const getEventTimeRegistered = (time: firebase.firestore.DocumentSnapshot<EventTime>) => {
  return `${i18n.t('events:EVENTS.CARD.REGISTERED')}: ${time.data()!.registered}/${time.data()!.maxParticipants}`;
};

/**
 * Get the name of collection of ref
 * @param event - The event to check
 * @returns `EventHostType`
 */
export const getRefCollectionName = (ref: firebase.firestore.DocumentReference) => {
  return ref.parent.id as COLLECTIONS;
};

/**
 * Get the type of event-host. Either a user or an organization
 * @param event - The event to check
 * @returns `COLLECTIONS`
 */
export const getEventHostType = (event: firebase.firestore.DocumentSnapshot<Event>) => {
  return getRefCollectionName(event.data()!.host);
};

/**
 * Get the type of location-owner. Either a user or an organization
 * @param event - The location to check
 * @returns `EventHostType`
 */
export const getLocationOwnerType = (location: firebase.firestore.DocumentSnapshot<Location>) => {
  return getRefCollectionName(location.data()!.owner);
};

export const noTimePatternText = () => {
  return '';
};

export const getLocation = (locationOccurences: Array<string>) => {
  const locations: Array<{ location: string; occurrences: number }> = [];
  locationOccurences.map((location) => {
    if (location !== 'none') {
      const index = locations.map((e) => e.location).indexOf(location);
      if (index >= 0) {
        locations[index].occurrences++;
      } else {
        locations.push({
          location: location,
          occurrences: 1,
        });
      }
    }
  });
  if (locations.length < 1) {
    //return t('EVENTS.CARD.LOCATION') + t('EVENTS.CARD.NOTSET');
    return '';
  }

  let highestValue = 0;
  let returnLocation = '';
  for (let i = 0; i < locations.length; i++) {
    if (locations[i].occurrences > highestValue) {
      highestValue = locations[i].occurrences;
      returnLocation = locations[i].location;
    }
  }
  return returnLocation;
};

export const getPostal = (address: string) => {
  const stringList = address.replaceAll(',', '').split(' ');
  if (stringList.length < 2) {
    return address;
  }

  const followsNumber = [];
  for (let i = 0; i < stringList.length - 1; i++) {
    if (/^\d+$/.test(stringList[i])) {
      followsNumber.push(stringList[i + 1]);
    }
  }

  return followsNumber.pop() || address;
};

export const getTimePatternText = (sortedDates: Array<{ timeStart: Date; timeEnd: Date }>) => {
  if (sortedDates.length <= 0) {
    return '';
  }
  const schedules: Array<Array<{ fromTime: string; timeEnd: string; occurrences: number }>> = [[], [], [], [], [], [], []];
  let numberOfSchedules = 0;
  sortedDates.map((sortedDate) => {
    const dayIndex: number = sortedDate.timeStart.getDay() || 0;
    const weekday = getFormattedDate(sortedDate.timeStart, true, false).split(' ')[0];
    const fromTime = getFormattedDate(sortedDate.timeStart, false, true);
    const timeEnd = getFormattedDate(sortedDate.timeEnd, false, true);
    const index = schedules[dayIndex].findIndex((e) => e.fromTime === fromTime && e.timeEnd === timeEnd);
    if (index >= 0) {
      schedules[dayIndex][index].occurrences++;
    } else {
      numberOfSchedules++;
      schedules[dayIndex].push({
        fromTime,
        timeEnd,
        occurrences: 1,
      });
    }
  });

  if (numberOfSchedules >= 3) {
    if (hasSameStartEnd(sortedDates)) {
      return getFormattedDate(sortedDates[0].timeStart, false, true) + ' - ' + getFormattedDate(sortedDates[0].timeEnd, false, true);
    }
    return noTimePatternText();
  }

  schedules.push(schedules[0]);
  schedules.shift();

  const occurrencesArray: Array<number> = [];
  let noTimePatternFound = false;

  const highestOccurrencesScheduales = schedules.map((weekday) => {
    let highestOccurrence = 0;
    let returnValue: Array<{ fromTime: string; timeEnd: string; occurrences: number }> = [];
    let notHigherCounter = 0;
    for (let i = 0; i < weekday.length; i++) {
      if (highestOccurrence < weekday[i].occurrences) {
        returnValue = [weekday[i]];
        highestOccurrence = weekday[i].occurrences;
      } else {
        notHigherCounter++;
      }
    }

    if (notHigherCounter / highestOccurrence >= 0.2 || notHigherCounter >= 3) {
      noTimePatternFound = true;
    }

    if (highestOccurrence > 0) {
      occurrencesArray.push(highestOccurrence);
    }
    return returnValue;
  });

  if (noTimePatternFound) {
    return noTimePatternText();
  }

  if (Math.max(...occurrencesArray) - Math.min(...occurrencesArray) >= 3) {
    return noTimePatternText();
  }

  const daysWithTime = [];
  for (let i = 0; i < 7; i++) {
    if (highestOccurrencesScheduales[i].length > 0) {
      const currentFromTime = highestOccurrencesScheduales[i][0].fromTime;
      const currentTimeEnd = highestOccurrencesScheduales[i][0].timeEnd;
      const index = daysWithTime.findIndex((e) => e.fromTime === currentFromTime && e.timeEnd === currentTimeEnd);
      if (index >= 0) {
        daysWithTime[index].days.push(i);
      } else {
        daysWithTime.push({
          fromTime: currentFromTime,
          timeEnd: currentTimeEnd,
          days: [i],
        });
      }
    }
  }
  const timesCombo = daysWithTime.map((time) => {
    let prevDay = -1;
    const combo: Array<Array<number>> = [];
    let currentCombo: Array<number> = [];
    time.days.forEach((day, index) => {
      currentCombo.push(day);
      if (day !== prevDay + 1 && index !== 0) {
        combo.push(currentCombo);
        currentCombo = [];
      }
      prevDay = day;
    });
    if (currentCombo.length > 0) {
      combo.push(currentCombo);
    }
    return {
      combo: combo,
      fromTime: time.fromTime,
      timeEnd: time.timeEnd,
    };
  });

  if (timesCombo.length >= 3) {
    return noTimePatternText();
  }

  let returnText = '';
  timesCombo.forEach((timeCombo, index) => {
    let currentText = '';
    timeCombo.combo.forEach((days) => {
      if (days.length === 1) {
        const plural = highestOccurrencesScheduales.find((s) => s.length)?.find((s) => s.occurrences > 5);
        currentText = plural ? weekdaysLongPlural[days[0]] : weekdays[days[0]];
      } else if (days.length === 2) {
        currentText = weekdays[days[0]] + ' ' + i18n.t('events:EVENTS.CARD.AND') + ' ' + weekdays[days[1]];
      } else if (days.length >= 3) {
        currentText = weekdays[days[0]] + '-' + weekdays[days[days.length - 1]];
      }
    });
    currentText += ' ' + timeCombo.fromTime + '-' + timeCombo.timeEnd;
    if (index === timesCombo.length - 1 && index !== 0) {
      returnText += ' ' + i18n.t('events:EVENTS.CARD.AND') + ' ';
    } else if (index !== 0) {
      returnText += ', ';
    }
    returnText += currentText;
  });

  return returnText;
};

export const timeAndPeriodText = (sortedDates: Array<{ timeStart: Date; timeEnd: Date }>, disableEnd = false) => {
  const timeText = getTimePatternText(sortedDates);
  const weekOrDateText = getWeekOrDatePeriodText(sortedDates);

  if (timeText.split(' ').length === 2 && sortedDates.length > 5 && disableEnd) {
    return timeText.split(' ').join(' • ');
  }

  if (timeText.length > 0 && weekOrDateText.length > 0) {
    if (sortedDates.length > 1) {
      return (
        <>
          <span style={{ display: 'inline-block' }}>{timeText}</span>
          <span style={{ display: 'inline-block' }}>&nbsp;• {weekOrDateText}</span>
        </>
      );
    } else {
      return getStartEndText(sortedDates);
    }
  }
  return timeText + weekOrDateText;
};

export const getWeekNumber = (d: Date) => {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  const weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
  return weekNo;
};

export const getStartEndText = (sortedDates: Array<{ timeStart: Date; timeEnd: Date }>) => {
  if (sortedDates.length !== 1) {
    return '';
  }

  const time = sortedDates[0];
  const timeStart = getFormattedDate(time.timeStart).split(' ').slice(1).join(' ');
  const timeEnd = getFormattedDate(time.timeEnd).split(' ').slice(1).join(' ');

  return `${timeStart} - ${timeEnd}`;
};

export const getDateDisplayText = (sortedDates: Array<{ timeStart: Date; timeEnd: Date }>) => {
  if (sortedDates.length < 2) {
    return '';
  }
  return (
    getFormattedDate(sortedDates[0].timeStart || new Date(), false, false) +
    ' - ' +
    getFormattedDate(sortedDates[sortedDates.length - 1].timeStart || new Date(), false, false) +
    ' (' +
    sortedDates.length +
    'x)'
  );
  return '';
};

export const getWeekOrDatePeriodText = (sortedDates: Array<{ timeStart: Date; timeEnd: Date }>) => {
  if (sortedDates.length < 1) {
    return '';
  } else if (sortedDates.length === 1) {
    return getFormattedDate(sortedDates[0].timeStart || new Date(), false, false);
  } else if (sortedDates.length === 2) {
    return getDateDisplayText(sortedDates);
  }
  const fromWeek = getWeekNumber(sortedDates[0].timeStart);
  const endWeek = getWeekNumber(sortedDates[sortedDates.length - 1].timeStart);

  if (fromWeek !== endWeek || sortedDates.length < 5) {
    return getDateDisplayText(sortedDates);
  }

  return i18n.t('events:EVENTS.CARD.WEEK') + ' ' + fromWeek;
};

export const getPriceText = (price: string | number) => {
  return `kr ${price},-`;
};

export const getSameTimeText = (sortedDates: Array<{ timeStart: Date; timeEnd: Date }>) => {
  if (sortedDates.length < 2) {
    return '';
  }

  const time = getFormattedDate(sortedDates[0].timeStart, false, true) + ' - ' + getFormattedDate(sortedDates[0].timeEnd, false, true);
  const dates = getFormattedDate(sortedDates[0].timeStart, false, false) + ' - ' + getFormattedDate(sortedDates[sortedDates.length - 1].timeEnd, false, false);

  return time + ' • ' + dates;
};

export const isSameDay = (dates: Array<{ timeStart: Date; timeEnd: Date }>) => {
  if (dates.length < 2) {
    return false;
  }
  const first = dates[0];
  return dates.every((d) => d.timeStart.getDay() === first.timeStart.getDay());
};

export const hasSameStartEnd = (dates: Array<{ timeStart: Date; timeEnd: Date }>) => {
  if (dates.length < 2) {
    return false;
  }
  const first = dates[0];
  return dates.every(
    (d) =>
      d.timeStart.getHours() === first.timeStart.getHours() &&
      d.timeStart.getMinutes() === first.timeStart.getMinutes() &&
      d.timeEnd.getHours() === first.timeEnd.getHours() &&
      d.timeEnd.getMinutes() === first.timeEnd.getMinutes(),
  );
};

export const getMembershipTiers = (t: (arg0: string) => string) => {
  return [
    {
      title: t('COMPONENT.MEMBERSHIP.FREE.TITLE'),
      staticTitle: 'free',
      subheader: '',
      priceValue: 0,
      familyPriceValue: 0,
      description: t('COMPONENT.MEMBERSHIP.TIERS.DESCRIPTION').split(','),
      commitmentWeeks: 0,
    },
    {
      title: t('COMPONENT.MEMBERSHIP.LOYAL.TITLE'),
      staticTitle: 'loyal',
      subheader: '',
      priceValue: 49,
      familyPriceValue: 89,
      description: t('COMPONENT.MEMBERSHIP.TIERS.DESCRIPTION').split(','),
      commitmentWeeks: 0,
    },
    {
      title: t('COMPONENT.MEMBERSHIP.STANDARD.TITLE'),
      staticTitle: 'standard',
      subheader: '',
      priceValue: 99,
      familyPriceValue: 179,
      description: t('COMPONENT.MEMBERSHIP.TIERS.DESCRIPTION').split(','),
      commitmentWeeks: 0,
    },
    {
      title: '',
      staticTitle: '',
      subheader: '',
      priceValue: 0,
      familyPriceValue: 0,
      description: [],
      commitmentWeeks: 0,
    },
  ];
};
