import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  skipToContentContainer: {
    zIndex: 1000,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  skipToContentLink: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    mixBlendMode: 'difference',
    position: 'absolute',
    transform: 'translate(-100%, 0)',
    '&:focus': {
      transform: 'translate(0, 0)',
    },
  },
}));

const SkipToContent = () => {
  const { t } = useTranslation(['common']);
  const classes = useStyles();
  return (
    <div className={classes.skipToContentContainer}>
      <a className={classes.skipToContentLink} href='#main'>
        {t('SKIPTOCONTENT')}
      </a>
    </div>
  );
};
export default SkipToContent;
