import { useMemo } from 'react';
import { useQuery, UseQueryResult, useQueryClient, QueryClient } from 'react-query';
import firebase, { COLLECTIONS, collection } from 'fb';
import { Organization } from 'types';
import { useUserState } from 'hooks/User';

export const ORG_QUERY_KEY = 'organization';
export const ORGS_QUERY_KEY = 'organizations';

export const useOrganizationById = (organzationId: string) => {
  return useQuery([ORG_QUERY_KEY, organzationId], () => collection<Organization>(COLLECTIONS.ORGANIZATIONS).doc(organzationId).get(), {
    enabled: organzationId !== '',
  });
};

export const useOrganizationsWhereIsOwner = (userId?: string) => {
  const user = useUserState();
  const queryClient = useQueryClient();
  return addCollectionToCache(
    useQuery([ORGS_QUERY_KEY, 'is_owner', userId || (user ? user.id : '')], () =>
      collection<Organization>(`${COLLECTIONS.ORGANIZATIONS}`)
        .where('owners', 'array-contains', userId || (user ? user.id : ''))
        .get(),
    ),
    queryClient,
  );
};

export const useOrganizationsWhereIsAdmin = (userId?: string) => {
  const user = useUserState();
  const queryClient = useQueryClient();
  return addCollectionToCache(
    useQuery([ORGS_QUERY_KEY, 'is_admin', userId || (user ? user.id : '')], () =>
      collection<Organization>(`${COLLECTIONS.ORGANIZATIONS}`)
        .where('admins', 'array-contains', userId || (user ? user.id : ''))
        .get(),
    ),
    queryClient,
  );
};

export const useOrganizationsWhereIsEmployee = (userId?: string) => {
  const user = useUserState();
  const queryClient = useQueryClient();
  return addCollectionToCache(
    useQuery([ORGS_QUERY_KEY, 'is_employee', userId || (user ? user.id : '')], () =>
      collection<Organization>(`${COLLECTIONS.ORGANIZATIONS}`)
        .where('employees', 'array-contains', userId || (user ? user.id : ''))
        .get(),
    ),
    queryClient,
  );
};

const addCollectionToCache = (query: UseQueryResult<firebase.firestore.QuerySnapshot<Organization>, unknown>, queryClient: QueryClient) => {
  if (!query.isLoading) {
    query.data?.forEach((doc) => {
      queryClient.setQueryData([ORG_QUERY_KEY, doc.id], doc);
    });
  }
  return query;
};

export const useHasOrganizationOwnerAccess = (organzationId: string, userId?: string) => {
  const user = useUserState();
  const { data, isLoading } = useOrganizationById(organzationId);
  if (organzationId === '') {
    return { hasAccess: false, isLoading: false };
  }
  return { hasAccess: data?.exists ? data.data()!.owners.includes(userId || (user ? user.id : '')) : false, isLoading };
};

export const useHasOrganizationAdminAccess = (organzationId: string, userId?: string) => {
  const user = useUserState();
  const { hasAccess: isOwner, isLoading: isOwnerLoading } = useHasOrganizationOwnerAccess(organzationId, userId);
  const { data, isLoading } = useOrganizationById(organzationId);
  if (organzationId === '') {
    return { hasAccess: false, isLoading: false };
  }
  return {
    hasAccess: isOwner || (data?.exists ? data.data()!.admins.includes(userId || (user ? user.id : '')) : false),
    isLoading: isLoading || isOwnerLoading,
  };
};

export const useHasOrganizationEmployeeAccess = (organzationId: string, userId?: string) => {
  const user = useUserState();
  const { hasAccess: isAdmin, isLoading: isAdminLoading } = useHasOrganizationAdminAccess(organzationId, userId);
  const { data, isLoading } = useOrganizationById(organzationId);
  if (organzationId === '') {
    return { hasAccess: false, isLoading: false };
  }
  return {
    hasAccess: isAdmin || (data?.exists ? data.data()!.employees.includes(userId || (user ? user.id : '')) : false),
    isLoading: isLoading || isAdminLoading,
  };
};

export const useOrganizationsWhereIsMember = (userId?: string) => {
  const { data: orgsOwner, isLoading: isOwnersLoading } = useOrganizationsWhereIsOwner();
  const { data: orgsAdmin, isLoading: isAdminsLoading } = useOrganizationsWhereIsAdmin();
  const { data: orgsEmployee, isLoading: isEmployeesLoading } = useOrganizationsWhereIsEmployee();
  const orgsOwnerList = useMemo(() => (orgsOwner ? orgsOwner.docs : []), [orgsOwner]);
  const orgsAdminList = useMemo(() => (orgsAdmin ? orgsAdmin.docs : []), [orgsAdmin]);
  const orgsEmployeeList = useMemo(() => (orgsEmployee ? orgsEmployee.docs : []), [orgsEmployee]);
  const isLoading = useMemo(() => isOwnersLoading || isAdminsLoading || isEmployeesLoading, [isOwnersLoading, isAdminsLoading, isEmployeesLoading]);

  return { ownerList: orgsOwnerList, adminList: orgsAdminList, employeeList: orgsEmployeeList, isLoading };
};
