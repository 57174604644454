import { ReactNode } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import URLS from 'URLS';
import { useUserState } from 'hooks/User';
import { useTranslation } from 'react-i18next';

// Material UI Components
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';

// Assets/Icons
import LOGO from 'assets/icons/ECPlayLogo.svg';
import AccountIcon from '@material-ui/icons/AccountCircleOutlined';
import EventIcon from '@material-ui/icons/Event';
import ChallengeIcon from '@material-ui/icons/EmojiEventsRounded';
import PlayerIcon from '@material-ui/icons/PlayArrowRounded';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AcademyIcon from '@material-ui/icons/School';
import ResourcesIcon from '@material-ui/icons/LibraryBooks';

// Project components
import Paper from 'components/layout/Paper';
import Sidebar from 'components/navigation/Sidebar';
import Apps from 'components/navigation/Apps';
import Notifications from 'components/navigation/Notifications';
import { useAuth, useIsSchoolAuth } from 'hooks/Auth';
import { useAdmin } from 'apps/AdminPanel/hooks/useAdmin';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles((theme) => ({
  nav: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 0,
    padding: theme.spacing(1, 0),
    margin: 'auto',
    width: '100%',
    marginBottom: 0,
  },
  navContent: {
    maxWidth: theme.breakpoints.values.xl,
    margin: 'auto',
    padding: theme.spacing(0, 1),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  logoWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 15,
    },
  },
  menuButton: {
    color: theme.palette.text.primary,
  },
  grow: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
  },
  fullWidth: {
    width: '100%',
  },
  flex: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  button: {
    marginRight: 5,
    marginLeft: 5,
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
  profileName: {
    margin: theme.spacing('auto', 1),
    fontSize: '16px',
    color: theme.palette.text.primary,
    minWidth: '40px',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  avatar: {
    width: 45,
    height: 45,
    fontSize: 18,
    fontWeight: 'bold',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

type URIbuttonProps = {
  data: {
    link: string;
    icon: ReactNode;
    text: string;
    external?: boolean;
  };
};

const URIbutton = ({ data }: URIbuttonProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Button
      className={classes.button}
      color='secondary'
      onClick={
        data.link === window.location.pathname
          ? () => window.location.reload()
          : () => {
              if (data.external) {
                window.open(data.link);
              } else {
                navigate(data.link);
              }
            }
      }
      startIcon={data.icon}
      variant={window.location.pathname === data.link ? 'outlined' : 'text'}>
      {data.text}
    </Button>
  );
};

type PersonIconProps = {
  name: string;
  img?: string;
};

const PersonIcon = ({ name, img }: PersonIconProps) => {
  const classes = useStyles();
  return (
    <Button component={Link} onClick={URLS.ACCOUNT === window.location.pathname ? () => window.location.reload() : undefined} to={URLS.ACCOUNT}>
      <div className={classes.profileContainer}>
        <Avatar className={classes.avatar} src={img}>
          {name.substring(0, 1)}
        </Avatar>
      </div>
    </Button>
  );
};

function TopBar() {
  const { t } = useTranslation(['common']);
  const classes = useStyles();
  const user = useUserState();
  const [auth, isAuthLoading] = useAuth();
  const [admin, isAdminLoading] = useAdmin();
  const [isSchoolAuth, _, schoolAuthDoc] = useIsSchoolAuth();
  const isSchoolStudent = schoolAuthDoc !== null ? schoolAuthDoc?.data().role === 'student' : false;
  const location = useLocation();

  return (
    <Paper className={classes.nav} outlined>
      <div className={classes.navContent}>
        <Hidden lgUp>
          <Sidebar />
        </Hidden>
        <Button className={classes.logoWrapper} component={Link} to={location.pathname === URLS.HUB ? URLS.LANDING : URLS.HUB}>
          <img alt='EC-Play logo' height='40em' src={LOGO} width='auto' />
        </Button>
        <div className={classes.grow}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Hidden className={classes.fullWidth} implementation='css' mdDown>
            <div className={classes.flex}>
              {process.env.REACT_APP_SHOW_CHALLENGE && isSchoolAuth !== true && (
                <URIbutton data={{ link: URLS.CHALLENGE, text: t('APPS.CHALLENGE'), icon: <ChallengeIcon /> }} />
              )}
              {process.env.REACT_APP_SHOW_EVENTS && <URIbutton data={{ link: URLS.EVENTS_EVENTS, text: t('APPS.EVENTS'), icon: <EventIcon /> }} />}
              {process.env.REACT_APP_SHOW_PLAYER && <URIbutton data={{ link: URLS.EDTECH, text: t('APPS.EDTECH'), icon: <PlayerIcon /> }} />}
              {process.env.REACT_APP_SHOW_RESOURCES && <URIbutton data={{ link: URLS.RESOURCES, text: t('APPS.RESOURCES'), icon: <ResourcesIcon /> }} />}
              {process.env.REACT_APP_SHOW_ACADEMY && <URIbutton data={{ link: URLS.ACADEMY, text: t('APPS.ACADEMY'), icon: <AcademyIcon /> }} />}
              {isSchoolStudent !== true && <URIbutton data={{ link: URLS.SHOP, text: t('APPS.SHOP'), icon: <ShoppingCartIcon />, external: true }} />}

              {/*
              This is temporary removed because there is no "about us" page.
              <URIbutton data={{ link: URLS.ORG, text: t('APPS.ORG'), icon: <InfoIcon />, external: true }} />
              */}
            </div>
          </Hidden>
        </div>
        <Notifications />
        <Hidden mdDown>
          <Apps />
        </Hidden>
        <div>
          {user ? (
            <PersonIcon img={user.data()?.picture} name={user.data()?.name || ''} />
          ) : (
            <IconButton className={classes.menuButton} component={Link} state={{ referrer: window.location.pathname }} to={URLS.SIGN_IN}>
              <AccountIcon />
            </IconButton>
          )}
        </div>
      </div>
    </Paper>
  );
}

export default TopBar;
