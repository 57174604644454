import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/firebase-messaging';
import 'firebase/functions';
import * as geofirestore from 'geofirestore';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};
let messaging: firebase.messaging.Messaging | null = null;
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(process.env.REACT_APP_VAPID_KEY || '');
}
const db = firebase.firestore();
const geoDb = geofirestore.initializeApp(db);
const storage = firebase.storage();
const auth = firebase.auth();
const functions = firebase.app().functions('europe-west1');

if (process.env.REACT_APP_EMULATOR) {
  db.useEmulator('localhost', 8080);
  auth.useEmulator('http://localhost:9099/');
  functions.useEmulator('localhost', 5001);
  storage.useEmulator('localhost', 9199);
}

export { db, geoDb, geofirestore, storage, auth, functions, messaging };
export default firebase;
