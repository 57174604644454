import firebase, { COLLECTIONS, collection } from 'fb';
import { getSongById } from 'SongCaching';
import { getFirstUrlPath, possibleFirstUrlPaths } from 'components/Player/utils/multiplePlayerApps';

export async function getHistorySongs(auth) {
  let snapshot = await collection(COLLECTIONS.SONGS_HISTORY).doc(auth.uid).get();
  if (!snapshot.exists) {
    return [];
  }
  const historyDoc = snapshot.data();
  const appHistory = historyDoc ? historyDoc[getFirstUrlPath()] || [] : [];
  return appHistory;
}

export async function getPrivateSongs(auth) {
  let snapshot = await collection(COLLECTIONS.SONGS_PRIVATE).where('creator', '==', auth.uid).get();
  if (snapshot.empty) {
    return [];
  }
  const privateSongs = snapshot.docs.map((doc) => doc.data());
  return privateSongs;
}

export async function getPrivateSong(songId) {
  const song = await collection(COLLECTIONS.SONGS_PRIVATE).doc(songId).get();
  return song.data();
}

export async function initFavorites(uid) {
  const userDoc = collection(COLLECTIONS.FAVORITES).doc(uid);
  const snapshot = await userDoc.get();
  const defaultFavoritesObject = Object.fromEntries(possibleFirstUrlPaths.map((path) => [path, []]));

  if (!snapshot.exists) {
    await userDoc.set({
      favoriteSongs: defaultFavoritesObject,
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      uid: uid,
    });
    return defaultFavoritesObject;
  }
  return snapshot.data().favoriteSongs;
}
export function incrementPlayCount(songId) {
  collection(COLLECTIONS.SONGS)
    .doc(songId)
    .update({ playcount: firebase.firestore.FieldValue.increment(1) });
}

export function addToUserHistory(userId, songId) {
  const doc = collection(COLLECTIONS.SONGS_HISTORY).doc(userId);
  const playerApp = getFirstUrlPath();
  doc.get().then((snapshot) => {
    if (!snapshot.exists) {
      doc.set({
        [playerApp]: [songId],
      });
      return;
    }
    let historyMap = (snapshot.exists && snapshot.data()) || {};
    let history = historyMap[playerApp] || [];
    const lastSong = history[history.length - 1];

    if (lastSong !== songId && songId) {
      if (history.includes(songId)) {
        history = history.filter((element) => element !== songId);
      }
      history.push(songId);
      if (history.length > 10) {
        history.shift();
      }
      const updateObj = {};
      updateObj[playerApp] = history;
      doc.update(updateObj);
    }
  });
}
