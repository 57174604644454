import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { COLLECTIONS, collection } from 'fb';
import { useTranslation } from 'react-i18next';
import { User } from 'types';

export const USERS_QUERY_KEY = 'users';

export const useUserById = (id: string) => {
  const { t } = useTranslation(['common']);
  return useQuery(
    [USERS_QUERY_KEY, id],
    () =>
      collection<User>(COLLECTIONS.USERS)
        .doc(id)
        .get()
        .then((user) => {
          if (user.exists) {
            return user;
          } else {
            throw Error(t('USERS.NOTFOUND'));
          }
        }),
    {
      enabled: id !== '',
    },
  );
};

export const useUserByEmail = (email: string) => {
  const { t } = useTranslation(['common']);
  return useQuery(
    [USERS_QUERY_KEY, email],
    () =>
      collection<User>(COLLECTIONS.USERS)
        .where('email', '==', email)
        .get()
        .then((users) => {
          if (!users.empty && users.docs[0].exists) {
            return users.docs[0];
          } else {
            throw Error(t('USERS.NOTFOUND'));
          }
        }),
    {
      enabled: email !== '',
    },
  );
};

export const useUsersUtils = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(['common']);
  /**
   * @param {string} id - Id of user to find
   * @param {boolean} [refetch=false] - Whether to refetch the user or not
   * @return {firebase.firestore.DocumentSnapshot} The user-document
   * @throws Will throw an error if the user couldn't be found in either the cache or in firestore
   */
  const getUserById = useCallback(
    async (id: string, refetch = false) => {
      const user = await queryClient.fetchQuery(
        [USERS_QUERY_KEY, id],
        () => collection<User>(COLLECTIONS.USERS).doc(id).get(),
        refetch ? { staleTime: 1 } : {},
      );
      if (user.exists) {
        return user;
      } else {
        throw Error(t('USERS.NOTFOUND'));
      }
    },
    [queryClient],
  );

  /**
   * @param {string} email - Email of user to find
   * @return {firebase.firestore.DocumentSnapshot} The user-document
   * @throws Will throw an error if the user couldn't be found in either the cache or in firestore
   */
  const getUserByEmail = useCallback(
    async (email: string) => {
      return queryClient.fetchQuery([USERS_QUERY_KEY, email], () =>
        collection<User>(COLLECTIONS.USERS)
          .where('email', '==', email)
          .get()
          .then((users) => {
            if (!users.empty && users.docs[0].exists) {
              return users.docs[0];
            } else {
              throw Error(t('USERS.NOTFOUND'));
            }
          }),
      );
    },
    [queryClient],
  );

  /**
   * @param {string} id - Id of parent
   * @return {firebase.firestore.DocumentSnapshot} The user-document of all childs
   * @throws Will throw an error if the user couldn't be found in either the cache or in firestore
   */
  //TODO MAKE THIS
  const getAllChildDocByParentId = useCallback(
    async (id: string) => {
      const user = await queryClient.fetchQuery([USERS_QUERY_KEY, id], () => collection<User>(COLLECTIONS.USERS).doc(id).get());
      if (user.exists) {
        return user;
      } else {
        throw Error(t('USERS.NOTFOUND'));
      }
    },
    [queryClient],
  );

  return { getUserById, getUserByEmail };
};
