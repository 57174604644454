import { useEffect, useState } from 'react';
import { Quote } from '..';

// Material UI Components
import { Divider, IconButton, makeStyles, Typography } from '@material-ui/core';

// Assets/Icons
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  quoteContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  quoteText: {
    textAlign: 'center',
    fontSize: '20px',
    fontStyle: 'italic',
  },
  quoteName: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  quote: {
    width: '100%',
  },
  divider: {
    backgroundColor: 'black',
    height: '30%',
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  arrow: {
    margin: theme.spacing(2),
  },
}));

export type QuotesProps = {
  quotes: Array<Quote>;
  amount?: number;
};

const Quotes = ({ quotes, amount = 3 }: QuotesProps) => {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);
  const [renderQuotes, setRenderQuotes] = useState(quotes.slice(0, amount));

  useEffect(() => {
    if (activeIndex + amount > quotes.length) {
      setRenderQuotes(quotes.slice(activeIndex, quotes.length).concat(quotes.slice(0, amount - (quotes.length - activeIndex))));
    } else {
      setRenderQuotes(quotes.slice(activeIndex, activeIndex + amount));
    }
  }, [activeIndex, amount, quotes]);

  const handleNext = () => {
    if (activeIndex + 1 < quotes.length) {
      setActiveIndex(activeIndex + 1);
    } else {
      setActiveIndex(0);
    }
  };

  const handlePrev = () => {
    if (activeIndex - 1 >= 0) {
      setActiveIndex(activeIndex - 1);
    } else {
      setActiveIndex(quotes.length - 1);
    }
  };

  return (
    <div className={classes.container}>
      {amount !== quotes.length && (
        <IconButton className={classes.arrow} onClick={handlePrev}>
          <ArrowLeft fontSize={'large'} />
        </IconButton>
      )}
      {renderQuotes.map((quote, i) => (
        <div className={classes.quoteContainer} key={i}>
          {i > 0 && <Divider className={classes.divider} orientation='vertical' />}
          <div className={classes.quote}>
            <Typography className={classes.quoteText} variant='h3'>{`"${quote.text}"`}</Typography>
            {quote.name && <Typography className={classes.quoteName} variant='h5'>{`- ${quote.name}`}</Typography>}
          </div>
        </div>
      ))}
      {amount !== quotes.length && (
        <IconButton className={classes.arrow} onClick={handleNext}>
          <ArrowRight fontSize={'large'} />
        </IconButton>
      )}
    </div>
  );
};

export default Quotes;
