import { ReactNode, useState, useContext, useEffect, createContext } from 'react';

import firebase, { collection, COLLECTIONS } from 'fb';
import { Playlist, Song, PlayerType, SchoolMember } from 'types';
import { getFirstUrlPath } from './../../../components/Player/utils/multiplePlayerApps';
import { useAuth, useIsSchoolAuth } from 'hooks/Auth';
import { useGetSongs } from 'SongCaching';

type PlaylistsProps = {
  currentPlaylist: Playlist | undefined;
  currentPlaylistSongs: Song[];
  currentPlaylistShuffledOrder: number[] | null;
  shuffleCurrentPlaylist: () => number[] | undefined;
  getPlaylistById: (id: string, overrideAccess: boolean) => Promise<Playlist | undefined>;
  createNewPlaylist: (playlistName: string, uid: string, player: string, songId?: string) => Promise<void>;
  handleInviteLink: (
    playlistId: string,
    inviteCode: string,
    uid: string,
  ) => Promise<{
    success: boolean;
    reason: string;
    playlist?: Playlist;
  }>;
  hasAccess: (playlistData: Playlist, userId: string) => [boolean, 'owner' | 'edit' | 'read' | 'none'];
  addSongToPlaylist: (playlistId: string, songId: string) => Promise<void>;
  removeSongFromPlaylist: (playlistId: string, songId: string) => Promise<void>;
  editPlaylistName: (playlistId: string, newName: string) => Promise<void>;
  editPublicStatus: (playlistId: string, publicStatus: boolean) => Promise<void>;
  getAllPlaylists: (owner?: boolean, edit?: boolean, read?: boolean, player?: undefined | string) => Promise<Playlist[] | undefined>;
  changeCurrentPlaylist: (playlistId: string) => Promise<void>;
  deletePlaylist: (playlistId: string, uid: string) => Promise<void>;
  removeUserFromPlaylist: (playlistId: string, uid: string) => Promise<void>;
  editToReadPermission: (playlistId: string, uid: string) => Promise<void>;
  reorderPlaylist: (playlistId: string, newOrder: number[]) => Promise<void>;
};
const PlaylistsContext = createContext<PlaylistsProps | undefined>(undefined);

const PlaylistsProvider = ({ children }: { children: ReactNode }) => {
  const [auth, authIsLoading] = useAuth();
  const [isSchoolAuth, schoolAuthIsLoading, schoolUserDoc, schoolClass] = useIsSchoolAuth();
  const [lastUid, setLastUid] = useState<string | null>(null);
  const [allPlaylists, setAllPlaylists] = useState<{ [player: string]: { [accessType: string]: Playlist[] } }>({});
  const [playlistMap, setPlaylistMap] = useState<{ [id: string]: Playlist }>({});
  const [currentPlaylist, setCurrentPlaylist] = useState<Playlist | undefined>(undefined);
  const [currentPlaylistSongs, setCurrentPlaylistSongs] = useState<Song[]>([]);
  const [currentPlaylistShuffledOrder, setCurrentPlaylistShuffledOrder] = useState<number[] | null>(null);

  const { getSongById } = useGetSongs();

  useEffect(() => {
    // Resetting data for new user
    if (!authIsLoading && !schoolAuthIsLoading) {
      if (!lastUid && auth?.uid) {
        setLastUid(auth.uid);
      } else if (auth && lastUid && auth?.uid !== lastUid && isSchoolAuth !== undefined && schoolUserDoc) {
        setAllPlaylists({});
        setPlaylistMap({});
        setCurrentPlaylist(undefined);
        setCurrentPlaylistSongs([]);
        setCurrentPlaylistShuffledOrder(null);
        setLastUid(auth.uid);
      }
    }
  }, [auth, authIsLoading, schoolAuthIsLoading, isSchoolAuth, schoolUserDoc]);

  const hasAccess = (playlistData: Playlist, userId: string) => {
    if (playlistData.owner === userId) {
      return [true, 'owner'] as [boolean, 'owner' | 'edit' | 'read' | 'none'];
    }
    if (!playlistData.public) {
      return [false, 'none'] as [boolean, 'owner' | 'edit' | 'read' | 'none'];
    }
    if (playlistData.editPermissionUsers.includes(userId)) {
      return [true, 'edit'] as [boolean, 'owner' | 'edit' | 'read' | 'none'];
    }
    if (playlistData.readPermissionUsers.includes(userId)) {
      return [true, 'read'] as [boolean, 'owner' | 'edit' | 'read' | 'none'];
    }

    if (typeof schoolClass === 'string' && playlistData.sharedToClasses) {
      if (playlistData.sharedToClasses.includes(schoolClass)) {
        return [true, 'read'] as [boolean, 'owner' | 'edit' | 'read' | 'none'];
      }
    }

    return [false, 'none'] as [boolean, 'owner' | 'edit' | 'read' | 'none'];
  };

  const getPlaylistById = async (id: string, overrideAccess = false) => {
    if (currentPlaylist?.id === id) {
      return currentPlaylist;
    }

    let playlist = playlistMap[id];
    if (playlist) {
      const [access, _] = hasAccess(playlist, auth?.uid || '');
      if (access || overrideAccess) {
        return playlist;
      }
    }
    const playlistDoc = await collection(COLLECTIONS.PLAYLISTS).doc(id).get();
    if (!playlistDoc.exists) {
      return undefined;
    }
    const playlistData = playlistDoc.data() as Playlist;
    const player = playlistData.player;
    if (!player) {
      return undefined;
    }

    if (overrideAccess) {
      const newPlaylist = { ...playlistData, id: id };
      return newPlaylist;
    }

    const [newAllPlaylists, newPlaylistMap] = await getAllPlaylistsInPlayer(player);

    if (!newAllPlaylists || !newPlaylistMap) {
      return undefined;
    }

    playlist = newPlaylistMap[id];

    if (playlist) {
      const [access, _] = hasAccess(playlist, auth?.uid || '');
      if (access) {
        return playlist;
      }
    }
    return undefined;
  };
  const unpackAndAddId = async (snapshot: firebase.firestore.QuerySnapshot<Playlist>) => {
    return await Promise.all(
      snapshot.docs.map(async (doc) => {
        const playlist = doc.data();
        playlist.id = doc.id;
        return playlist;
      }),
    );
  };
  const getAllPlaylistsInPlayerFromDb = async (uid: string, player: PlayerType = 'player') => {
    const playlistsInPLayer = collection<Playlist>(COLLECTIONS.PLAYLISTS).where('player', '==', player);
    const ownerPlaylistDocs = await playlistsInPLayer.where('owner', '==', uid).get();
    const publicPlaylistDocs = playlistsInPLayer.where('public', '==', true);
    const editPermissionPlaylistDocs = await publicPlaylistDocs.where('editPermissionUsers', 'array-contains', uid).get();
    const readPermissionPlaylistsDocs = await publicPlaylistDocs.where('readPermissionUsers', 'array-contains', uid).get();

    let readAccessTemp = await unpackAndAddId(readPermissionPlaylistsDocs);

    let classReadPermissionPlaylistsDocs;
    if (typeof schoolClass === 'string') {
      // TODO LOOK AT THIS
      classReadPermissionPlaylistsDocs = await publicPlaylistDocs.where('sharedToClasses', 'array-contains', schoolClass).get();
      if (classReadPermissionPlaylistsDocs !== undefined) {
        readAccessTemp = [...readAccessTemp, ...(await unpackAndAddId(classReadPermissionPlaylistsDocs))];
      }
    }

    const ownerPlaylists = await unpackAndAddId(ownerPlaylistDocs);
    const editPlaylists = await unpackAndAddId(editPermissionPlaylistDocs);

    // Filter out playlists from readAccessTemp that are also in ownerPlaylists
    const readPlaylists = readAccessTemp.filter((readPlaylist) => {
      return !ownerPlaylists.some((ownerPlaylist) => ownerPlaylist.id === readPlaylist.id);
    });

    const data = {
      owner: ownerPlaylists,
      edit: editPlaylists,
      read: readPlaylists,
    };
    return data;
  };

  // Main function to get and save all playlists in a player
  const getAllPlaylistsInPlayer = async (player: PlayerType = 'player') => {
    if (auth?.uid) {
      if (!allPlaylists[player]) {
        const data = await getAllPlaylistsInPlayerFromDb(auth.uid, player);
        setAllPlaylists((prev) => ({ ...prev, [player]: data }));
        const newPlaylistMap = { ...playlistMap };
        data.owner.forEach((playlist) => {
          newPlaylistMap[playlist?.id || ''] = playlist;
        });
        data.edit.forEach((playlist) => {
          newPlaylistMap[playlist?.id || ''] = playlist;
        });
        data.read.forEach((playlist) => {
          newPlaylistMap[playlist?.id || ''] = playlist;
        });
        setPlaylistMap(newPlaylistMap);
        return [data, newPlaylistMap] as [
          {
            owner: Playlist[];
            edit: Playlist[];
            read: Playlist[];
          },
          { [id: string]: Playlist },
        ];
      }
      return [allPlaylists[player], playlistMap] as [
        {
          owner: Playlist[];
          edit: Playlist[];
          read: Playlist[];
        },
        { [id: string]: Playlist },
      ];
    }
    return [undefined, undefined];
  };

  const getSongsInPlaylist = async (playlistId: string, playlist: Playlist, forceUpdate = false) => {
    if (playlistId === currentPlaylist?.id && !forceUpdate) {
      return currentPlaylistSongs;
    }
    let songs = await Promise.all(playlist.songs.map((song) => getSongById(song.id)));
    songs = songs.filter((song) => !(song === 'deleted' || song === 'does not exist'));
    return songs as Song[];
  };

  const changeCurrentPlaylist = async (playlistId: string, forcedPlaylistMap: null | { [id: string]: Playlist } = null) => {
    if (currentPlaylist?.id === playlistId && !forcedPlaylistMap) {
      return;
    }
    let playlist: undefined | Playlist = undefined;
    if (!forcedPlaylistMap) {
      playlist = await getPlaylistById(playlistId);
    } else {
      playlist = forcedPlaylistMap[playlistId];
    }
    if (!playlist) {
      return;
    }

    const songs = await getSongsInPlaylist(playlistId, playlist, forcedPlaylistMap !== null);
    setCurrentPlaylist({ ...playlist });
    setCurrentPlaylistSongs([...songs]);
    setCurrentPlaylistShuffledOrder(null);
  };

  const shuffleCurrentPlaylist = (shuffle = true) => {
    if (!shuffle) {
      setCurrentPlaylistShuffledOrder(null);
      return;
    }
    if (!currentPlaylist || !currentPlaylistSongs) {
      return;
    }

    let newOrder = currentPlaylistSongs.map((s, index) => [index, Math.random()]);
    newOrder = newOrder.sort((a, b) => a[1] - b[1]);
    const indexes = newOrder.map((s) => s[0]);
    setCurrentPlaylistShuffledOrder(indexes);
    return indexes;
  };

  const createNewPlaylist = async (playlistName: string, uid: string, player: string, songId?: string) => {
    const readInviteCode = 'r' + Math.random().toString(36).substring(7);
    const editInviteCode = 'e' + Math.random().toString(36).substring(7);

    const songs = [];
    if (songId) {
      songs.push(collection<Song>(COLLECTIONS.SONGS).doc(songId));
    }

    const docAfterAdd = await collection<Playlist>(COLLECTIONS.PLAYLISTS).add({
      name: playlistName,
      public: false,
      owner: uid,
      readPermissionUsers: [],
      editPermissionUsers: [],
      songs: songs,
      readInviteCode: readInviteCode,
      editInviteCode: editInviteCode,
      lastEditedDate: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      player: player,
    });
    const data = (await docAfterAdd.get()).data();
    if (!data) {
      return;
    }
    data.id = docAfterAdd.id;
    if (allPlaylists[player]) {
      const newPlaylists = allPlaylists;
      newPlaylists[player].owner.push(data as Playlist);
      setAllPlaylists({ ...newPlaylists });

      const newPlaylistMap = playlistMap;
      newPlaylistMap[docAfterAdd.id] = data as Playlist;
      setPlaylistMap({ ...newPlaylistMap });
    } else {
      await getAllPlaylistsInPlayer(player);
    }
  };

  const updateCachedPlaylist = (uid: string, playlistId: string, playlist: Playlist) => {
    const oldPlaylist = playlistMap[playlistId];
    const [access, relation] = hasAccess(playlist, uid);
    const [oldAccess, oldRelation] = hasAccess(oldPlaylist, uid);

    if (access) {
      const newPlaylistMap = { ...playlistMap };
      newPlaylistMap[playlistId] = playlist;
      setPlaylistMap(newPlaylistMap);

      const newAllPlaylists = allPlaylists;
      const index = newAllPlaylists[playlist.player][relation].findIndex((playlist) => playlist.id === playlistId);
      if (index !== -1) {
        if (oldRelation !== relation) {
          newAllPlaylists[playlist.player][oldRelation].splice(index, 1);
          newAllPlaylists[playlist.player][relation].push(playlist);
        } else {
          newAllPlaylists[playlist.player][relation][index] = playlist;
        }
        setAllPlaylists({ ...newAllPlaylists });
      } else {
        newAllPlaylists[playlist.player][relation].push(playlist);
        setAllPlaylists({ ...newAllPlaylists });
      }
      if (playlistId === currentPlaylist?.id) {
        changeCurrentPlaylist(playlistId, newPlaylistMap);
      }
    }
  };

  const removeCachedPlaylist = (uid: string, playlistId: string, playlist: Playlist) => {
    const [access, relation] = hasAccess(playlist, uid);
    if (access && relation === 'owner' && allPlaylists[playlist.player]) {
      const newPlaylistMap = { ...playlistMap };
      delete newPlaylistMap[playlistId];
      setPlaylistMap(newPlaylistMap);

      const newAllPlaylists = allPlaylists;
      const index = newAllPlaylists[playlist.player][relation].findIndex((playlist) => playlist.id === playlistId);
      if (index !== -1) {
        newAllPlaylists[playlist.player][relation].splice(index, 1);
        setAllPlaylists({ ...newAllPlaylists });
      }
      if (playlistId === currentPlaylist?.id) {
        setCurrentPlaylist(undefined);
        setCurrentPlaylistSongs([]);
      }
    }
  };

  const addSongToPlaylist = async (playlistId: string, songId: string) => {
    const playlist = await getPlaylistById(playlistId);
    if (!playlist) {
      return;
    }
    const [access, relation] = hasAccess(playlist, auth?.uid || '');
    if (!access || !(relation === 'owner' || relation === 'edit')) {
      return;
    }
    const newSongRef = collection<Song>(COLLECTIONS.SONGS).doc(songId);
    playlist.songs.push(newSongRef);
    collection<Playlist>(COLLECTIONS.PLAYLISTS)
      .doc(playlistId)
      .update({
        songs: firebase.firestore.FieldValue.arrayUnion(newSongRef),
        lastEditedDate: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      });

    const newSongData = (await getSongById(songId)) as Song;
    if (currentPlaylist?.id === playlistId) {
      setCurrentPlaylistSongs((prev) => [...prev, newSongData]);
    }
    updateCachedPlaylist(auth?.uid || '', playlistId, playlist);
  };

  const removeSongFromPlaylist = async (playlistId: string, songId: string) => {
    const playlist = await getPlaylistById(playlistId);
    if (!playlist) {
      return;
    }
    const [access, relation] = hasAccess(playlist, auth?.uid || '');
    if (!access || !(relation === 'owner' || relation === 'edit')) {
      return;
    }
    const removeSongRef = collection<Song>(COLLECTIONS.SONGS).doc(songId);
    const index = playlist.songs.findIndex((song) => song.id === songId);
    if (index === -1) {
      return;
    }
    playlist.songs.splice(index, 1);

    collection<Playlist>(COLLECTIONS.PLAYLISTS)
      .doc(playlistId)
      .update({
        songs: firebase.firestore.FieldValue.arrayRemove(removeSongRef),
        lastEditedDate: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      });

    const removeSongData = (await getSongById(songId)) as Song;
    if (currentPlaylist?.id === playlistId) {
      setCurrentPlaylistSongs((prev) => [...prev, removeSongData]);
    }
    updateCachedPlaylist(auth?.uid || '', playlistId, playlist);
  };
  const handleInviteLink = async (playlistId: string, inviteCode: string, uid: string) => {
    const playlist_ = await getPlaylistById(playlistId, true);

    const playlist = { ...playlist_ } as Playlist;
    if (!playlist) {
      return { success: false, reason: 'playlist' };
    }

    if (playlist.owner === uid) {
      return { success: false, reason: 'owner' };
    }
    if (!playlist.public) {
      return { success: false, reason: 'private' };
    }

    if ((playlist.readPermissionUsers.includes(uid) || playlist.editPermissionUsers.includes(uid)) && playlist.readInviteCode === inviteCode) {
      return { success: false, reason: 'access' };
    }

    if (playlist.editPermissionUsers.includes(uid) && playlist.editInviteCode === inviteCode) {
      return { success: false, reason: 'access' };
    }

    if (playlist.readInviteCode === inviteCode) {
      const newReadPermissionUsers = playlist.readPermissionUsers;
      newReadPermissionUsers.push(uid);
      collection<Playlist>(COLLECTIONS.PLAYLISTS)
        .doc(playlistId)
        .update({
          readPermissionUsers: firebase.firestore.FieldValue.arrayUnion(uid),
          lastEditedDate: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
        });
      try {
        updateCachedPlaylist(uid, playlistId, playlist);
      } catch (e) {
        // This is not an actual error
      }

      return { success: true, reason: 'read', playlist: playlist };
    } else if (playlist.editInviteCode === inviteCode) {
      playlist.editPermissionUsers.push(uid);
      let updateObj: {
        editPermissionUsers: firebase.firestore.FieldValue;
        readPermissionUsers?: firebase.firestore.FieldValue;
        lastEditedDate: firebase.firestore.Timestamp;
      } = {
        editPermissionUsers: firebase.firestore.FieldValue.arrayUnion(uid),
        lastEditedDate: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      };
      const readPermissionIndex = playlist.readPermissionUsers.indexOf(uid);
      if (readPermissionIndex !== -1) {
        playlist.readPermissionUsers.splice(readPermissionIndex, 1);
        updateObj = { ...updateObj, readPermissionUsers: firebase.firestore.FieldValue.arrayRemove(uid) };
      }

      collection<Playlist>(COLLECTIONS.PLAYLISTS).doc(playlistId).update(updateObj);
      try {
        updateCachedPlaylist(uid, playlistId, playlist);
      } catch (e) {
        // This is not an actual error
      }
      return { success: true, reason: 'edit', playlist: playlist };
    }

    return { success: false, reason: 'code' };
  };

  const editPlaylistName = async (playlistId: string, name: string) => {
    const playlist = await getPlaylistById(playlistId);
    if (!playlist) {
      return;
    }
    const [access, relation] = hasAccess(playlist, auth?.uid || '');
    if (!access || !(relation === 'owner' || relation === 'edit')) {
      return;
    }
    collection<Playlist>(COLLECTIONS.PLAYLISTS)
      .doc(playlistId)
      .update({
        name: name,
        lastEditedDate: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      });
    playlist.name = name;
    updateCachedPlaylist(auth?.uid || '', playlistId, playlist);
  };

  const editPublicStatus = async (playlistId: string, publicStatus: boolean) => {
    const playlist = await getPlaylistById(playlistId);
    if (!playlist) {
      return;
    }
    const [access, relation] = hasAccess(playlist, auth?.uid || '');
    if (!access || !(relation === 'owner' || relation === 'edit')) {
      return;
    }
    if (playlist.public === publicStatus) {
      return;
    }

    collection<Playlist>(COLLECTIONS.PLAYLISTS)
      .doc(playlistId)
      .update({
        public: publicStatus,
        lastEditedDate: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      });
    playlist.public = publicStatus;
    updateCachedPlaylist(auth?.uid || '', playlistId, playlist);
  };

  const deletePlaylist = async (playlistId: string) => {
    const playlist = await getPlaylistById(playlistId);
    if (!playlist) {
      return;
    }
    const [access, relation] = hasAccess(playlist, auth?.uid || '');
    if (!access || relation !== 'owner') {
      return;
    }
    collection<Playlist>(COLLECTIONS.PLAYLISTS).doc(playlistId).delete();
    removeCachedPlaylist(auth?.uid || '', playlistId, playlist);
  };

  const getAllPlaylists = async (owner = true, edit = true, read = true, player: undefined | string = undefined) => {
    if (player === undefined) {
      player = getFirstUrlPath();
    }
    const [relationLists, map] = await getAllPlaylistsInPlayer(player);
    if (relationLists !== undefined) {
      return [...(owner ? relationLists.owner : []), ...(edit ? relationLists.edit : []), ...(read ? relationLists.read : [])];
    }
    return undefined;
  };

  const removeUserFromPlaylist = async (playlistId: string, userId: string) => {
    const playlist = await getPlaylistById(playlistId);
    if (!playlist) {
      return;
    }
    const [access, permission] = hasAccess(playlist, auth?.uid || '');
    if (!access || permission === 'none' || (permission === 'read' && auth?.uid !== userId)) {
      return;
    }
    const [userAccess, userPermission] = hasAccess(playlist, userId);
    if (!userAccess || userPermission === 'owner') {
      return;
    }
    const updateObj: {
      editPermissionUsers?: firebase.firestore.FieldValue;
      readPermissionUsers?: firebase.firestore.FieldValue;
      lastEditedDate: firebase.firestore.Timestamp;
    } = { lastEditedDate: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp };

    if (userPermission === 'edit') {
      updateObj['editPermissionUsers'] = firebase.firestore.FieldValue.arrayRemove(userId);
    } else if (userPermission === 'read') {
      updateObj['readPermissionUsers'] = firebase.firestore.FieldValue.arrayRemove(userId);
    }

    collection<Playlist>(COLLECTIONS.PLAYLISTS).doc(playlistId).update(updateObj);
    if (userId === auth?.uid) {
      removeCachedPlaylist(auth?.uid || '', playlistId, playlist);
    }
  };
  const editToReadPermission = async (playlistId: string, userId: string) => {
    const playlist = await getPlaylistById(playlistId);
    if (!playlist) {
      return;
    }
    const [access, permission] = hasAccess(playlist, auth?.uid || '');
    if (!access || permission === 'none' || permission === 'read') {
      return;
    }
    const [userAccess, userPermission] = hasAccess(playlist, userId);
    if (!userAccess || userPermission !== 'edit') {
      return;
    }
    collection<Playlist>(COLLECTIONS.PLAYLISTS)
      .doc(playlistId)
      .update({
        editPermissionUsers: firebase.firestore.FieldValue.arrayRemove(userId),
        readPermissionUsers: firebase.firestore.FieldValue.arrayUnion(userId),
        lastEditedDate: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      });

    if (userId === auth?.uid) {
      playlist.readPermissionUsers.push(userId);
      playlist.editPermissionUsers = playlist.editPermissionUsers.filter((id) => id !== userId);
      updateCachedPlaylist(auth?.uid || '', playlistId, playlist);
    }
  };

  const reorderPlaylist = async (playlistId: string, newOrderIndexes: number[]) => {
    const playlist = await getPlaylistById(playlistId);
    if (!playlist) {
      return;
    }
    const [access, relation] = hasAccess(playlist, auth?.uid || '');
    if (!access || !(relation === 'owner' || relation === 'edit')) {
      return;
    }

    const currentSongs = playlist.songs;
    if (currentSongs.length !== newOrderIndexes.length) {
      return;
    }
    const newSongs = newOrderIndexes.map((index) => currentSongs[index]);
    collection<Playlist>(COLLECTIONS.PLAYLISTS)
      .doc(playlistId)
      .update({ songs: newSongs, lastEditedDate: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp });
    playlist.songs = newSongs;
    updateCachedPlaylist(auth?.uid || '', playlistId, playlist);
  };

  const value = {
    currentPlaylist,
    currentPlaylistSongs,
    currentPlaylistShuffledOrder,
    getPlaylistById,
    changeCurrentPlaylist,
    createNewPlaylist,
    handleInviteLink,
    hasAccess,
    removeSongFromPlaylist,
    addSongToPlaylist,
    editPlaylistName,
    editPublicStatus,
    getAllPlaylists,
    deletePlaylist,
    removeUserFromPlaylist,
    editToReadPermission,
    reorderPlaylist,
    shuffleCurrentPlaylist,
  };

  return <PlaylistsContext.Provider value={value}>{children}</PlaylistsContext.Provider>;
};

const useCurrentPlaylist = (playlistId: string) => {
  const context = useContext(PlaylistsContext);
  const [currentId, setCurrentId] = useState<string | null>(null);
  const [currentPlaylist, setCurrentPlaylist] = useState<Playlist | null>(null);
  const [currentPlaylistSongs, setCurrentPlaylistSongs] = useState<Song[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  if (context === undefined) {
    throw new Error('usePlaylist must be used within a PlaylistsProvider');
  }

  useEffect(() => {
    if (playlistId === undefined || playlistId === null || playlistId === '' || playlistId === 'history' || playlistId === 'private') {
      setLoading(false);
      setCurrentPlaylist(null);
      setCurrentPlaylistSongs(null);
      return;
    }
    if (currentId !== playlistId) {
      setCurrentId(playlistId);
      context.changeCurrentPlaylist(playlistId);
      setLoading(true);
    } else {
      setLoading(true);
    }
  }, [playlistId]);
  useEffect(() => {
    if (context.currentPlaylist && context.currentPlaylist?.id === currentId) {
      if (context.currentPlaylistSongs.length === context.currentPlaylist.songs.length) {
        setLoading(false);
        setCurrentPlaylist({ ...context.currentPlaylist } || null);
        setCurrentPlaylistSongs([...context.currentPlaylistSongs] || null);
      }
    }
  }, [context.currentPlaylist, context.currentPlaylistSongs, loading]);

  return {
    playlist: currentPlaylist,
    songs: currentPlaylistSongs,
    isLoading: loading,
    shuffleOrder: context.currentPlaylistShuffledOrder,
    shufflePlaylist: context.shuffleCurrentPlaylist,
  };
};

const usePlaylists = () => {
  const context = useContext(PlaylistsContext);
  if (context === undefined) {
    throw new Error('usePlaylists must be used within a PlaylistsProvider');
  }

  return { getAllPlaylists: context.getAllPlaylists, getPlaylistById: context.getPlaylistById };
};

const usePlaylistAccess = () => {
  const context = useContext(PlaylistsContext);
  if (context === undefined) {
    throw new Error('usePlaylistAccess must be used within a PlaylistsProvider');
  }
  return { hasAccess: context.hasAccess, handleInviteLink: context.handleInviteLink };
};

const useEditPlaylists = () => {
  const context = useContext(PlaylistsContext);
  if (context === undefined) {
    throw new Error('useEditPlaylists must be used within a PlaylistsProvider');
  }
  return {
    getPlaylistById: context.getPlaylistById,
    createNewPlaylist: context.createNewPlaylist,
    addSongToPlaylist: context.addSongToPlaylist,
    removeSongFromPlaylist: context.removeSongFromPlaylist,
    editPlaylistName: context.editPlaylistName,
    editPublicStatus: context.editPublicStatus,
    deletePlaylist: context.deletePlaylist,
    removeUserFromPlaylist: context.removeUserFromPlaylist,
    editToReadPermission: context.editToReadPermission,
    reorderPlaylist: context.reorderPlaylist,
  };
};
export { PlaylistsProvider, usePlaylists, usePlaylistAccess, useEditPlaylists, useCurrentPlaylist };
