import { ReactNode, useState, useEffect, useContext, createContext, ReactElement } from 'react';
import { useAuth, useIsSchoolAuth } from 'hooks/Auth';
import { useUserDataFields } from 'hooks/User';
import { SchoolMember, SubscriptionType } from 'types';
import Navigation from 'components/navigation/Navigation';
import { getSubscribePath } from 'components/Player/utils/multiplePlayerApps';

import { Route, Navigate, useLocation } from 'react-router-dom';
import URLS from 'URLS';
import { useSnackbar } from 'hooks/Snackbar';
import { useTranslation } from 'react-i18next';
import firebase, { collectionGroup, COLLECTIONS, db } from 'fb';

type SubscriptionContextProps = {
  subscription: SubscriptionType | null;
  subscriptions: { [id: string]: SubscriptionType } | null;
  downgradeDates: { [id: string]: { downgradeDate: Date; newSubscriptionType: string } | undefined } | null;
  isLoading: boolean;
  refetch: () => void;
};

const SubscriptionContext = createContext<SubscriptionContextProps>({
  subscriptions: null,
  subscription: null,
  downgradeDates: null,
  isLoading: false,
  refetch: () => {
    // To make prettier and eslint happy
  },
});

type SubscriptionProviderProps = {
  children: ReactNode;
};

export const stringToSubscriptionType = (subscriptionString: string | undefined) => {
  if (!subscriptionString) {
    return SubscriptionType.unset;
  }
  let keys = Object.keys(SubscriptionType);
  keys = keys.slice(keys.length / 2, keys.length);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i].toLowerCase();
    if (key === subscriptionString.toLowerCase()) {
      return i as SubscriptionType;
    }
  }
  return SubscriptionType.unset;
};

export const subscriptionTypeToString = (subType: SubscriptionType) => {
  return SubscriptionType[subType];
};

const SubscriptionProvider = ({ children }: SubscriptionProviderProps) => {
  const [auth, isAuthLoading] = useAuth();
  const [isSchoolAuth, isSchoolAuthLoading, schoolUserDoc] = useIsSchoolAuth();
  const {
    data: filteredUserData,
    isLoading: userHookIsLoading,
    refetch: userDataRefresh,
  } = useUserDataFields(true, ['subscriptionType', 'currentSubscriptionDowngrade']);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState<{ [key: string]: SubscriptionType } | null>(null);
  const [subscription, setSubscription] = useState<SubscriptionType | null>(null);
  const [downgradeDates, setDowngradeDates] = useState<{ [key: string]: { downgradeDate: Date; newSubscriptionType: string } | undefined } | null>(null);

  useEffect(() => {
    if (!userHookIsLoading && !isAuthLoading && !isSchoolAuthLoading) {
      // TODO - filteredUserDate is null but loading false
      if (isSchoolAuth && auth) {
        const checkIfSchoolMemberAndSetSubscription = async () => {
          // Check if user is teacher or student and then set subscription to match
          if (schoolUserDoc) {
            // Getting school member doc
            const schoolMemberDoc = schoolUserDoc as firebase.firestore.QueryDocumentSnapshot<SchoolMember>;

            // Getting school doc
            const school = await schoolMemberDoc.ref.parent.parent?.parent.parent;

            const schoolDoc = await school?.get();
            const schoolOrgNumber = schoolDoc?.data()?.orgNumber;
            const currentDoc = schoolMemberDoc.data() as SchoolMember;

            // Getting school license doc
            const schoolLicense = await db.collection(COLLECTIONS.SCHOOL_LICENSES).where('school.orgNumber', '==', schoolOrgNumber).get();

            if (schoolLicense?.docs[0]?.data()?.subscriptionActivated) {
              if (currentDoc.role === 'student' && currentDoc.studentBypass !== true) {
                // The user is a teacher in a school with pro
                setSubscription(SubscriptionType.student_pro);
                setSubscriptions({ [auth.uid]: SubscriptionType.student_pro });
              } else {
                setSubscription(SubscriptionType.teacher_pro);
                setSubscriptions({ [auth.uid]: SubscriptionType.teacher_pro });
              }
            } else if (currentDoc.role === 'student' && currentDoc.studentBypass !== true) {
              // The user is a teacher in a school with pro
              setSubscription(SubscriptionType.student_free);
              setSubscriptions({ [auth.uid]: SubscriptionType.student_free });
            } else {
              setSubscription(SubscriptionType.teacher_free);
              setSubscriptions({ [auth.uid]: SubscriptionType.teacher_free });
            }
          } else {
            setSubscription(SubscriptionType.free);
            setSubscriptions({ [auth.uid]: SubscriptionType.free });
          }
          setIsLoading(false);
        };
        checkIfSchoolMemberAndSetSubscription();
      } else if (auth && filteredUserData) {
        let hookSub: SubscriptionType | undefined | null = filteredUserData[auth.uid].subscriptionType;
        if (hookSub === undefined) {
          hookSub = null;
        }
        setSubscription(hookSub);

        const hookSubs = Object.entries(filteredUserData).map((entry) => [entry[0], entry[1].subscriptionType || SubscriptionType.unset]);

        setSubscriptions(Object.fromEntries(hookSubs));

        const hookDates = Object.entries(filteredUserData).map((entry) => [entry[0], entry[1].currentSubscriptionDowngrade || undefined]);

        setDowngradeDates(Object.fromEntries(hookDates));

        setIsLoading(false);
      } else {
        setIsLoading(false);
        setSubscriptions(null);
        setSubscription(null);
      }
    }

    if (userHookIsLoading || isAuthLoading || isSchoolAuthLoading) {
      setIsLoading(true);
    }
  }, [userHookIsLoading, isAuthLoading, isSchoolAuthLoading]);

  return (
    <SubscriptionContext.Provider
      value={{ subscription: subscription, subscriptions: subscriptions, downgradeDates: downgradeDates, isLoading: isLoading, refetch: userDataRefresh }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

const useSubscription = () => {
  const context = useContext(SubscriptionContext);

  if (context === undefined) {
    throw new Error('useSubscription must be used within a SubscriptionProvider');
  }
  return {
    subscription: context.subscription,
    subscriptions: context.subscriptions,
    downgradeDates: context.downgradeDates,
    isLoading: context.isLoading,
    refetch: context.refetch,
  };
};
const SubscriptionRoute = ({
  element,
  children,
  subscriptionRequired = SubscriptionType.free,
  path,
}: {
  path: string;
  element?: ReactElement;
  children?: ReactNode;
  subscriptionRequired: SubscriptionType;
}) => {
  const { t } = useTranslation(['player']);
  const location = useLocation();
  const [auth, authIsLoading] = useAuth();
  const { subscription, isLoading: subscriptionIsLoading } = useSubscription();
  const [isLoading, setIsLoading] = useState(true);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    if (!authIsLoading && !subscriptionIsLoading) {
      setIsLoading(false);
    }
  }, [authIsLoading, subscriptionIsLoading]);

  if (!authIsLoading && !auth) {
    return <Navigate state={{ referrer: location.pathname }} to={URLS.SIGN_IN} />;
  }

  if (isLoading) {
    return <Navigation isLoading noFooter />;
  } else if (subscription === null || subscription < subscriptionRequired) {
    if (subscriptionRequired === SubscriptionType.free) {
      setTimeout(() => showSnackbar(t('SUBSCRIPTION.NOACCESS.NOTSET'), { severity: 'info', length: 15000 }), 1000);
    } else {
      setTimeout(
        () =>
          showSnackbar(t('SUBSCRIPTION.NOACCESS.NEEDS' + SubscriptionType[subscriptionRequired].toString().toUpperCase()), {
            severity: 'info',
            length: 15000,
          }),
        1000,
      );
    }
    // #TODO
    return <Navigate state={{ referrer: location.pathname }} to={getSubscribePath()} />;
  }

  return (
    <Route element={element} path={path}>
      {children}
    </Route>
  );
};

export { SubscriptionProvider, useSubscription, SubscriptionRoute };
