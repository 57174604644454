import { useState } from 'react';
import { functions } from 'fb';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserState } from 'hooks/User';
import { useNotifications } from 'hooks/Notifications';
import { getTimeSince } from 'utils';
import getNotificationIcon from './NotificationIcon';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// Icons
import NotificationsIcon from '@material-ui/icons/NotificationsNoneRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import LoadIcon from '@material-ui/icons/CloudDownloadOutlined';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 26,
    width: 26,
    color: 'black',
    fill: theme.palette.black.main,
    [theme.breakpoints.down('md')]: {
      fill: (props: { light?: boolean }) => (props.light ? 'white' : theme.palette.text.primary),
    },
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.black.main,
  },
  readAllButton: {
    position: 'absolute',
    right: theme.spacing(7),
    top: theme.spacing(1),
    color: theme.palette.black.main,
  },
  primary: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  header: {
    color: theme.palette.black.main,
  },
  notificationBadge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      content: '""',
    },
  },
  hide: {
    display: 'none',
  },
  content: {
    padding: 0,
  },
  listItemUnread: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const Notifications = ({ light }: { light?: boolean }) => {
  const { t } = useTranslation(['common']);
  const classes = useStyles({ light: light });
  const [open, setOpen] = useState(false);
  const user = useUserState();
  const { notifications, moreToLoad, unread, loadMoreNotifications, markAsRead, newNotifications } = useNotifications();

  const read = (id: string) => {
    markAsRead(id);
    setOpen(false);
  };

  const openNotificationDialog = () => {
    setOpen(true);
    functions.httpsCallable('users-NoNewNotifications')();
  };

  const readAll = () => {
    notifications.forEach((notification) => {
      const data = notification.data();
      if (data && !data.read) {
        markAsRead(notification.id);
      }
    });
  };

  if (!user) {
    return null;
  } else {
    return (
      <>
        <IconButton onClick={openNotificationDialog}>
          <Badge badgeContent={newNotifications} color='primary'>
            <NotificationsIcon className={classes.icon} />
          </Badge>
        </IconButton>
        <Dialog aria-labelledby='scroll-dialog-title' fullWidth maxWidth='md' onClose={() => setOpen(false)} open={open}>
          <DialogTitle className={classes.root} disableTypography id='scroll-dialog-title'>
            <Typography className={classes.header} variant='h6'>
              {t('NOTIFICATIONS.HEADER')}
            </Typography>
            {notifications.find((n) => !n.data()?.read) && (
              <IconButton aria-label='close' className={classes.readAllButton} onClick={() => readAll()}>
                <DoneAllIcon></DoneAllIcon>
              </IconButton>
            )}
            <IconButton aria-label='close' className={classes.closeButton} onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.content} dividers>
            <List>
              {notifications.map((notification, index) => {
                const data = notification.data();
                if (!data) {
                  return null;
                }
                return (
                  <ListItem
                    button
                    className={data.read ? '' : classes.listItemUnread}
                    component={Link}
                    divider={notifications.length - 1 !== index}
                    key={index}
                    onClick={() => read(notification.id)}
                    to={data.click_action}>
                    <ListItemAvatar>
                      <Badge
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        classes={{ badge: classnames(classes.notificationBadge, data.read && classes.hide) }}
                        overlap='circle'
                        variant='dot'>
                        <Avatar className={classes.primary}>{getNotificationIcon(data.template)}</Avatar>
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText primary={data.read ? data.title : <b>{data.title}</b>} secondary={data.body} />
                    <ListItemIcon>{data.read ? getTimeSince(data.time.toDate()) : <b>{getTimeSince(data.time.toDate())}</b>}</ListItemIcon>
                  </ListItem>
                );
              })}
            </List>
          </DialogContent>
          {moreToLoad && (
            <DialogActions>
              <Button color='secondary' endIcon={<LoadIcon />} fullWidth onClick={loadMoreNotifications} variant='outlined'>
                {t('NOTIFICATIONS.LOADMORE')}
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </>
    );
  }
};

export default Notifications;
