import { ReactNode, useState, useContext, useCallback, useMemo, createContext } from 'react';
import Snackbar from 'components/layout/Snackbar';
import firebase, { messaging } from 'fb';

export type Severity = 'error' | 'warning' | 'info' | 'success';
export type SnackbarOptions = { severity: Severity; length?: number; body?: string; image?: string; click_action?: string; closeOnClick?: boolean };
type SnackbarProps = {
  showSnackbar: (title: string, { severity, length }: SnackbarOptions) => void;
};
const SnackbarContext = createContext<SnackbarProps | undefined>(undefined);

const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackTitle, setSnackTitle] = useState('');
  const [snackOptions, setSnackOptions] = useState<SnackbarOptions>({ severity: 'info' });

  const showSnackbar = useCallback((title: string, options: SnackbarOptions) => {
    setSnackTitle(title);
    setSnackOptions(options);
    setSnackbarOpen(true);
  }, []);

  const value = useMemo(() => ({ showSnackbar }), [showSnackbar]);

  if (firebase.messaging.isSupported() && messaging) {
    messaging.onMessage((payload) => showSnackbar(payload.notification.title, { ...payload.notification, severity: 'info' }));
  }

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snackbar onClose={() => setSnackbarOpen(false)} open={snackbarOpen} options={snackOptions} title={snackTitle} />
    </SnackbarContext.Provider>
  );
};

const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

export { SnackbarProvider, useSnackbar };
