import { useState, useEffect, ReactNode } from 'react';
import { collection, COLLECTIONS } from 'fb';
import { useUserState } from 'hooks/User';
import { useTranslation } from 'react-i18next';
import { User } from 'types';

// mui
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

export type AskForEmailPermissionPopupProps = {
  children: ReactNode;
};

const AskForEmailPermissionPopup = ({ children }: AskForEmailPermissionPopupProps) => {
  const user = useUserState();
  const { t } = useTranslation(['common']);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (user) {
      if (!user.data()!.mailSubscriptions) {
        setOpenDialog(true);
      }
    }
  }, [user]);

  const acceptEmails = () => {
    setOpenDialog(false);
    collection<User>(COLLECTIONS.USERS)
      .doc(user!.id)
      .update({
        mailSubscriptions: {
          newSongs: true,
          eventStart: true,
        },
      });
  };

  const doNotAccept = () => {
    setOpenDialog(false);
    collection<User>(COLLECTIONS.USERS)
      .doc(user!.id)
      .update({
        mailSubscriptions: {
          newSongs: false,
          eventStart: false,
        },
      });
  };
  return (
    <>
      {children}
      <Dialog open={openDialog}>
        <DialogTitle id='simple-dialog-title'>{t('AUTH.EMAILPOPUPHEADER')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{t('AUTH.YESTOEMAILSUBSCRIPTION')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={doNotAccept}>
            {t('COMMON.DECLINE')}
          </Button>
          <Button autoFocus color='primary' onClick={acceptEmails}>
            {t('COMMON.ACCEPT')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AskForEmailPermissionPopup;
