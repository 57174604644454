import { ReactNode, createContext, useState, useContext, useEffect } from 'react';
import { Song } from 'types';

import { COLLECTIONS, collection } from 'fb';
type PlayerDemoSongsProps = { getSong: (app: string) => Promise<Song | undefined> };

const PlayerDemoSongsContext = createContext<PlayerDemoSongsProps | null>(null);

export const PlayerDemoSongsProvider = ({ children }: { children: ReactNode }) => {
  const [songs, setSongs] = useState<{ [key: string]: Song }>({});
  const getSongDB = async (app: string) => {
    try {
      const songDocs = await collection<Song>(COLLECTIONS.SONGS).where('demo', '==', app).get();
      if (songDocs.empty) {
        return undefined;
      }
      const songData = songDocs.docs[0].data();
      if (!songData) {
        return undefined;
      }
      // TODO: Do some conversion of data to make it fit Song?
      return songData;
    } catch (e: unknown) {
      return undefined;
    }
  };

  const getSong = async (app: string) => {
    if (app in songs) {
      return songs[app];
    } else {
      const song = await getSongDB(app);
      if (song) {
        setSongs({ ...songs, app: song });
        return song;
      }
    }
  };

  return <PlayerDemoSongsContext.Provider value={{ getSong }}>{children}</PlayerDemoSongsContext.Provider>;
};
export const usePlayerDemoSongs = (app?: string) => {
  const context = useContext(PlayerDemoSongsContext);

  const [currentApp, setCurrentApp] = useState<string | null>(null);
  const [song, setSong] = useState<Song | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (context === null || !app) {
      setSong(null);
      setIsLoading(false);
    } else if (context !== null && app && currentApp !== app) {
      setIsLoading(true);
      setCurrentApp(app);
      context.getSong(app).then((newSong) => {
        if (newSong) {
          setSong(newSong);
        } else {
          setSong(null);
        }
        setIsLoading(false);
      });
    }
  }, [app, context]);

  return [song, isLoading] as [Song | null, boolean];
};
