import { ReactNode, useState, useEffect, useContext, createContext } from 'react';
import { useAuth } from 'hooks/Auth';
import firebase, { COLLECTIONS, collection } from 'fb';
import { User, UserField, FilteredUser, TypeOfField, SubscriptionType } from 'types';
import { stringToSubscriptionType } from 'components/Player/hooks/useSubscription';

type UserStateContext = {
  userDoc: firebase.firestore.DocumentSnapshot<User> | null;
  userData: User | null;
  childrenData: User[] | null;
  isLoading: boolean;
  refetch: () => void;
};

const UserStateContext = createContext<UserStateContext>({
  userDoc: null,
  userData: null,
  childrenData: null,
  isLoading: false,
  refetch: () => {},
});

type UserProviderProps = {
  children: ReactNode;
};

const UserProvider = ({ children }: UserProviderProps) => {
  const [authed, authIsLoading] = useAuth();
  const [userDoc, setUserDoc] = useState<firebase.firestore.DocumentSnapshot<User> | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState<null | User>(null);
  const [childrenData, setChildrenData] = useState<null | User[]>(null);
  const [withChildren, setWithChildren] = useState(false);

  useEffect(() => {
    if (authed && !authIsLoading) {
      setIsLoading(true);
      const unsub = collection<User>(COLLECTIONS.USERS)
        .doc(authed.uid)
        .onSnapshot((user) => {
          if (user.exists) {
            setUserDoc(user);
          }
        });
      return () => unsub();
    } else {
      setUserDoc(null);
    }
    if (authIsLoading) {
      setIsLoading(true);
    } else if (!authed) {
      setIsLoading(false);
    }
  }, [authed, authIsLoading]);

  useEffect(() => {
    if (userDoc !== null) {
      updateDataIfNecessary();
    }
  }, [userDoc, withChildren]);

  const getChildData = async (childIds: string[]) => {
    const children = await Promise.all(
      childIds.map(async (childId) => {
        const childDoc = await collection<User>(COLLECTIONS.USERS).doc(childId).get();
        return childDoc.data();
      }),
    );
    const filteredChildren: User[] = [];
    children.forEach((child) => {
      if (child) {
        filteredChildren.push(child);
      }
    });
    return filteredChildren;
  };

  const updateDataIfNecessary = async () => {
    if (userDoc !== null) {
      let user = userData;
      if (userData === null) {
        setIsLoading(true);
        user = userDoc.data() || null;
        setUserData(user);
      }
      if (childrenData === null) {
        if (user?.children && user?.children.length) {
          setIsLoading(true);
          const children = await getChildData(user.children);
          setChildrenData(children);
        } else {
          setChildrenData([]);
        }
      }
      setIsLoading(false);
    }
  };

  const refetch = async () => {
    if (authed && !authIsLoading && userDoc) {
      setIsLoading(true);
      const user = userDoc.data() || null;
      setUserData(user);
      if (user && user?.children && user?.children.length) {
        const children = await getChildData(user.children);
        setChildrenData(children);
      } else {
        setChildrenData([]);
      }
      setIsLoading(false);
    }
  };

  return (
    <UserStateContext.Provider value={{ userDoc, userData: userData, childrenData: childrenData, isLoading: isLoading, refetch }}>
      {children}
    </UserStateContext.Provider>
  );
};
const useUserState = () => {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context.userDoc;
};

const useUserWithLoading = () => {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserLoading must be used within a UserProvider');
  }
  return { user: context.userDoc, isLoading: context.isLoading };
};

const useUserDataFields = (children = false, fields?: UserField[]) => {
  const context = useContext(UserStateContext);
  const [filteredData, setFilteredData] = useState<{ [key: string]: FilteredUser } | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const filterUserByFields = async (user: User, fields: UserField[]) => {
    let filteredUser: FilteredUser = {};
    const filteredUserEntries = await Promise.all(
      fields.map(async (field) => {
        if (Object.keys(user).includes(field as string)) {
          return { key: field as keyof typeof filteredUser, value: user[field as keyof typeof user] };
        } else if (field === 'subscriptionType') {
          const subString = user?.subscription?.subscriptionType;
          if (subString) {
            const subType: SubscriptionType = stringToSubscriptionType(subString);
            return { key: 'subscriptionType', value: subType };
          } else {
            return undefined;
          }
        } else if (field === 'currentSubscriptionDowngrade') {
          const subDocRef = user?.subscription?.currentSubscriptionRef;
          if (subDocRef !== undefined) {
            const subDoc = await subDocRef.get();
            const downgradeDate = subDoc?.data()?.downgradeDate?.toDate() || subDoc?.data()?.cancelDate?.toDate();

            let newSubscriptionType = subDoc?.data()?.newSubscriptionType;

            if (subDoc?.data()?.cancelDate?.toDate()) {
              newSubscriptionType = 'free';
            }

            if (downgradeDate && newSubscriptionType) {
              return { key: 'currentSubscriptionDowngrade', value: { downgradeDate, newSubscriptionType } };
            }
          } else {
            return undefined;
          }
        } else {
          return undefined;
        }
      }),
    );

    filteredUserEntries.forEach((entry) => {
      if (entry) {
        filteredUser = { ...filteredUser, ...{ [entry.key]: entry.value } };
      }
    });

    return filteredUser as FilteredUser;
  };

  const getFilteredUserData = async () => {
    if (context.userData && !context.isLoading) {
      if (fields) {
        const awaitFilterUser = await filterUserByFields(context.userData, fields);
        let filteredUsers: { [key: string]: FilteredUser } = { [context.userData.uid]: awaitFilterUser };

        if (children && context.childrenData) {
          const childData = Object.fromEntries(
            await Promise.all(context.childrenData.map(async (child) => child && [child.uid, await filterUserByFields(child, fields)])),
          );
          filteredUsers = { ...filteredUsers, ...childData };
        }

        setFilteredData(filteredUsers);
        setIsLoading(false);
      }
    }
    if (!context.isLoading && context.userData === null) {
      setIsLoading(false);
      setFilteredData(null);
    }
    if (context.isLoading) {
      setIsLoading(true);
    }
  };

  useEffect(() => {
    getFilteredUserData().catch((err) => {});
  }, [fields?.length, children, context.userData, context.childrenData, context.isLoading]);

  return { refetch: context.refetch, data: filteredData, isLoading: isLoading };
};

export { UserProvider, useUserState, useUserDataFields, useUserWithLoading };
