import { Link, NavLink } from 'react-router-dom';
import URLS from 'URLS';
import { useTranslation } from 'react-i18next';

// Material UI Components
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

// Icons
import { ReactComponent as Facebook } from 'assets/icons/social_media/facebook.svg';
import { ReactComponent as Instagram } from 'assets/icons/social_media/instagram.svg';
import { ReactComponent as LinkedIn } from 'assets/icons/social_media/linkedin.svg';
import { ReactComponent as YouTube } from 'assets/icons/social_media/youtube.svg';

// Project components
import LangSelect from 'components/miscellaneous/LangSelect';
import { lightThemePlayer } from 'components/Player/utils/multiplePlayerApps';
import store from 'components/Player/utils/localstorage';

const useStyles = makeStyles((theme) => ({
  content: {
    overflowX: 'scroll',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(5),
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateColumns: '1fr 1fr 1fr',
    margin: 'auto',

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4),
      gridGap: theme.spacing(1),
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  link: {
    margin: 'auto',
  },
  logo: {
    height: 60,
    marginBottom: 10,
    display: theme.palette.logo.main,
  },
  logoDark: {
    height: 60,
    marginBottom: 10,
    display: theme.palette.logo.light,
  },
  button: {
    width: 'fit-content',
    minWidth: 150,
    margin: 'auto',
    marginTop: theme.spacing(1),
    color: (props: { light?: boolean }) => (props.light ? 'white' : theme.palette.text.primary),
    borderColor: (props: { light?: boolean }) => (props.light ? 'white' : theme.palette.text.primary),
    /*'&:hover': {
      borderColor: theme.palette.black.light,
    },*/
  },
  socialMediaContainer: {
    width: '400px',
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      width: '300px',
      margin: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      width: '250px',
    },
  },
  socialMediaButton: {
    width: '70px',
    height: '70px',
    [theme.breakpoints.down('md')]: {
      width: '50px',
      height: '50px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '45px',
      height: '45px',
    },
  },
  socialMediaIcon: {
    width: '50px',
    height: '50px',
    [theme.breakpoints.down('md')]: {
      width: '40px',
      height: '40px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '35px',
      height: '35px',
    },
    fill: (props: { light?: boolean }) => (props.light ? 'white' : theme.palette.text.primary),
  },
  privacyAndLangWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  navLink: {
    color: (props: { light?: boolean }) => (props.light ? 'white' : theme.palette.text.primary),
    '&:hover': { textDecoration: 'underline' },
  },
  copyrightWrapper: {
    color: (props: { light?: boolean }) => (props.light ? 'white' : theme.palette.text.primary),
    fontSize: '20px',
    textAlign: 'center',
    lineHeight: '50px',
    height: '50px',
  },
}));

function Footer() {
  const classes = useStyles({ light: lightThemePlayer() });
  const { t } = useTranslation(['common']);
  // EC-PLAY logo
  /*
    <Link className={classes.link} to={URLS.LANDING}>
          <img alt='EC-Play Logo' className={classes.logo} src={ECPlayLogo} />
          <img alt='EC-Play Logo' className={classes.logoDark} src={ECPlayLogoDark} />
        </Link>
  */
  return (
    <>
      <Divider variant='middle' />
      <div className={classes.content}>
        <div className={classes.socialMediaContainer}>
          <IconButton
            className={classes.socialMediaButton}
            onClick={() => {
              window.open('https://www.facebook.com/ecplaymusic');
            }}>
            <Facebook className={classes.socialMediaIcon} />
          </IconButton>
          <IconButton
            className={classes.socialMediaButton}
            onClick={() => {
              window.open('https://www.youtube.com/channel/UCNdX-a63crg33SVzqXqnn6g');
            }}>
            <YouTube className={classes.socialMediaIcon} />
          </IconButton>
          <IconButton
            className={classes.socialMediaButton}
            onClick={() => {
              window.open('https://www.instagram.com/ecplaymusic/');
            }}>
            <Instagram className={classes.socialMediaIcon} />
          </IconButton>
          <IconButton
            className={classes.socialMediaButton}
            onClick={() => {
              window.open('https://www.linkedin.com/company/ec-play/');
            }}>
            <LinkedIn className={classes.socialMediaIcon} />
          </IconButton>
        </div>
        <div className={classes.copyrightWrapper}>{'© EC-Play ' + new Date().getFullYear()}</div>
        <div className={classes.privacyAndLangWrapper}>
          <LangSelect className={classes.button} />
          <NavLink className={classes.navLink} to={URLS.PRIVACY}>
            {t('PRIVACY.PRIVACY')}
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default Footer;
