import { Page } from '..';
import URLS from 'URLS';

export const pages: Array<Page> = [
  {
    displayName: 'LANDING.TEMPLATE.HOME',
    name: 'home',
    videoUrl:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FEC%20Play%20Landing.mp4?alt=media&token=9ab4b1f9-1063-4bec-9c10-b4d74ac811c4',
    salesPitchHeader: 'LANDING.TEMPLATE.HOME.SALESHEADER',
    salesPitchText: 'LANDING.TEMPLATE.HOME.SALESTEXT',
    salesPitchButton: URLS.SCHOOLONBOARDING + 'start',
    salesPitchButtonText: 'LANDING.TEMPLATE.HOME.BUTTONTEXT',
    demoImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FplayerDemos%2FBand%20Demo.gif?alt=media&token=74ae2110-b131-4eb1-a5f0-0b02ea4417a8',
    demoUrl: 'band',
    quotes: [{ text: 'LANDING.TEMPLATE.QUOTES.ONE' }, { text: 'LANDING.TEMPLATE.QUOTES.TWO' }, { text: 'LANDING.TEMPLATE.QUOTES.THREE' }],
    posterHeader: 'LANDING.TEMPLATE.HOME.POSTERHEADER',
    posterText: 'LANDING.TEMPLATE.HOME.POSTERTEXT',
    posterImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fimages%2FBand.png?alt=media&token=585ca486-6126-46fd-897c-622dd91ffbcb',
  },
  {
    displayName: 'LANDING.TEMPLATE.RESOURCES',
    name: 'resources',
    videoUrl:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FRessursbank%20Landing.mp4?alt=media&token=bb869c02-b398-4a68-95d5-7fd58eb3889d',
    salesPitchHeader: 'LANDING.TEMPLATE.RESOURCES.SALESHEADER',
    salesPitchText: 'LANDING.TEMPLATE.RESOURCES.SALESTEXT',
    salesPitchButton: URLS.RESOURCES,
    quotes: [{ text: 'LANDING.TEMPLATE.QUOTES.ONE' }, { text: 'LANDING.TEMPLATE.QUOTES.TWO' }, { text: 'LANDING.TEMPLATE.QUOTES.THREE' }],
    posterHeader: 'LANDING.TEMPLATE.RESOURCES.POSTERHEADER',
    posterText: 'LANDING.TEMPLATE.RESOURCES.POSTERTEXT',
    posterImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fimages%2FRessurser.png?alt=media&token=85d88de9-ddd2-4f71-a8fe-bd684aae5570',
  },
  {
    displayName: 'LANDING.TEMPLATE.BAND',
    name: 'band',
    videoUrl:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FForside%20Promo.mp4?alt=media&token=d92e5f54-29b5-47cf-a45d-620a0a7afb70',
    salesPitchHeader: 'LANDING.TEMPLATE.BAND.SALESHEADER',
    salesPitchText: 'LANDING.TEMPLATE.BAND.SALESTEXT',
    salesPitchButton: URLS.PLAYER,
    demoImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FplayerDemos%2FBand%20Demo.gif?alt=media&token=74ae2110-b131-4eb1-a5f0-0b02ea4417a8',
    quotes: [{ text: 'LANDING.TEMPLATE.QUOTES.ONE' }, { text: 'LANDING.TEMPLATE.QUOTES.TWO' }, { text: 'LANDING.TEMPLATE.QUOTES.THREE' }],
    posterHeader: 'LANDING.TEMPLATE.BAND.POSTERHEADER',
    posterText: 'LANDING.TEMPLATE.BAND.POSTERTEXT',
    posterImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fimages%2FBand.png?alt=media&token=585ca486-6126-46fd-897c-622dd91ffbcb',
  },
  {
    displayName: 'LANDING.TEMPLATE.UKULELE',
    name: 'ukulele',
    videoUrl:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FUkulele%20app%20Tutorial.mp4?alt=media&token=a68bd37e-f816-4d8e-bdd8-16206c9d3ff9',
    salesPitchHeader: 'LANDING.TEMPLATE.UKULELE.SALESHEADER',
    salesPitchText: 'LANDING.TEMPLATE.UKULELE.SALESTEXT',
    salesPitchButton: URLS.UKULELEPLAYER,
    demoImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FplayerDemos%2FUkulele%20Demo.gif?alt=media&token=d1d82b4a-ba82-4839-a7dd-c7fa3700aaab',
    quotes: [{ text: 'LANDING.TEMPLATE.QUOTES.ONE' }, { text: 'LANDING.TEMPLATE.QUOTES.TWO' }, { text: 'LANDING.TEMPLATE.QUOTES.THREE' }],
    posterHeader: 'LANDING.TEMPLATE.UKULELE.POSTERHEADER',
    posterText: 'LANDING.TEMPLATE.UKULELE.POSTERTEXT',
    posterImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fimages%2FUkulele.jpg?alt=media&token=914a50e3-b731-4da7-adc5-a47a8bbce75b',
  },
  {
    displayName: 'LANDING.TEMPLATE.BOOMWHACKERS',
    name: 'boomwhackers',
    videoUrl:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FForside%20Promo.mp4?alt=media&token=d92e5f54-29b5-47cf-a45d-620a0a7afb70',
    salesPitchHeader: 'LANDING.TEMPLATE.BOOMWHACKERS.SALESHEADER',
    salesPitchText: 'LANDING.TEMPLATE.BOOMWHACKERS.SALESTEXT',
    salesPitchButton: URLS.BOOMWHACKERSPLAYER,
    demoImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FplayerDemos%2FBoomWhackers%20Demo.gif?alt=media&token=a9c3c6eb-9e4a-49fd-9757-06a73b4f940a',
    quotes: [{ text: 'LANDING.TEMPLATE.QUOTES.ONE' }, { text: 'LANDING.TEMPLATE.QUOTES.TWO' }, { text: 'LANDING.TEMPLATE.QUOTES.THREE' }],
    posterHeader: 'LANDING.TEMPLATE.BOOMWHACKERS.POSTERHEADER',
    posterText: 'LANDING.TEMPLATE.BOOMWHACKERS.POSTERTEXT',
    posterImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fimages%2FBoomwhackers.jpg?alt=media&token=ad01507a-de1e-44c4-be58-8bd6db531f96',
  },
  {
    displayName: 'LANDING.TEMPLATE.GUITAR',
    name: 'guitar',
    videoUrl:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FGitarapp%20Tutorial.mp4?alt=media&token=398e4871-a799-4a48-970d-99e99e04c31b',
    salesPitchHeader: 'LANDING.TEMPLATE.GUITAR.SALESHEADER',
    salesPitchText: 'LANDING.TEMPLATE.GUITAR.SALESTEXT',
    salesPitchButton: URLS.GUITARPLAYER,
    demoImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FplayerDemos%2FGuitar%20Demo.gif?alt=media&token=e8019594-73d0-460e-b8c5-96de8e106a78',
    quotes: [{ text: 'LANDING.TEMPLATE.QUOTES.ONE' }, { text: 'LANDING.TEMPLATE.QUOTES.TWO' }, { text: 'LANDING.TEMPLATE.QUOTES.THREE' }],
    posterHeader: 'LANDING.TEMPLATE.GUITAR.POSTERHEADER',
    posterText: 'LANDING.TEMPLATE.GUITAR.POSTERTEXT',
    posterImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fimages%2FGitar.jpg?alt=media&token=481141cb-764d-400e-a665-e5e86906ceda',
  },
  {
    displayName: 'LANDING.TEMPLATE.PIANO',
    name: 'piano',
    videoUrl:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FForside%20Promo.mp4?alt=media&token=d92e5f54-29b5-47cf-a45d-620a0a7afb70',
    salesPitchHeader: 'LANDING.TEMPLATE.PIANO.SALESHEADER',
    salesPitchText: 'LANDING.TEMPLATE.PIANO.SALESTEXT',
    salesPitchButton: URLS.WHITEPIANOPLAYER,
    demoImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FplayerDemos%2FPiano%20Demo.gif?alt=media&token=ada40b07-1e00-4386-9abc-86949ea94840',
    demoUrl: 'whitepiano',
    quotes: [{ text: 'LANDING.TEMPLATE.QUOTES.ONE' }, { text: 'LANDING.TEMPLATE.QUOTES.TWO' }, { text: 'LANDING.TEMPLATE.QUOTES.THREE' }],
    posterHeader: 'LANDING.TEMPLATE.PIANO.POSTERHEADER',
    posterText: 'LANDING.TEMPLATE.PIANO.POSTERTEXT',
    posterImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fimages%2FPiano.png?alt=media&token=10575fdf-7dc7-4959-9be8-372084515bde',
  },
  {
    displayName: 'LANDING.TEMPLATE.BASS',
    name: 'basstabs',
    videoUrl:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FForside%20Promo.mp4?alt=media&token=d92e5f54-29b5-47cf-a45d-620a0a7afb70',
    salesPitchHeader: 'LANDING.TEMPLATE.BASS.SALESHEADER',
    salesPitchText: 'LANDING.TEMPLATE.BASS.SALESTEXT',
    salesPitchButton: URLS.BASSTABSPLAYER,
    demoImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FplayerDemos%2FBassTabs%20Demo.gif?alt=media&token=b5687dc6-f5d2-4b2d-b51b-77bc869a38ce',
    quotes: [{ text: 'LANDING.TEMPLATE.QUOTES.ONE' }, { text: 'LANDING.TEMPLATE.QUOTES.TWO' }, { text: 'LANDING.TEMPLATE.QUOTES.THREE' }],
    posterHeader: 'LANDING.TEMPLATE.BASS.POSTERHEADER',
    posterText: 'LANDING.TEMPLATE.BASS.POSTERTEXT',
    posterImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fimages%2FBass%20Tabs.jpg?alt=media&token=29b8ccd1-3f28-4cdd-a9f1-1816125b8fdc',
  },
  {
    displayName: 'LANDING.TEMPLATE.CHOIR',
    name: 'choir',
    videoUrl:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FForside%20Promo.mp4?alt=media&token=d92e5f54-29b5-47cf-a45d-620a0a7afb70',
    salesPitchHeader: 'LANDING.TEMPLATE.CHOIR.SALESHEADER',
    salesPitchText: 'LANDING.TEMPLATE.CHOIR.SALESTEXT',
    salesPitchButton: URLS.CHOIRPLAYER,
    demoImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FplayerDemos%2FChoir%20Demo.gif?alt=media&token=d0c0ba0f-058a-43b8-b5f3-7da9ff7982d6',
    quotes: [{ text: 'LANDING.TEMPLATE.QUOTES.ONE' }, { text: 'LANDING.TEMPLATE.QUOTES.TWO' }, { text: 'LANDING.TEMPLATE.QUOTES.THREE' }],
    posterHeader: 'LANDING.TEMPLATE.CHOIR.POSTERHEADER',
    posterText: 'LANDING.TEMPLATE.CHOIR.POSTERTEXT',
    posterImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fimages%2FKor.png?alt=media&token=046eca76-b145-4592-b58f-48eb5a68e2f4',
  },
  {
    displayName: 'LANDING.TEMPLATE.ACADEMY',
    name: 'academy',
    videoUrl:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FForside%20Promo.mp4?alt=media&token=d92e5f54-29b5-47cf-a45d-620a0a7afb70',
    salesPitchHeader: 'LANDING.TEMPLATE.ACADEMY.SALESHEADER',
    salesPitchText: 'LANDING.TEMPLATE.ACADEMY.SALESTEXT',
    salesPitchButton: URLS.ACADEMY,
    quotes: [{ text: 'LANDING.TEMPLATE.QUOTES.ONE' }, { text: 'LANDING.TEMPLATE.QUOTES.TWO' }, { text: 'LANDING.TEMPLATE.QUOTES.THREE' }],
    posterHeader: 'LANDING.TEMPLATE.ACADEMY.POSTERHEADER',
    posterText: 'LANDING.TEMPLATE.ACADEMY.POSTERTEXT',
    posterImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fimages%2FAcademy.png?alt=media&token=3f95187d-453b-4573-bfbf-6bac12f37093',
  },
  {
    displayName: 'LANDING.TEMPLATE.ACTIVITIES',
    name: 'activities',
    videoUrl:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fvideos%2FForside%20Promo.mp4?alt=media&token=d92e5f54-29b5-47cf-a45d-620a0a7afb70',
    salesPitchHeader: 'LANDING.TEMPLATE.ACTIVITIES.SALESHEADER',
    salesPitchText: 'LANDING.TEMPLATE.ACTIVITIES.SALESTEXT',
    salesPitchButton: URLS.EVENTS_EVENTS,
    salesPitchButtonText: 'LANDING.TEMPLATE.JOIN',
    salesPitchButtonAlwaysRedirect: true,
    quotes: [{ text: 'LANDING.TEMPLATE.QUOTES.ONE' }, { text: 'LANDING.TEMPLATE.QUOTES.TWO' }, { text: 'LANDING.TEMPLATE.QUOTES.THREE' }],
    posterHeader: 'LANDING.TEMPLATE.ACTIVITIES.POSTERHEADER',
    posterText: 'LANDING.TEMPLATE.ACTIVITIES.POSTERTEXT',
    posterImage:
      'https://firebasestorage.googleapis.com/v0/b/ec-play-prod.appspot.com/o/landing%2Fimages%2FAktiviteter.png?alt=media&token=eea57ca7-7e19-4119-b23b-6c2eb64d142e',
  },
];
