import { ReactNode, useState, useContext, useCallback, useMemo, createContext, useEffect } from 'react';

// Project imports
import useMembership from 'apps/Events/hooks/useMembership';
import { EventTimeParticipant } from 'apps/Events/types';
import Onboarding from 'components/onboarding/Onboarding';
import { collection, collectionGroup, COLLECTIONS } from 'fb';
import { User } from 'types';
import URLS, { AUTH_ROUTES, EVENTS_ROUTES } from 'URLS';
import { useIsSchoolAuth } from './Auth';
import { useSnackbar } from './Snackbar';
import { useUserWithLoading } from './User';
import { useTranslation } from 'react-i18next';

type OnboardingStep = {
  text: string;
  checked: boolean;
  isChecked: () => boolean;
  redirect?: string;
  clickRedirect?: string;
  autoRedirect?: boolean;
};

type OnboardingProps = {
  startOnboarding: (title: string, path: string) => void;
  stopOnboarding: () => void;
  url: string;
  navigateNow: boolean;
  setNavigateNow: (value: boolean) => void;
  setAsDialog: (value: boolean) => void;
  active: boolean;
};

const OnboardingContext = createContext<OnboardingProps | undefined>(undefined);

const OnboardingProvider = ({ children }: { children: ReactNode }) => {
  const [asDialog, setAsDialog] = useState(false);
  const [active, setActive] = useState(false);
  const [display, setDisplay] = useState(false);
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [navigateNow, setNavigateNow] = useState(false);
  const [redirectPath, setRedirectPath] = useState('');

  const startOnboarding = useCallback((title: string, path: string) => {
    setAsDialog(true);
    setActive(true);
    setTitle(title);
    setRedirectPath(path);
    localStorage.setItem('onboardingActive', 'true');
    localStorage.setItem('onboardingTitle', title);
    localStorage.setItem('onboardingRedirectPath', path);
  }, []);

  useEffect(() => {
    if (localStorage.getItem('onboardingActive') === 'true' && !active) {
      setAsDialog(true);
      setActive(true);
      setTitle(localStorage.getItem('onboardingTitle') || '');
      setRedirectPath(localStorage.getItem('onboardingRedirectPath') || '');
    }
  }, []);

  // TEMP
  const { user, isLoading: isUserLoading } = useUserWithLoading();
  const { memberships, isLoading: isMembershipLoading } = useMembership();
  const [isSchoolAuth, isSchoolAuthLoadingding] = useIsSchoolAuth();
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation('events');
  const [steps, setSteps] = useState<Array<OnboardingStep>>([]);
  const [timesLoading, setTimesLoading] = useState(true);

  useEffect(() => {
    if (!isUserLoading && !isMembershipLoading && !timesLoading) {
      setDisplay(true);
    }
  }, [isUserLoading, isMembershipLoading, timesLoading, active]);

  const userRefs = useMemo(() => {
    const userData = user?.data();
    let userRefs = [user?.ref];
    if (userData?.children) {
      const childrenRefs = userData.children.map((child) => {
        return collection<User>(COLLECTIONS.USERS).doc(child);
      });
      userRefs = [...userRefs, ...childrenRefs];
    }
    return userRefs;
  }, [user]);

  const [futureTimes, setFutureTimes] = useState<Array<EventTimeParticipant>>([]);

  useEffect(() => {
    if (!active) {
      return;
    }
    if (userRefs.length && userRefs[0]) {
      collectionGroup<EventTimeParticipant>(COLLECTIONS.EVENTS_EVENT_TIME_PARTICIPANTS)
        .where('user', 'in', userRefs)
        .where('attended', '==', false)
        .onSnapshot(async (snapshot) => {
          const times = await Promise.all(
            snapshot.docs.map(async (doc) => {
              return await doc.ref?.parent?.parent?.get();
            }),
          );
          setFutureTimes(times.filter((time) => time && time!.data()!.timeStart.toDate() > new Date()).map((time) => time?.data() as EventTimeParticipant));
          setTimesLoading(false);
        });
    }
    setTimesLoading(false);
  }, [userRefs, active]);

  const getSteps = useMemo(() => {
    const s: Array<OnboardingStep> = [
      {
        text: 'Logg inn/registrer bruker',
        checked: false,
        redirect: AUTH_ROUTES.SIGN_IN,
        autoRedirect: true,
        isChecked: () => {
          return Boolean(user);
        },
      },
      {
        text: 'Opprett barnebruker',
        checked: false,
        redirect: URLS.ACCOUNT,
        clickRedirect: URLS.ACCOUNT + '/addchildren',
        autoRedirect: true,
        isChecked: () => {
          return Boolean(user) && Boolean(user!.data()!.children) && user!.data()!.children!.length > 0;
        },
      },
      {
        text: 'Bli medlem',
        checked: false,
        redirect: EVENTS_ROUTES.EVENTS_MEMBERSHIP,
        autoRedirect: true,
        isChecked: () => {
          return Boolean(user) && Boolean(memberships) && Boolean(memberships[user!.id]) && memberships[user!.id] !== 'unset';
        },
      },
      {
        text: 'Søk etter din skole og meld dere på',
        checked: false,
        redirect: redirectPath,
        autoRedirect: true,
        isChecked: () => {
          return Boolean(user) && futureTimes.length > 0;
        },
      },
    ];
    return s;
  }, [user, memberships, redirectPath, futureTimes]);

  useEffect(() => {
    if (!active) {
      return;
    }
    if (isUserLoading || isMembershipLoading || isSchoolAuthLoadingding || timesLoading) {
      return;
    }
    const newSteps = getSteps.map((step) => {
      return { ...step, checked: Boolean(step.isChecked()) };
    });
    if (newSteps.some((step, index) => step.checked !== steps[index]?.checked)) {
      setSteps(newSteps);
    }
  }, [isUserLoading, isMembershipLoading, redirectPath, futureTimes, isSchoolAuthLoadingding, timesLoading]);

  useEffect(() => {
    if (!isSchoolAuthLoadingding && isSchoolAuth && active) {
      stopOnboarding();
      setUrl(URLS.HUB);
      setNavigateNow(true);
      showSnackbar(t('ONBOARDING.SCHOOL.NOACCESS'), { severity: 'info' });
    }
  }, [isSchoolAuthLoadingding, isSchoolAuth, active]);
  // TEMP

  const stopOnboarding = () => {
    setActive(false);
    localStorage.removeItem('onboardingActive');
    localStorage.removeItem('onboardingTitle');
    localStorage.removeItem('onboardingRedirectPath');
  };

  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  useEffect(() => {
    if (steps.length > 0 && active) {
      const step = steps.find((step) => !step.checked);
      if (!step) {
        setUrl(steps.filter((step) => step.autoRedirect).pop()?.redirect || URLS.HUB);
      } else if (step?.redirect && step.autoRedirect !== false) {
        setUrl(step.redirect as string);
      }
      setNavigateNow(true);
      setAsDialog(true);
    }
  }, [steps, active]);

  useEffect(() => {
    if (!active && title) {
      setAsDialog(false);
      setActive(false);
      setTitle('');
      setUrl('');
      setNavigateNow(false);
    }
  }, [active]);

  const handleChangeDialog = (value: boolean) => {
    setAsDialog(value);
  };

  const handleChangeActive = (value: boolean) => {
    setActive(value);
  };

  const value = useMemo(
    () => ({ startOnboarding, stopOnboarding, url, navigateNow, setNavigateNow, setAsDialog, active }),
    [startOnboarding, stopOnboarding, url, navigateNow, setNavigateNow, setAsDialog, active],
  );

  return (
    <OnboardingContext.Provider value={value}>
      {children}
      {active && display && (
        <Onboarding
          asDialog={asDialog}
          setAsDialog={handleChangeDialog}
          setNavigateNow={setNavigateNow}
          setUrl={setUrl}
          steps={steps}
          stopOnboarding={stopOnboarding}
          title={title}
        />
      )}
    </OnboardingContext.Provider>
  );
};

const useOnboarding = () => {
  const context = useContext(OnboardingContext);
  if (context === undefined) {
    throw new Error('useOnboarding must be used within an OnboardingProvider');
  }
  return context;
};

const useOnboardingRedirect = () => {
  const context = useContext(OnboardingContext);
  if (context === undefined) {
    throw new Error('useOnboardingRedirect must be used within an OnboardingProvider');
  }
  return { url: context.url, navigateNow: context.navigateNow, setNavigateNow: context.setNavigateNow, setAsDialog: context.setAsDialog };
};

export { OnboardingProvider, useOnboarding, useOnboardingRedirect };
