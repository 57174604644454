import { Button, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import ReactPlayer from 'react-player';
import theme from 'theme';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Project imports
import { Page } from '..';
import Demo from '../components/Demo';
import Menu from '../components/Menu';
import Poster from '../components/Poster';
import Quotes from '../components/Quotes';
import { boldAndUnderlineText, boldText } from 'utils';
import TabsMenu from '../components/TabsMenu';
import GetStartedButton from '../components/GetStartedWithSchoolSubscriptionButton';
import URLS from 'URLS';
import { useAuth } from 'hooks/Auth';

const useStyles = makeStyles((theme) => ({
  container: {},
  topBox: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column-reverse',
    },
  },
  salesBox: {
    display: 'flex',
    width: '50%',
    aspectRatio: '16/9',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      aspectRatio: 'auto',
    },
  },
  video: {
    width: '50%',
    aspectRatio: '16/9',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  menu: {
    display: 'flex',
    background: 'rgba(17,17,17,1)',
    width: '20%',
    paddingLeft: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      width: '0',
      paddingLeft: '0',
    },
  },
  salesPitch: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    right: 0,
    padding: theme.spacing(4),
    background: 'linear-gradient(90deg, rgba(17,17,17,1) 2%, rgba(25,25,25,1) 27%, #606060 93%)',
    width: '80%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  salesPitchHeaderText: {
    wordWrap: 'break-word',
    color: 'white',
    marginBottom: theme.spacing(3),
    fontSize: '40px',
    [theme.breakpoints.up(450)]: {
      whiteSpace: 'pre-line',
    },
    [theme.breakpoints.between(1200, 1450)]: {
      fontSize: '32px',
    },
  },
  salesPitchText: {
    color: 'white',
    marginBottom: theme.spacing(3),
    fontSize: '20px',
    whiteSpace: 'pre-line',
    [theme.breakpoints.between(1200, 1450)]: {
      fontSize: '15px',
    },
    [theme.breakpoints.up(1550)]: {
      fontSize: '25px',
    },
  },
  salesPitchButton: {
    width: theme.spacing(20),
  },
  demo: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: -theme.spacing(10),
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(5),
    },
  },
  demoButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  quotes: {
    display: 'flex',
    marginTop: theme.spacing(5),
    justifyContent: 'center',
  },
  poster: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
  },
  maxWidthScaling: {
    maxWidth: '1400px',
    margin: 'auto',
  },
}));

type TemplateProps = {
  page: Page;
  setPage: (p: string) => void;
  pages: Array<Page>;
};

const Template = ({ page, setPage, pages }: TemplateProps) => {
  const {
    videoUrl,
    salesPitchHeader,
    salesPitchText,
    salesPitchButton,
    salesPitchButtonText,
    salesPitchButtonAlwaysRedirect,
    demoImage,
    demoUrl,
    quotes,
    posterHeader,
    posterText,
    posterImage,
  } = page;
  const classes = useStyles();
  const navigate = useNavigate();
  const [auth, isAuthLoading] = useAuth();
  const { t } = useTranslation('common');
  const showTabs = useMediaQuery(theme.breakpoints.down('lg'));
  const showDemo = useMediaQuery(theme.breakpoints.up(300));

  const menuAmount = () => {
    const six = useMediaQuery(theme.breakpoints.down(1800));
    const five = useMediaQuery(theme.breakpoints.down(1600));
    const four = useMediaQuery(theme.breakpoints.down(1400));
    if (four) {
      return 4;
    } else if (five) {
      return 5;
    } else if (six) {
      return 6;
    }
    return 7;
  };

  const quoteAmount = () => {
    const two = useMediaQuery(theme.breakpoints.down(1500));
    const one = useMediaQuery(theme.breakpoints.down('md'));
    if (one) {
      return 1;
    } else if (two) {
      return 2;
    }
    return 3;
  };

  const handlePageChange = (newPage: string) => {
    setPage(pages.find((p) => p.displayName === newPage)!.name);
  };

  const handleDemoClick = () => {
    const url = URLS.PLAYER_DEMO + (demoUrl ? demoUrl : page.name);
    navigate(url);
  };

  return (
    <div className={classes.container}>
      <TabsMenu hidden={!showTabs} items={pages} selected={page.name} setSelected={handlePageChange} />
      <div className={classes.topBox}>
        <div className={classes.salesBox}>
          <div className={classes.menu}>
            <Menu amount={menuAmount()} hidden={showTabs} items={pages} selected={page.name} setSelected={handlePageChange} />
          </div>
          <div className={classes.salesPitch}>
            <Typography className={classes.salesPitchHeaderText} variant='h3'>
              {boldText(t(salesPitchHeader))}
            </Typography>
            <Typography className={classes.salesPitchText}>{boldAndUnderlineText(t(salesPitchText))}</Typography>
            {(salesPitchButton && auth) || (salesPitchButtonAlwaysRedirect && salesPitchButton) ? (
              <Button className={classes.salesPitchButton} onClick={() => navigate(salesPitchButton)} variant='contained'>
                {salesPitchButtonText ? t(salesPitchButtonText) : t('LANDING.GETSTARTEDBUTTON')}
              </Button>
            ) : (
              <GetStartedButton className={classes.salesPitchButton} />
            )}
          </div>
        </div>
        <div className={classes.video}>
          <ReactPlayer controls height='100%' loop playing url={videoUrl} width='100%'></ReactPlayer>
        </div>
      </div>

      {demoImage && (
        <>
          {showDemo && (
            <div className={classes.demo}>
              <Demo content={demoImage} onClick={handleDemoClick} />
            </div>
          )}

          <div className={classes.demoButton}>
            <Button onClick={handleDemoClick} variant='contained'>
              <Typography>{t('LANDING.TEMPLATE.TRYDMEO')}</Typography>
            </Button>
          </div>
        </>
      )}
      <GetStartedButton floating />

      <div className={classes.maxWidthScaling}>
        <div className={classes.quotes}>
          <Quotes
            amount={quoteAmount()}
            quotes={
              quotes.map((quote) => {
                return { ...quote, text: t(quote.text) };
              }) || []
            }
          />
        </div>

        <div className={classes.poster}>
          <Poster header={t(posterHeader)} image={posterImage} text={t(posterText)} />
        </div>
      </div>
    </div>
  );
};

export default Template;
