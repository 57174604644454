import classnames from 'classnames';

// Material UI Components
import { makeStyles } from '@material-ui/core/styles';
import MaterialPaper, { PaperProps as MuiPaperProps } from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: 'auto',
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
  },
  noPadding: {
    padding: theme.spacing(0),
  },
  noMargin: {
    margin: 'initial',
  },
}));

export type PaperProps = MuiPaperProps & {
  outlined?: boolean;
  noPadding?: boolean;
  noMargin?: boolean;
};

const Paper = ({ children, className, outlined, noPadding, noMargin, ...props }: PaperProps) => {
  const classes = useStyles();

  return (
    <MaterialPaper
      className={classnames(classes.paper, noPadding && classes.noPadding, noMargin && classes.noMargin, className && className)}
      elevation={2}
      variant={outlined ? 'outlined' : 'elevation'}
      {...props}>
      {children}
    </MaterialPaper>
  );
};

export default Paper;
