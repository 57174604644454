import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Page } from '..';

// Material UI Components
import { Divider, IconButton, makeStyles, Typography, ButtonBase } from '@material-ui/core';

// Assets/Icons
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    // height: '100%',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemText: {
    color: 'white',
    '&:hover': {
      color: 'rgba(82, 153, 211)',
    },
    width: '100%',
    maxHeight: theme.spacing(7),
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    cursor: 'pointer',
    [theme.breakpoints.between(1200, 1275)]: {
      fontSize: '13px',
    },
    [theme.breakpoints.between(1275, 1350)]: {
      fontSize: '14px',
    },
    [theme.breakpoints.between(1350, 1500)]: {
      fontSize: '15px',
    },
    textAlign: 'left',
  },
  button: {
    width: '100%',
  },
  selectedItemText: {
    marginLeft: '3%',
  },
  divider: {
    backgroundColor: 'rgba(50, 50, 50)',
  },
  selectedDivider: {
    backgroundColor: 'rgba(82, 153, 211)',
    height: theme.spacing(3),
    width: '1px',
  },
  arrow: {
    color: 'white',
  },
  disabledArrow: {
    color: 'rgba(50, 50, 50)',
  },
}));

export type MenuProps = {
  items: Array<Page>;
  selected: string;
  setSelected: (selected: string) => void;
  amount?: number;
  hidden?: boolean;
};

const Menu = ({ items, selected, setSelected, amount = 7, hidden = false }: MenuProps) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [renderItems, setRenderItems] = useState(items.slice(0, amount));
  const { t } = useTranslation('common');

  useEffect(() => {
    setSelectedIndex(items.findIndex((item) => item.name === selected));
  }, [selected]);

  useEffect(() => {
    const start = page * amount;
    const end = start + amount;

    if (end > items.length) {
      setRenderItems(items.slice(Math.max(items.length - amount, 0), items.length));
    } else {
      setRenderItems(items.slice(start, end));
    }
  }, [page, amount]);

  const handleClicked = (index: number) => {
    setSelectedIndex(index);
    setSelected(items[index].displayName);
    window.sessionStorage.setItem('landingPage', items[index].name);
  };

  return (
    <>
      {!hidden && (
        <>
          <div className={classes.container}>
            <IconButton disabled={page === 0} onClick={() => setPage(Math.max(page - 1, 0))}>
              <ArrowUp className={page === 0 ? classes.disabledArrow : classes.arrow} />
            </IconButton>
            {renderItems.map((item, i) => {
              const index = items.findIndex((it) => it === item);
              const isSelected = index === selectedIndex;
              return (
                <div className={classes.itemContainer} key={i}>
                  {i === 0 && <Divider className={classes.divider} />}
                  <div className={classes.item} onClick={() => handleClicked(index)}>
                    {isSelected && <Divider className={classes.selectedDivider} orientation='vertical' />}
                    <ButtonBase className={classes.button} focusRipple>
                      <Typography className={classNames(classes.itemText, isSelected && classes.selectedItemText)}>{t(item.displayName)}</Typography>
                    </ButtonBase>
                  </div>
                  <Divider className={classes.divider} />
                </div>
              );
            })}
            <IconButton disabled={(page + 1) * amount >= items.length} onClick={() => setPage(Math.min(page + 1, Math.ceil(items.length / amount) - 1))}>
              <ArrowDown className={(page + 1) * amount >= items.length ? classes.disabledArrow : classes.arrow} />
            </IconButton>
          </div>
        </>
      )}
    </>
  );
};

export default Menu;
