import React, { ComponentType, useState, useRef, useEffect } from 'react';
import URLS from 'URLS';
import { Link } from 'react-router-dom';
import { useUserState } from 'hooks/User';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAdmin } from 'apps/AdminPanel/hooks/useAdmin';
import { useAuth, useIsSchoolAuth } from 'hooks/Auth';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';

// Project components
import Paper from 'components/layout/Paper';

// Icons
import { ReactComponent as AppsIcon } from 'assets/icons/apps.svg';
import EventIcon from '@material-ui/icons/Event';
import ChatIcon from '@material-ui/icons/Chat';
import ChallengeIcon from '@material-ui/icons/EmojiEventsRounded';
import BandIcon from '@material-ui/icons/PlayArrowRounded';
import ChoirIcon from '@material-ui/icons/Mic';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import AcademyIcon from '@material-ui/icons/School';
import { ReactComponent as GuitarIcon } from 'apps/Academy/assets/img/guitar.svg';
import { ReactComponent as UkuleleIcon } from 'apps/Academy/assets/img/ukulele.svg';
import { ReactComponent as ComposerIcon } from 'assets/img/Composer.svg';
import PianoIcon from 'apps/Academy/assets/img/tempPiano.png';
import { ReactComponent as BassIcon } from 'apps/Academy/assets/img/bass.svg';
import BoomWhackersIcon from '@material-ui/icons/BarChart';
import ResourcesIcon from '@material-ui/icons/LibraryBooks';

import { SvgIcon } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 1600,
  },
  grid: {
    display: 'grid',
    gridGap: theme.spacing(1),
    gridTemplateColumns: '1fr 1fr 1fr',
    [theme.breakpoints.down('xs')]: {
      gridGap: theme.spacing(0.5),
      gridTemplateColumns: '1fr 1fr',
    },
  },
  icon: {
    height: 26,
    width: 26,
  },
  appsIcon: {
    height: 26,
    width: 26,
    fill: (props: { light?: boolean }) => (props.light ? 'white' : theme.palette.black.main),
    [theme.breakpoints.down('md')]: {
      fill: theme.palette.black.light,
    },
  },
  app: {
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    maxWidth: 90,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  startIcon: {
    margin: 'auto',
  },
  button: {
    minWidth: '106px',
  },
}));

function Apps({ light }: { light?: boolean }) {
  const { t } = useTranslation(['common']);
  const classes = useStyles({ light: light });
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const user = useUserState();
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [admin] = useAdmin();
  const [isSchoolAuth, _, schoolAuthDoc] = useIsSchoolAuth();
  const isSchoolStudent = schoolAuthDoc !== null ? schoolAuthDoc?.data().role === 'student' : false;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  type AppProps = {
    icon?: ComponentType<{ className: string }>;
    iconFunction?: (className: string) => JSX.Element;
    text: string;
    to: string;
    external?: boolean;
  };

  const App = ({ icon: Icon, iconFunction, text, to, external }: AppProps) => (
    <Button
      className={classes.button}
      classes={{ label: classes.app, startIcon: classes.startIcon }}
      component={Button}
      onClick={() => {
        if (external) {
          window.open(to);
        } else {
          navigate(to);
        }
      }}
      startIcon={Icon ? <Icon className={classes.icon} /> : iconFunction ? iconFunction(classes.icon) : <></>}>
      <Typography className={classes.text} variant='subtitle2'>
        {text}
      </Typography>
    </Button>
  );

  return (
    <>
      <IconButton aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup='true' onClick={handleToggle} ref={anchorRef}>
        <AppsIcon className={classes.appsIcon} />
      </IconButton>
      <Popper anchorEl={anchorRef.current} className={classes.popper} disablePortal={true} open={open} placement='top-end' role={undefined} transition>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div className={classes.grid}>
                  {isSchoolAuth !== true && <App icon={ChatIcon} text={t('CHATS.HEADER')} to={URLS.CHAT} />}
                  {process.env.REACT_APP_SHOW_CHALLENGE && isSchoolAuth !== true && <App icon={ChallengeIcon} text={t('APPS.CHALLENGE')} to={URLS.CHALLENGE} />}
                  {process.env.REACT_APP_SHOW_EVENTS && <App icon={EventIcon} text={t('APPS.EVENTS')} to={URLS.EVENTS_EVENTS} />}
                  {process.env.REACT_APP_SHOW_PLAYER && <App icon={BandIcon} text={t('APPS.BAND')} to={URLS.PLAYER} />}
                  {process.env.REACT_APP_SHOW_CHOIRPLAYER && <App icon={ChoirIcon} text={t('APPS.CHOIR')} to={URLS.CHOIRPLAYER} />}
                  {process.env.REACT_APP_SHOW_BOOMWHACKERSPLAYER && <App icon={BoomWhackersIcon} text={t('APPS.BOOMWHACKERS')} to={URLS.BOOMWHACKERSPLAYER} />}
                  {process.env.REACT_APP_SHOW_UKULELEPLAYER && (
                    <App
                      iconFunction={(className: string) => <SvgIcon className={className} component={UkuleleIcon} viewBox={'0 0 60 60'} />}
                      text={t('APPS.UKULELE')}
                      to={URLS.UKULELEPLAYER}
                    />
                  )}
                  {process.env.REACT_APP_SHOW_GUITARPLAYER && (
                    <App
                      iconFunction={(className: string) => <SvgIcon className={className} component={GuitarIcon} viewBox={'0 0 404 404'} />}
                      text={t('APPS.GUITAR')}
                      to={URLS.GUITARPLAYER}
                    />
                  )}
                  {process.env.REACT_APP_SHOW_WHITEPIANOPLAYER && (
                    <App
                      iconFunction={(className: string) => <img className={className} src={PianoIcon} />}
                      text={t('APPS.WHITEPIANO')}
                      to={URLS.WHITEPIANOPLAYER}
                    />
                  )}
                  {process.env.REACT_APP_SHOW_BASSTABSPLAYER && (
                    <App
                      iconFunction={(className: string) => <SvgIcon className={className} component={BassIcon} viewBox={'0 0 612 612'} />}
                      text={t('APPS.BASSTABS')}
                      to={URLS.BASSTABSPLAYER}
                    />
                  )}
                  {process.env.REACT_APP_SHOW_COMPOSER === 'TRUE' && (
                    <App
                      iconFunction={(className: string) => <SvgIcon className={className} component={ComposerIcon} viewBox={'0 0 576 515'} />}
                      text={t('APPS.COMPOSER')}
                      to={URLS.COMPOSER_WITHOUT_PLAYBACK}
                    />
                  )}
                  {process.env.REACT_APP_SHOW_RESOURCES && <App icon={ResourcesIcon} text={t('APPS.RESOURCES')} to={URLS.RESOURCES} />}
                  {process.env.REACT_APP_SHOW_ACADEMY && <App icon={AcademyIcon} text={t('APPS.ACADEMY')} to={URLS.ACADEMY} />}
                  {process.env.REACT_APP_SHOW_ADMINPANEL && isSchoolAuth !== true && (admin?.player || admin?.events || admin?.license) && (
                    <App
                      icon={SettingsIcon}
                      text={t('APPS.ADMINPANEL')}
                      to={
                        admin?.player
                          ? URLS.ADMINPANEL_PLAYER
                          : admin?.events
                          ? URLS.ADMINPANEL_EVENTS
                          : admin?.license
                          ? URLS.ADMINPANEL_SCHOOLONBOARDING
                          : URLS.LANDING
                      }
                    />
                  )}

                  {isSchoolStudent !== true && <App external icon={ShoppingCartIcon} text={t('APPS.SHOP')} to={URLS.SHOP} />}
                  {/*
                  This is temporary removed due to "about us" page not existing
                    <App external icon={InfoIcon} text={t('APPS.ORG')} to={URLS.ORG} />
                  */}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default Apps;
