import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import URLS from 'URLS';
import { useTranslation } from 'react-i18next';
import { useIsSchoolAuth } from 'hooks/Auth';
import { useAdmin } from 'apps/AdminPanel/hooks/useAdmin';

// Material UI Components
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

// Assets/Icons
import LOGO from 'assets/icons/ECPlayLogo.svg';
import MenuIcon from '@material-ui/icons/MenuRounded';
import AccountIcon from '@material-ui/icons/AccountCircleOutlined';
import ChatIcon from '@material-ui/icons/Chat';
import EventIcon from '@material-ui/icons/Event';
import ChallengeIcon from '@material-ui/icons/EmojiEventsRounded';
import PlayerIcon from '@material-ui/icons/PlayArrowRounded';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AcademyIcon from '@material-ui/icons/School';
import ResourcesIcon from '@material-ui/icons/LibraryBooks';

const useStyles = makeStyles((theme) => ({
  drawer: {
    minWidth: 275,
    width: '70%',
    maxWidth: 400,
  },
  logo: {
    margin: theme.spacing(1, 2),
    width: 150,
    height: 'auto',
  },
}));

const Sidebar = () => {
  const { t } = useTranslation(['common']);
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isSchoolAuth, _, schoolAuthDoc] = useIsSchoolAuth();
  const isSchoolStudent = schoolAuthDoc !== null ? schoolAuthDoc?.data().role === 'student' : false;
  const [admin, adminIsLoading] = useAdmin();

  const toggleDrawer = () => setOpen((prev) => !prev);

  type ListItemProps = {
    icon: ReactNode;
    text: string;
    to: string;
    external?: boolean;
  };

  const ListItem = ({ to, icon, text, external }: ListItemProps) => (
    <MuiListItem
      button
      color='primary'
      onClick={() => {
        if (external) {
          window.open(to);
        } else {
          navigate(to);
        }
      }}
      selected={location.pathname === to}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </MuiListItem>
  );

  return (
    <>
      <IconButton aria-label='Toggle sidebar' onClick={toggleDrawer}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor='left' classes={{ paper: classes.drawer }} onClose={toggleDrawer} open={open}>
        <List>
          <img alt='EC-Play logo' className={classes.logo} height='40em' src={LOGO} width='auto' />
          {process.env.REACT_APP_SHOW_EVENTS && <ListItem icon={<EventIcon />} text={t('APPS.EVENTS')} to={URLS.EVENTS_EVENTS} />}
          {process.env.REACT_APP_SHOW_CHALLENGE && isSchoolAuth !== true && (
            <ListItem icon={<ChallengeIcon />} text={t('APPS.CHALLENGE')} to={URLS.CHALLENGE} />
          )}
          {process.env.REACT_APP_SHOW_PLAYER && <ListItem icon={<PlayerIcon />} text={t('APPS.EDTECH')} to={URLS.EDTECH} />}
          {process.env.REACT_APP_SHOW_RESOURCES && <ListItem icon={<ResourcesIcon />} text={t('APPS.RESOURCES')} to={URLS.RESOURCES} />}
          {process.env.REACT_APP_SHOW_ACADEMY && <ListItem icon={<AcademyIcon />} text={t('APPS.ACADEMY')} to={URLS.ACADEMY} />}
          {isSchoolStudent !== true && <ListItem external icon={<ShoppingCartIcon />} text={t('APPS.SHOP')} to={URLS.SHOP} />}
          {/*
          This is temporary removed due to "about us" not existing
            <ListItem external icon={<InfoIcon />} text={t('APPS.ORG')} to={URLS.ORG} />
            */}
        </List>
        <Divider />
        <List>
          {isSchoolAuth !== true && <ListItem icon={<ChatIcon />} text={t('CHATS.HEADER')} to={URLS.CHAT} />}
          <ListItem icon={<AccountIcon />} text={t('APPS.ACCOUNT')} to={URLS.ACCOUNT} />
        </List>
      </Drawer>
    </>
  );
};

export default Sidebar;
