import { ReactNode, useEffect } from 'react';

// Material UI Components
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Container from '@material-ui/core/Container';

// Project Components
import Footer from 'components/navigation/Footer';
import TopBar from 'components/navigation/TopBar';
import SkipToContent from 'components/navigation/SkipToContent';

const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: '101vh',
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
}));

export type NavigationProps = {
  children?: ReactNode;
  isLoading?: boolean;
  noFooter?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
};

const Navigation = ({ children, isLoading, noFooter, maxWidth = 'xl' }: NavigationProps) => {
  const classes = useStyles();

  useEffect(() => window.scrollTo(0, 0), []);

  const Content = () => (isLoading ? <LinearProgress /> : <>{children}</>);

  return (
    <>
      <SkipToContent />
      <TopBar />
      <main className={classes.main} id={'main'}>
        {maxWidth === false ? (
          <Content />
        ) : (
          <Container className={classes.container} maxWidth={maxWidth}>
            <Content />
          </Container>
        )}
      </main>
      {!noFooter &&
        !isLoading &&
        (maxWidth === false ? (
          <Footer />
        ) : (
          <Container className={classes.container} maxWidth={maxWidth}>
            <Footer />
          </Container>
        ))}
    </>
  );
};

export default Navigation;
